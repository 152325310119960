import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cm-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsModalComponent {
  isTermsAndCondAccepted = false;

  constructor(private activeModal: NgbActiveModal) {}

  onAccept() {
    this.activeModal.close();
  }
}
