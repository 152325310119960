export enum DISPLAY_LOCATIONS {
  ADVANCED = '1',
  MODULE = '2',
}

export enum DISPLAY_ON_UI {
  SHOW = 1,
  HIDE = 0,
}

export enum LOCATION_TYPE {
  TASK = 'task',
  ADVANCED = 'advanced',
  CALCULATED = 'calculated',
  GLOBAL = 'global',
  ALL = 'all',
  OVERWRITE = 'overwrite',
}

export enum PERMISSIONS {
  certificationAdd,
  certificationRemove,
  certificationEdit,
  certificationCopy,
  certificationViewAll,
  certificationViewOrganization,
  certificationViewMy,
  certificationStatusFilter,
  certificationIndustryFilter,
  certificationRegistryVisibility,

  userGroupsShown,
  userCustomFieldsShown,
  usersAddGroup,
  customFieldInstanceEdit,

  assignRegFormStep,

  profileSubscriptions,
  profileActivateSubscription,
  profileDeactivateSubscription,
  profileAddSubscription,
  profileDeleteSubscription,

  profileVerificationForms,
  profileEnableVerificationForm,

  testRegistrationReports,

  deleteUsersCertifications,
}
