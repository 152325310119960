import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './router-serializer';
import { routerReducer } from './+state/router-state.reducer';

@NgModule({
  imports: [
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /**
       * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
       * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
       * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
       */
      serializer: CustomRouterStateSerializer,
    }),
    StoreModule.forFeature('router', routerReducer),
  ],
  providers: [],
})
export class RouterStateModule {}
