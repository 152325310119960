import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import moment from 'moment-mini';
import { getHostName } from 'app/global';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenStorageService {
  static token: ReplaySubject<any> = new ReplaySubject(1);
  private _userToken;
  private _tokenExpiray;
  private _redirectUrl;

  get dataStorage() {
    const disabledCookiesUrl = '/disabled-cookies';
    if (window.navigator.cookieEnabled) {
      return {
        setItem: (name = '', value = '', domain: string = getHostName(), path: string = '/', expires?) => {
          this.cookieService.set(name, value, expires, path, domain);
        },
        removeItem: (name = '', domain = getHostName(), path = '/') => {
          this.cookieService.delete(name, path, domain);
          this.cookieService.delete(name, path, window.location.hostname);
          if (environment.name.toLowerCase() === 'production') {
            this.cookieService.delete(name, path, 'app.certemy.com');
          }
        },
        getItem: (name = '') => {
          return this.cookieService.get(name);
        },
      };
    } else {
      if (window.location.pathname !== disabledCookiesUrl) {
        window.location.href = `${window.location.protocol}//${window.location.host}${disabledCookiesUrl}`;
      }
      return { setItem: (name, value) => {}, getItem: name => '', removeItem: name => {} };
    }
  }

  constructor(private cookieService: CookieService) {
    this._tokenExpiray = environment.JWT_EXPIRE_TIME;
  }

  get userToken() {
    return this._userToken ? this._userToken : (this._userToken = this.dataStorage.getItem('userToken'));
  }

  set userToken(userToken: string) {
    this._userToken = userToken;
    this.dataStorage.setItem('userToken', userToken);
    this.dataStorage.setItem('userTokenTimeStamp', JSON.stringify(Date.now()));
  }

  get tokenExpiray() {
    return this._tokenExpiray ? this._tokenExpiray : (this._tokenExpiray = this.dataStorage.getItem('tokenExpiray'));
  }

  set tokenExpiray(expiray) {
    this._tokenExpiray = expiray;
    this.dataStorage.setItem('tokenExpiray', expiray);
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  set redirectUrl(redirectUrl) {
    this._redirectUrl = redirectUrl;
  }

  get isLoggedIn(): boolean {
    return !!this.userToken && !this.isTokenExpired();
  }

  dropUserToken() {
    this._userToken = '';
    this.dataStorage.removeItem('userToken');
    this.dataStorage.removeItem('userTokenTimeStamp');
  }

  dropAllTokens() {
    this.dropUserToken();
  }

  dropRedirectUrl() {
    this._redirectUrl = '';
  }

  isTokenExpired(offset = 0) {
    const timeStampValue = this.dataStorage.getItem('userTokenTimeStamp');

    if (timeStampValue) {
      const timeStamp = moment(+timeStampValue);
      const timePassed = moment().diff(timeStamp, 'minutes');
      const expirationTime = Number(this.tokenExpiray - offset);

      return timePassed >= expirationTime;
    }

    return false;
  }

  replaceUserToken(token) {
    this.userToken = token;
  }
}
