import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

export interface ResizeData {
  bodyWidth: number;
  isMobile: boolean;
  isDesktop: boolean;
}

@Injectable()
export class ResizeService implements OnDestroy {
  private readonly mobileBreakpoint = 700;
  private readonly desktopBreakpoint = 900;
  private onDestroy$ = new Subject<void>();
  resize$ = new BehaviorSubject(this.checkWidth());

  constructor() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        map(() => this.checkWidth()),
        takeUntil(this.onDestroy$),
      )
      .subscribe(res => this.resize$.next(res));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkWidth(): ResizeData {
    return {
      bodyWidth: document.body.clientWidth,
      isMobile: document.body.clientWidth <= this.mobileBreakpoint,
      isDesktop: document.body.clientWidth >= this.desktopBreakpoint,
    };
  }
}
