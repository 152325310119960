import { DEFAULT_PRODUCTION_DOMAIN_NAME } from '../../global';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import _get from 'lodash-es/get';
import { SelfEnrollmentsService } from 'services/self-enrollments/self-enrollments.service';
import { PlatformBrandingService } from 'features/organization-settings/platform-branding/platform-branding.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { OrganizationRoleRow } from 'features/organization-settings/organization-roles/organization-roles.constants';
import _size from 'lodash-es/size';
import { SelfEnrollmentsPublicResponse } from 'pages/organization/self-enrollments/self-enrollments.interface';

function getSelfEnrollmentHostName(): string {
  return environment.name === 'Production'
    ? `${DEFAULT_PRODUCTION_DOMAIN_NAME}.certemy.com`
    : `${environment.name.toLowerCase()}.certemy.com`;
}

export function getSelfEnrollmentLink(pathname?: string): string {
  pathname = pathname || window.location.pathname;
  const lastSlashIndex = pathname.lastIndexOf('/');
  // BE needs exactly self-enrollment link (without last part that FE uses for navigation)
  const loginRoutes = ['/login', '/complete-registration'];
  const url = loginRoutes.includes(pathname.slice(lastSlashIndex)) ? pathname.slice(0, lastSlashIndex) : pathname;

  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === 'local.certemy.com';
  return isLocalhost
    ? `${window.location.protocol}//${window.location.host}${url}`
    : `${window.location.protocol}//${getSelfEnrollmentHostName()}:${url}`;

  // Please uncomment the line below to navigate to self-enrollment link (use). Use your current environment.
  // return `https://preuat.certemy.com:${url}`;
}

export const RESPONSE_ERRORS = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  SIGN_UP_ERROR: 'SIGNUP_ERROR',
};

export const SIGN_UP_TYPES = {
  PROFESSIONAL: 'PROFESSIONAL',
  ORGANIZATION: 'ORGANIZATION',
};

export enum SIGN_IN_TYPES {
  DEFAULT = 'DEFAULT',
  GOOGLE = 'GOOGLE',
  ONE_LOGIN = 'ONE_LOGIN',
  OKTA = 'OKTA',
  AZURE = 'AZURE',
  ENTRA = 'ENTRA',
  PING = 'PING',
  MEMBERCLICKS = 'MEMBERCLICKS',
  MEMBERSUITE = 'MEMBERSUITE',
  NONACTIVEUSER = 'NONACTIVEUSER',
  PASSWORDLESS = 'PASSWORDLESS',
}

export enum LOGIN_PROVIDER {
  CERTEMY = 'CERTEMY',
  MEMBERCLICKS = 'MEMBERCLICKS',
  MEMBERSUITE = 'MEMBERSUITE',
  GOOGLE = 'GOOGLE',
  GOOGLE_DOMAIN = 'GOOGLE_DOMAIN',
  ONE_LOGIN = 'ONE_LOGIN',
  OKTA = 'OKTA',
  AZURE = 'AZURE',
  ENTRA = 'ENTRA',
  PING = 'PING',
}

export enum LOGIN_PROVIDER_VERIFY_STATUS {
  SUCCESS,
  FAIL,
}

export interface VerifyTokenData {
  id?: string;
  email: string;
  profileId: number;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  domain?: string;
}

export const LOGIN_ERRORS = {
  DRAFT_CERTIFICATION: 'You cannot assign credential with draft publish status',
  INCORRECT_INFO: 'Password or email is incorrect',
  INCORRECT_ACCOUNT: 'Invalid account credentials',
  MEMBER_CLICKS_ERROR: 'Error connecting to MemberClicks server. Please try again later',
  MEMBER_CLICKS_VALIDATION_ERROR: `Invalid data received from MemberClicks. Please contact the administrator`,
  INCORRECT_INFO_USERNAME: 'Password or username is incorrect',
  INCORRECT_PASSWORD: 'Please provide a correct password',
  DEACTIVATED_ACCOUNT: 'Your account was deactivated',
  EXISTING_CERTIFICATION: `You currently have the credential you are trying to impose, assigned to your profile.<br>
  To continue your progress in the credential please proceed to
  <a href="https://www.certemy.com/" target="_blank">www.certemy.com</a>  site to continue.`,
  EXISTING_WORKFLOW: `You currently have the workflow you are trying to impose, assigned to your profile.<br>
  To continue your progress in the workflow please proceed to
  <a href="https://www.certemy.com/" target="_blank">www.certemy.com</a>  site to continue.`,
  ACCESS_DENIED: 'ACCESS_DENIED',
};

export function getEnrollmentDataForResolver(
  selfEnrollmentsService: SelfEnrollmentsService,
  platformBrandingService: PlatformBrandingService,
  router: Router,
  pathname?: string,
): Observable<any> {
  return selfEnrollmentsService.getEnrollmentStatus(getSelfEnrollmentLink(pathname)).pipe(
    tap((data: SelfEnrollmentsPublicResponse) => platformBrandingService.initPlatformBrandingOrRedirect(data)),
    map((data: SelfEnrollmentsPublicResponse) => {
      if (_size(data.organizationRoles)) {
        data.organizationRoles = data.organizationRoles.map((record: OrganizationRoleRow) => ({
          id: record.organizationRoleId,
          name: record.name,
        }));
      }
      return data;
    }),
    catchError(error => {
      if (
        _get(error, 'body.code_name', '') === RESPONSE_ERRORS.VALIDATION_ERROR &&
        !!_get(error, 'body.error_details.link.length')
      ) {
        router.navigateByUrl('/not-found');
      }
      return of(null);
    }),
  );
}
