import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';

import { ROLE_CODES } from 'constants/role.constants';
import { Store } from '@ngrx/store';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { DomainService } from 'services/domain/domain.service';
import { CHANGE_DOMAIN_TYPE } from 'constants/constants';
import { UserService } from 'services/user/user.service';
import { TokenStorageService } from 'services/token/token-storage.service';

@Injectable()
export class RoleGuardService  {
  private PROTECTED_ROUTES = [
    {
      path: '/organization',
      roles: [ROLE_CODES.SUPERADMIN, ROLE_CODES.ORGANIZATION_ADMIN],
    },
    {
      path: '/professional',
      roles: [ROLE_CODES.PROFESSIONAL],
    },
    {
      path: '/supervisor',
      roles: [ROLE_CODES.SUPERVISOR],
    },
    {
      path: '/imposer',
      roles: [ROLE_CODES.IMPOSER],
    },
    {
      path: '/verifier',
      roles: [ROLE_CODES.SUPERADMIN, ROLE_CODES.ORGANIZATION_ADMIN],
    },
    {
      path: '/admin',
      roles: [ROLE_CODES.SUPERADMIN],
    },
    {
      path: '/employer',
      roles: [ROLE_CODES.EMPLOYER],
    },
    {
      path: '/partner',
      roles: [ROLE_CODES.PARTNER],
    },
  ];

  private firstRun = true;

  constructor(
    private store: Store<any>,
    private router: Router,
    private domainService: DomainService,
    private userService: UserService,
    private tokenStorage: TokenStorageService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let changeDomainType: CHANGE_DOMAIN_TYPE;
    if (this.firstRun) {
      const changeDomainTypeStr = this.domainService.getTokenFromCookies('changeDomainType');
      changeDomainType = changeDomainTypeStr && Number(changeDomainTypeStr);
      this.domainService.removeTokenFromCookies('changeDomainType');
      this.firstRun = false;
    }

    return this.store.select(Selectors.currentProfile).pipe(
      map(profile => {
        if (!profile || !profile.role_id) {
          this.router.navigateByUrl('/entry/login');
          return false;
        }

        const hasAccess = this.hasAccess(state.url, profile.role_id);

        if (!hasAccess) {
          if (changeDomainType === CHANGE_DOMAIN_TYPE.CHANGE_LOCATION_ONLY) {
            const redirectLink = this.userService.generateRedirectLink('', profile);
            this.tokenStorage.dropRedirectUrl();
            this.router.navigate(redirectLink.link, { queryParams: redirectLink.queryParams });
            return hasAccess;
          }
          // console.log(`Role Guard: No access to the page: ${state.url}`);
          this.router.navigateByUrl('/not-found');
        }

        return hasAccess;
      }),
    );
  }

  hasAccess(url: string, roleId: ROLE_CODES): boolean {
    const protectedRoute = this.PROTECTED_ROUTES.find(route => url.indexOf(route.path) === 0);

    return !!protectedRoute && protectedRoute.roles.includes(roleId);
  }
}

export const roleGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
  return inject(RoleGuardService).canActivate(route, state);
};
