import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { authz_path } from '../../global';
import { HttpHandler } from '@angular/common/http';
import { TokenStorageService } from '../token/token-storage.service';
import { MessageQueryService } from '@certemy/ui-components';
import { HttpService } from 'services/http/http.service';

@Injectable()
export class AuthzService extends HttpService {
  protected apiUrl = authz_path;

  constructor(
    httpHandler: HttpHandler,
    protected messageService: MessageQueryService,
    protected tokenStorage: TokenStorageService,
    protected router: Router,
  ) {
    super(httpHandler, messageService, tokenStorage, router);
  }
}
