import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { getSection } from 'utils/helpers';
import { ROLE_CODES } from 'constants/role.constants';
import { ComputedFrom } from '@certemy/common';
import { PolicyPermissionsService } from 'services/policy-permission/policy-permissions.service';
import { PolicyObj } from 'services/policy-permission/policy-permissions.service';

const canEditProfile = [ROLE_CODES.SUPERADMIN, ROLE_CODES.ORGANIZATION_ADMIN, ROLE_CODES.PROFESSIONAL];

const USER_PROFILE_POLICIES: Record<string, PolicyObj> = {
  view: { name: 'view', url: 'settings/users/own_profile', action: 'view' },
};

@Component({
  selector: 'cm-user-profiles-list',
  templateUrl: './user-profiles-list.component.html',
  styleUrls: ['./user-profiles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfilesListComponent implements OnInit {
  @Input() profile;
  @Input() profiles;
  profileLink: string;
  policyPermissions: { view: boolean } = { view: false };

  @ComputedFrom('profile')
  get isLoggedAsProfile() {
    return !!this.profile.origin;
  }

  @ComputedFrom('profile', 'policyPermissions')
  get isUserProfileLinkVisible() {
    if (!this.policyPermissions.view) {
      return false;
    }

    return this.profile && canEditProfile.includes(this.profile.role_id) && !this.isLoggedAsProfile;
  }

  constructor(private changeDetector: ChangeDetectorRef, private policyPermissionsService: PolicyPermissionsService) {}

  ngOnInit() {
    const section = getSection(window.location.pathname);
    this.profileLink = `/${section}/profile/${this.profile.profile_id}`;

    this.policyPermissionsService
      .hasMultiplePermissions(Object.values(USER_PROFILE_POLICIES))
      .pipe(take(1))
      .subscribe((permissions: { view: boolean }) => {
        this.policyPermissions = permissions;
        this.changeDetector.detectChanges();
      });
  }

  getSwitchProfileLink(profile) {
    return `/switch-profile/${profile.profile_id}`;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
