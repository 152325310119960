import { ModuleWithProviders, NgModule } from '@angular/core';

import { UrgentActionsComponent } from './urgent-actions.component';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { UrgentActionsService } from './urgent-actions.service';

@NgModule({
  imports: [CertemyCommonModule],
  declarations: [UrgentActionsComponent],
  providers: [],
  exports: [UrgentActionsComponent],
})
export class UrgentActionsModule {
  static forRoot(): ModuleWithProviders<UrgentActionsModule> {
    return {
      ngModule: UrgentActionsModule,
      providers: [UrgentActionsService],
    };
  }
}
