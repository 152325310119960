import { NgModule } from '@angular/core';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { BaseErrorPageComponent } from './base-error-page/base-error-page.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AccessRestrictedComponent } from './access-restricted/access-restricted.component';
import { HeaderModule } from 'components/header/header.module';

@NgModule({
  imports: [CertemyCommonModule, HeaderModule],
  declarations: [BaseErrorPageComponent, ForbiddenComponent, NotFoundComponent, AccessRestrictedComponent],
  providers: [],
  exports: [BaseErrorPageComponent],
})
export class ErrorPagesModule {}
