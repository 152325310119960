import { ErrorHandler } from '@angular/core';
import { SentryLoggerService } from 'services/sentry-logger/sentry-logger.service';
import { UrgentActionsService } from 'components/urgent-actions/urgent-actions.service';
import { ConfirmReloadComponent } from 'components/confirm-reload/confirm-reload.component';
import { HttpError } from 'services/http/http-error.interface';
import { MessageQueryService } from '@certemy/ui-components';
import { LoggerService } from '@certemy/common';
import { NEVER, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import _get from 'lodash-es/get';

enum ERROR_TYPES {
  LoadingChunkError = 'Loading Chunk Error',
  HttpError = 'Http Error',
  UnknownError = 'Unknown Error',
}

export class CertemyErrorHandler implements ErrorHandler {
  private readonly LOADING_CHUNK_ERROR_REGEX = /Loading chunk \d{1,3} failed\./i;
  private errors$ = new Subject();

  constructor(
    private sentryLoggerService: SentryLoggerService,
    private urgentActionsService: UrgentActionsService,
    private messageQueryService: MessageQueryService,
    private loggerService: LoggerService,
  ) {
    this.errors$
      .pipe(
        map((error: any) => this.unwrapUnhandledPromiseRejection(error.originalError || error)),
        switchMap(error => this.handleLoadingChunkError(error)),
        switchMap(error => this.handleHttpError(error)),
      )
      .subscribe(error => this.captureException(error));
  }

  handleError(err: any): void {
    this.errors$.next(err);
  }

  private unwrapUnhandledPromiseRejection(error) {
    if (error.message && error.message.startsWith('Uncaught (in promise)')) {
      return error.rejection;
    } else {
      return error;
    }
  }

  private handleLoadingChunkError(error) {
    if (this.LOADING_CHUNK_ERROR_REGEX.test(error.message)) {
      this.urgentActionsService.add(ConfirmReloadComponent);
      this.loggerService.warning(error, {
        tags: {
          error_type: ERROR_TYPES.LoadingChunkError,
        },
      });

      return NEVER;
    } else {
      return of(error);
    }
  }

  private handleHttpError(error: any) {
    if (HttpError.isHttpError(error)) {
      if (!error.handled) {
        this.messageQueryService.addErrorMessage(error.showIfNotHandled);
      }

      this.loggerService.captureException(error, {
        level: this.getLogLevel(error),
        tags: {
          error_type: ERROR_TYPES.HttpError,
          code_name: _get(error, 'body.code_name'),
          status: error.status.toString(),
        },
      });

      return NEVER;
    } else {
      return of(error);
    }
  }

  private captureException(error: any) {
    this.loggerService.captureException(error, {
      level: this.getLogLevel(error),
      tags: {
        error_type: ERROR_TYPES.UnknownError,
      },
      extra: this.sentryLoggerService.getLogs(),
    });

    console.error(error);
  }

  private getLogLevel(error: any) {
    return error.handled ? 'warning' : 'error';
  }
}

export const ErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: CertemyErrorHandler,
  deps: [SentryLoggerService, UrgentActionsService, MessageQueryService, LoggerService],
};
