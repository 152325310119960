import { Component } from '@angular/core';
import { BaseErrorPageConfig } from '../base-error-page/base-error-page.component';

@Component({
  selector: 'cm-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  config: BaseErrorPageConfig = {
    header: '404',
    body: 'For some reasons the page you requested could not be found on our server',
  };
}
