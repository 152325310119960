import { NgModule } from '@angular/core';

import { UserProfileCalloutComponent } from './user-profile-callout.component';
import { UserProfilesListModule } from 'components/user-profiles-list/user-profiles-list.module';
import { CertemyCommonModule } from 'common/certemy-common.module';

@NgModule({
  imports: [CertemyCommonModule, UserProfilesListModule],
  exports: [UserProfileCalloutComponent],
  declarations: [UserProfileCalloutComponent],
  providers: [],
})
export class UserProfileCalloutModule {}
