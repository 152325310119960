import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PlatformBrandingEffects } from 'features/organization-settings/platform-branding/+state/platform-branding.effects';
import { brandingReducer } from 'features/organization-settings/platform-branding/+state/platform-branding.reducer';

@NgModule({
  imports: [StoreModule.forFeature('branding', brandingReducer), EffectsModule.forFeature([PlatformBrandingEffects])],
  providers: [],
})
export class PlatformBrandingStateModule {}
