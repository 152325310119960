import { ColorSettings, FontSettings, LogoConfig, PlatformBrandingSettings } from './platform-branding.interfaces';
import { Prop } from 'utils/constants.helpers';
import { CROPPER_MODES } from '@certemy/ui-components';

export const DEFAULT_COLOR_SETTINGS: ColorSettings = {
  baseColor: '#5cd1d8',
  hoverBaseColor: '#4ec6cc',
  activeBaseColor: '#39b9bf',
  menuBaseColor: '#003033',
  menuButtonColor: '#014c50',
  menuButtonHoverColor: '#027c82',
};

export const DEFAULT_LOGO_FORM_TYPE: CROPPER_MODES = CROPPER_MODES.SQUARE;
export const DEFAULT_LOGO_FILE = '/assets/logo-white.svg';

export const DEFAULT_LOGO_CONFIG: LogoConfig = {
  imageConfig: {
    imageFile: DEFAULT_LOGO_FILE,
    originalImageFile: DEFAULT_LOGO_FILE,
    imageConfiguration: {
      height: 132,
      left: 55,
      naturalHeight: 132,
      naturalWidth: 300,
      top: 22,
      width: 300,
    },
  },
  logoFormType: DEFAULT_LOGO_FORM_TYPE,
};

export const CSS_VARIABLES_NAMES = {
  BASE_COLOR: '--baseColor',
  BASE_HOVER_COLOR: '--baseHoverColor',
  BASE_ACTIVE_COLOR: '--baseActiveColor',
  MENU_BASE_COLOR: '--menuBase',
  MENU_BUTTON_COLOR: '--menuButton',
  MENU_BUTTON_HOVER_COLOR: '--menuButtonHover',
  BASE_FONT: '--baseFont',
  HEADING_FONT: '--headingFont',
};

export const DEFAULT_FONT_SETTINGS: FontSettings = {
  baseFont: '"Noto Sans", sans-serif',
  headingFont: '"Merriweather", serif',
};

export const DEFAULT_PLATFORM_SETTINGS: PlatformBrandingSettings = {
  colorSettings: DEFAULT_COLOR_SETTINGS,
  baseFont: DEFAULT_FONT_SETTINGS.baseFont,
  headingFont: DEFAULT_FONT_SETTINGS.headingFont,
  avatar: DEFAULT_LOGO_CONFIG.imageConfig,
  logoFormType: DEFAULT_LOGO_CONFIG.logoFormType,
};

export const BASE_FONT_OPTIONS: Prop[] = [
  { id: '"Verdana", sans-serif', name: 'Verdana' },
  { id: '"Roboto", sans-serif', name: 'Roboto' },
  { id: '"Poppins", sans-serif', name: 'Poppins' },
  { id: '"Montserrat", sans-serif', name: 'Montserrat' },
  { id: '"Noto Sans", sans-serif', name: 'Noto Sans' },
];

export const HEADING_FONT_OPTIONS: Prop[] = [
  { id: '"Raleway", sans-serif', name: 'Raleway' },
  { id: '"Ubuntu", sans-serif', name: 'Ubuntu' },
  { id: '"Roboto Slab", serif', name: 'Roboto Slab' },
  { id: '"Montserrat", serif', name: 'Montserrat' },
  { id: '"Merriweather", serif', name: 'Merriweather' },
];
