import { ActionReducer, MetaReducer, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { environment } from '../../../environments/environment';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { SentryLoggerService } from 'services/sentry-logger/sentry-logger.service';
import { Provider } from '@angular/core';

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */

// console.log all actions
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    console.groupCollapsed && console.groupCollapsed(`Action ${action.type}`);
    console.log('Previous state:', state);
    console.log('Action:', action);
    const newState = reducer(state, action);
    console.log('New state:', newState);
    console.groupEnd && console.groupEnd();

    return newState;
  };
}

export function getMetaReducers(sentryLoggerService: SentryLoggerService): MetaReducer<any>[] {
  const commonReducers = [sentryLoggerService.logger];
  return !environment.production ? [logger, ...commonReducers] : commonReducers;
}

export const MetaReducersService: Provider = {
  provide: USER_PROVIDED_META_REDUCERS,
  useFactory: getMetaReducers,
  deps: [SentryLoggerService],
};
