import { Component, Input } from '@angular/core';

export interface BaseErrorPageConfig {
  header: string;
  body: string;
  link?: string;
  buttonText?: string;
}

@Component({
  selector: 'cm-base-error-page',
  templateUrl: './base-error-page.component.html',
  styleUrls: ['./base-error-page.component.scss'],
})
export class BaseErrorPageComponent {
  @Input() config: BaseErrorPageConfig;
}
