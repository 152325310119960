import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import interact from 'interactjs';
import _cloneDeep from 'lodash-es/cloneDeep';
import { ResizableEdges, InteractableConfing } from './interactable.interface';

@Directive({
  selector: '[interactable]',
})
export class InteractableDirective implements OnInit, OnDestroy {
  @Output() onInteractableChange = new EventEmitter<InteractableConfing>();

  @Input()
  set interactable(value: InteractableConfing) {
    this._interactable = _cloneDeep(value);
    this.applyTransformation();
  }

  get interactable(): InteractableConfing {
    return this._interactable;
  }

  @Input()
  set interactableDisabled(disabled: boolean) {
    this._interactableDisabled = disabled;
    if (disabled) {
      this.destroyInteract();
    } else {
      this.createInteract();
    }
  }

  get interactableDisabled() {
    return this._interactableDisabled;
  }

  @Input() resizableDisabled = false;
  private _interactableDisabled = false;
  private _interactable: InteractableConfing;
  private interactObj: { unset: () => void };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (!this.interactableDisabled) {
      this.createInteract();
    }
  }

  ngOnDestroy() {
    this.destroyInteract();
  }

  getResizableEdges(edges: ResizableEdges = {}): ResizableEdges {
    return {
      left: false,
      right: false,
      bottom: false,
      top: false,
      ...edges,
    };
  }

  createInteract() {
    if (!this.interactable || this.interactObj) {
      return;
    }

    this.interactObj = interact(this.elementRef.nativeElement)
      .draggable({
        onmove: this.dragMoveListener.bind(this),
        onend: this.emitChange.bind(this),
        inertia: true,
        modifiers: [
            interact.modifiers.restrict({
              restriction: 'parent',
              endOnly: false,
              elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            }),
        ],
      })
      .resizable({
        onmove: this.resizeListener.bind(this),
        onend: this.emitChange.bind(this),
        preserveAspectRatio: false,
        edges: this.resizableDisabled
          ? this.getResizableEdges({})
          : this.getResizableEdges({ right: true, bottom: true }),
        modifiers: [
          interact.modifiers.restrict({
            restriction: 'parent',
            endOnly: false,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
          }),
        ],
      });
  }

  destroyInteract() {
    if (this.interactObj) {
      this.interactObj.unset();
      this.interactObj = null;
    }
  }

  dragMoveListener(event) {
    this.interactable.top += event.dy;
    this.interactable.left += event.dx;
    this.applyTransformation();
  }

  resizeListener(event) {
    // translate when resizing from top or left edges
    this.interactable.left += event.deltaRect.left;
    this.interactable.top += event.deltaRect.top;

    this.interactable.width = event.rect.width;
    this.interactable.height = event.rect.height;
    this.applyTransformation();
  }

  emitChange() {
    this.onInteractableChange.emit(this.interactable);
  }

  private applyTransformation() {
    const { left, top, height, width } = this.interactable;
    const target = this.elementRef.nativeElement;

    this.renderer.setStyle(target, 'transform', `translate(${left}px, ${top}px)`);
    this.renderer.setStyle(target, 'width', `${width}px`);
    this.renderer.setStyle(target, 'height', `${height}px`);
  }
}
