import { inject, Injectable } from '@angular/core';
import { TokenStorageService } from '../services/token/token-storage.service';
import { Actions } from 'features/profiles-state/+state/profiles-state.actions';
import { Store } from '@ngrx/store';
import { requestCompleted } from '@certemy/common';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TokenService } from 'services/token/token.service';
import { DomainService } from 'services/domain/domain.service';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class UpdateProfileGuardService  {
  constructor(
    private tokenStorage: TokenStorageService,
    private store: Store<any>,
    private tokenService: TokenService,
    private domainService: DomainService,
  ) {}

  canActivate() {
    if (this.tokenStorage.isLoggedIn) {
      // If default profile was switched somewhere else
      return this.tokenService.renewToken().pipe(
        switchMap(() => this.domainService.changeDomainIfDomainNameChanged(true).pipe(map(() => false))),
        catchError(() => {
          this.store.dispatch(new Actions.GetProfiles());
          return requestCompleted(this.store.select(Selectors.profilesState));
        }),
      );
    } else {
      return of(true);
    }
  }
}

export const updateProfileGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
  return inject(UpdateProfileGuardService).canActivate();
};
