import { Component, ElementRef, HostBinding, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UrgentActionsService } from './urgent-actions.service';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cm-urgent-actions',
  templateUrl: './urgent-actions.component.html',
  styleUrls: ['./urgent-actions.component.scss'],
})
export class UrgentActionsComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) private wrapper: ElementRef;

  @HostBinding('style.paddingTop') paddingTop;

  get elements$() {
    return this.urgentActionsService.elements$;
  }

  private onDestroy$ = new Subject<void>();

  constructor(private urgentActionsService: UrgentActionsService) {}

  ngOnInit() {
    this.elements$
      .pipe(
        delay(0),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => (this.paddingTop = `${this.wrapper.nativeElement.offsetHeight}px`));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isTemplateRef(something): something is TemplateRef<any> {
    return something instanceof TemplateRef;
  }

  getContext = (element: any) => {
    const close = () => this.urgentActionsService.remove(element);
    return { $implicit: { close } };
  };
}
