import { RouterAction } from '@ngrx/router-store';
import { SerializedRouterState } from './router-state.state';
import { Action } from '@ngrx/store';

export type RouterStateActions = RouterAction<SerializedRouterState, SerializedRouterState> | InitialNavigation;

export class InitialNavigation implements Action {
  static readonly type = '[ROUTER] INITIAL NAVIGATION';
  readonly type = InitialNavigation.type;

  constructor(public payload: { url: string }) {}
}
