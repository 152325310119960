import { Action } from '@ngrx/store';
import { type } from 'services/actions-cache/actions-cache.service';
import { ColorSettings, FontSettings, LogoConfig, PlatformBrandingSettings } from '../platform-branding.interfaces';

export namespace Actions {
  export class GetBrandingSettings implements Action {
    static readonly type = type<'[BRANDING] GetBrandingSettings'>('[BRANDING] GetBrandingSettings');
    readonly type = GetBrandingSettings.type;
  }

  export class GetBrandingSettingsSuccess implements Action {
    static readonly type = type<'[BRANDING] GetBrandingSettingsSuccess'>('[BRANDING] GetBrandingSettingsSuccess');
    readonly type = GetBrandingSettingsSuccess.type;

    constructor(public payload: PlatformBrandingSettings) {}
  }

  export class GetBrandingSettingsError implements Action {
    static readonly type = type<'[BRANDING] GetBrandingSettingsError'>('[BRANDING] GetBrandingSettingsError');
    readonly type = GetBrandingSettingsError.type;
  }

  export class SetPlatformLogo implements Action {
    static readonly type = type<'[BRANDING] SetPlatformLogo'>('[BRANDING] SetPlatformLogo');
    readonly type = SetPlatformLogo.type;
    constructor(public payload: LogoConfig) {}
  }

  export class SetColorSettings implements Action {
    static readonly type = type<'[BRANDING] SetColorSettings'>('[BRANDING] SetColorSettings');
    readonly type = SetColorSettings.type;
    constructor(public payload: ColorSettings) {}
  }

  export class SetFontSettings implements Action {
    static readonly type = type<'[BRANDING] SetFontSettings'>('[BRANDING] SetFontSettings');
    readonly type = SetFontSettings.type;
    constructor(public payload: FontSettings) {}
  }

  export class SetBaseFont implements Action {
    static readonly type = type<'[BRANDING] SetBaseFont'>('[BRANDING] SetBaseFont');
    readonly type = SetBaseFont.type;
    constructor(public payload: string) {}
  }

  export class SetHeadingFont implements Action {
    static readonly type = type<'[BRANDING] SetHeadingFont'>('[BRANDING] SetHeadingFont');
    readonly type = SetHeadingFont.type;
    constructor(public payload: string) {}
  }

  export class Reset implements Action {
    static readonly type = type<'[BRANDING] Reset'>('[BRANDING] Reset');
    readonly type = Reset.type;
  }
}

export type ActionType =
  | Actions.GetBrandingSettings
  | Actions.GetBrandingSettingsSuccess
  | Actions.GetBrandingSettingsError
  | Actions.SetColorSettings
  | Actions.SetFontSettings
  | Actions.SetPlatformLogo
  | Actions.SetBaseFont
  | Actions.SetHeadingFont
  | Actions.Reset;
