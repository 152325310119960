import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ExternalRedirectGuardService  {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    window.location.href = route.data['externalUrl'];
    return true;
  }
}

export const externalRedirectGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
  return inject(ExternalRedirectGuardService).canActivate(route);
};
