import { NgModule } from '@angular/core';

import { TermsAndConditionsComponent } from './term-and-conditions.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [TermsAndConditionsComponent],
  declarations: [TermsAndConditionsComponent],
  providers: [],
})
export class TermsAndConditionsModule {}
