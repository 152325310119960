import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from 'services/permissions/permissions.service';

@Pipe({
  name: 'hasPermissions',
})
export class PermissionsPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) {}

  transform(feature) {
    return this.permissionsService.hasPermissions(feature);
  }
}
