import { HttpErrorResponse } from '@angular/common/http';

export class HttpError extends Error {
  readonly errorType = 'HTTP_ERROR';
  error = true;
  status: number;
  body: any;
  handled = false;
  showIfNotHandled = 'Unknown error occurred. Please try again later.';

  static isHttpError(err: any): err is HttpError {
    return err instanceof HttpError;
  }

  constructor(error: HttpErrorResponse) {
    super(error.message);
    // Class Error breaks the prototype chain
    // https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
    // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html (Support for new.target)
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.body = error.error;
    this.status = error.status;
  }
}
