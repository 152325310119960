import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Selectors as ProfileSelectors } from 'features/profiles-state/+state/profiles-state.state';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComputedFrom } from '@certemy/common';
import { Selectors as RouterSelectors } from 'features/router-state/+state/router-state.state';
import { map, takeUntil } from 'rxjs/operators';
import { ROLE_CODES } from 'constants/role.constants';
import {
  Selectors,
  UserProfile,
} from 'features/organization-settings/platform-branding/+state/platform-branding.state';
import { DEFAULT_LOGO_CONFIG } from 'features/organization-settings/platform-branding/platform-branding.constants';
import { CROPPER_MODES } from '@certemy/ui-components';
import { PolicyPermissionsService } from 'services/policy-permission/policy-permissions.service';
import { PolicyObj } from 'services/policy-permission/policy-permissions.service';

const hasProfileDetailsPage = [ROLE_CODES.SUPERADMIN, ROLE_CODES.ORGANIZATION_ADMIN, ROLE_CODES.PROFESSIONAL];

export namespace Header {
  export interface Settings {
    logoLink: string;
    mainMenu: MainMenuItem[];
    fullWidth?: boolean;
  }

  export interface MainMenuItem {
    link?: string;
    externalLink?: string;
    label: string;
    icon?: string;
    dropdownMenu?: DropdownMenuRow[];
    key?: number;
  }

  export interface DropdownMenuRow {
    primaryLink: DropdownLink;
    secondaryLink?: DropdownLink;
    hiddenLinks?: CommonDropdownLink[];
  }

  export interface CommonDropdownLink {
    link: string;
    queryParams?: any;
  }

  export interface DropdownLink extends CommonDropdownLink {
    label: string;
  }
}

const HEADER_USER_PROFILE_POLICIES: Record<string, PolicyObj> = {
  view: { name: 'view', url: 'settings/users/own_profile', action: 'view' },
};

@Component({
  selector: 'cm-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dropdownMenuState', [
      state('void', style({ height: 0 })),
      transition('void <=> *', animate('500ms cubic-bezier(0.645, 0.045, 0.355, 1)')),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() settings: Header.Settings;
  profile: UserProfile;
  profiles: UserProfile[];
  showMobileMenu = false;
  isDropdownMenuOpened = false;
  CROPPER_MODES = CROPPER_MODES;
  logoConfig$ = this.store
    .select(Selectors.platformLogoConfig)
    .pipe(
      map(({ imageConfig, logoFormType }) =>
        imageConfig.imageFile ? { imageConfig, logoFormType } : DEFAULT_LOGO_CONFIG,
      ),
    );

  profileDetailsLink$ = combineLatest([
    this.store.select(RouterSelectors.getCurrentSection),
    this.store.select(ProfileSelectors.currentProfile),
  ]).pipe(map(([section, profile]) => `/${section}/profile/${profile.profile_id}`));
  private onDestroy$ = new Subject<void>();
  policyPermissions: { view: boolean } = { view: false };

  @ComputedFrom('profile')
  get isLoggedAsProfile() {
    return !!this.profile.origin;
  }

  @ComputedFrom('profile', 'policyPermissions')
  get isProfileLinkVisible() {
    if (!this.policyPermissions.view) {
      return false;
    }

    return this.profile && hasProfileDetailsPage.includes(this.profile.role_id) && !this.isLoggedAsProfile;
  }

  constructor(
    private router: Router,
    private store: Store<any>,
    private policyPermissionsService: PolicyPermissionsService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.store
      .select(ProfileSelectors.profilesState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ currentProfile, availableProfiles }) => {
        this.profile = currentProfile;
        this.profiles = availableProfiles;
      });

    this.policyPermissionsService
      .hasMultiplePermissions(Object.values(HEADER_USER_PROFILE_POLICIES))
      .pipe(take(1))
      .subscribe((permissions: { view: boolean }) => {
        this.policyPermissions = permissions;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onToggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  closeMobileMenu(menuItem: Header.MainMenuItem) {
    this.showMobileMenu = false;
    if (menuItem.externalLink) {
      window.open(menuItem.externalLink, '_blank');
    }
  }

  onLogout() {
    this.router.navigate(['/entry/logout']);
  }

  onRouterLinkClick(menuItem: Header.MainMenuItem) {
    if (menuItem.externalLink) {
      window.open(menuItem.externalLink, '_blank');
    }
  }
}
