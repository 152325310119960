<cm-profile-avatar
    class="avatar-profile"
    [imageUrl]="profile.image"
    [placeholder]="profile.avatarPlaceholder"
></cm-profile-avatar>

<ng-container *ngIf="isLoggedAsProfile; else simpleProfile">
    <div class="info">
        <span class="active-profile-name">{{ profile.origin.first_name + ' ' + profile.origin.last_name }}</span>
        <span class="active-profile-logged-as">logged in as {{ profile.role }}</span>
        <span class="active-profile-logged-as">{{ profile.first_name + ' ' + profile.last_name }}</span>
    </div>
</ng-container>

<ng-template #simpleProfile>
    <div class="info">
        <span class="active-profile-name">{{ profile.first_name + ' ' + profile.last_name }}</span>
        <span class="active-profile-role">
            {{ profile.role }}
            <ng-container *ngIf="profile.organization_name"> at {{ profile.organization_name }}</ng-container>
        </span>
    </div>
</ng-template>
<cm-user-profile-callout
    class="profile-callout"
    (close)="closeProfileCalloutHandler()"
    (mouseleave)="onMouseLeaveEvent()"
    (mouseover)="onProfileMouseOver()"
    [hidden]="!showProfileCallout"
>
</cm-user-profile-callout>
