import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { ShowIfHttpError } from '@certemy/common';
import { Observable } from 'rxjs';
import { buildQueryParams, convertFilterStructToQueryParam, createQueryParam } from 'utils/helpers';
import { AuditListQueryParam } from './audit-list/audit-list.interface';
import { AuditDetailsQueryParam, AuditDetails, CreateAuditQuery } from './audit-details/audit-details.interface';
import { BatchAuditListFilters } from './batch-audit-list/batch-audit-list.constants';
import { AuditDetailsFilters } from './audit-details/audit-details.constants';
import { AuditCertification } from './audit-create-options';
import { Transcript } from 'features/transcript/transcript.interface';
import {
  GetTranscriptProfileParam,
  UpdateAuditProfileParams,
} from 'features/audit/audit-transcript-modal/audit-transcript-modal.interface';
import { BatchAuditListTableData } from 'features/audit/batch-audit-list/batch-audit-list.interface';
import { RollingAuditListFilters } from 'features/audit/rolling-audit-list/rolling-audit-list.constants';
import {
  AuditCertificationQueryParam,
  ResubmitRequiredRollingAuditData,
  RollingAuditListQueryParam,
  RollingAuditListTableData,
} from 'features/audit/rolling-audit-list/rolling-audit-list.interface';
import { SmartForm } from '@certemy/ui-components';
import { WORKFLOW_MODULE_ID } from 'constants/workflow.constants';

@Injectable()
export class AuditService {
  _workflowTypeId: WORKFLOW_MODULE_ID;

  set workflowTypeId(workflowTypeId: WORKFLOW_MODULE_ID) {
    this._workflowTypeId = workflowTypeId;
  }

  constructor(private http: HttpService) {}

  @ShowIfHttpError('Cannot receive batch audit')
  getBatchAuditList(params: AuditListQueryParam = new BatchAuditListFilters()): Observable<BatchAuditListTableData> {
    const queryParams = this.getQueryParams(params);

    return this.http.get(`/audit${queryParams}`);
  }

  @ShowIfHttpError('Cannot receive rolling audit')
  getRollingAuditList(
    { filters, ...params }: RollingAuditListQueryParam = new RollingAuditListFilters(),
  ): Observable<RollingAuditListTableData> {
    const queryParams = this.getQueryParams(params);
    const filtersParams = this.getFiltersParams(filters);

    return this.http.get(`/audit/rolling${queryParams}${filtersParams}`);
  }

  @ShowIfHttpError('Cannot receive resubmit required rolling audit count')
  getResubmitRequiredRollingAuditCount(): Observable<ResubmitRequiredRollingAuditData> {
    const query = { workflowTypeId: this._workflowTypeId };
    const queryParams = this.getQueryParams(query as any);

    return this.http.get(`/audit/rolling/resubmitRequiredCount${queryParams}`);
  }

  @ShowIfHttpError('Cannot receive audit credentials')
  getRollingAuditCertificates(query: AuditCertificationQueryParam): Observable<SmartForm.FieldOption[]> {
    const queryParams = this.getQueryParams(query as any);

    return this.http.get(`/audit/rolling/certificates${queryParams}`);
  }

  @ShowIfHttpError('Cannot receive certifications')
  getCertifications(query: AuditCertificationQueryParam): Observable<AuditCertification[]> {
    const queryParams = this.getQueryParams(query as any);

    return this.http.get(`/audit/certification${queryParams}`);
  }

  @ShowIfHttpError('Cannot run audit')
  createAudit(data: CreateAuditQuery): Observable<AuditDetails> {
    return this.http.post('/audit', data);
  }

  @ShowIfHttpError('Cannot run audit')
  getAudit(id: number, params: AuditDetailsQueryParam = new AuditDetailsFilters()): Observable<AuditDetails> {
    const queryParams = this.getQueryParams(params);

    return this.http.get(`/audit/${id}${queryParams}`);
  }

  @ShowIfHttpError('Cannot delete audit')
  cancelAudit(id: number): Observable<null> {
    return this.http.delete(`/audit/${id}`);
  }

  @ShowIfHttpError('Cannot get transcript profile')
  getTranscriptProfile(id: number, params: GetTranscriptProfileParam): Observable<Transcript[]> {
    const query = convertFilterStructToQueryParam(params);

    return this.http.get(`/transcript/profile/${id}?${query}`);
  }

  @ShowIfHttpError('Cannot update audit')
  updateAuditProfile(id: number, params: UpdateAuditProfileParams): Observable<null> {
    return this.http.put(`/audit/${id}`, params);
  }

  private getQueryParams({
    limit,
    offset,
    orderId,
    orderType,
    search,
    workflowTypeId = this._workflowTypeId,
  }: AuditListQueryParam | AuditDetailsQueryParam): string {
    return buildQueryParams(
      [
        limit && createQueryParam('limit', limit),
        offset && createQueryParam('offset', offset),
        orderId && createQueryParam('orderId', orderId),
        orderType && createQueryParam('orderType', orderType),
        search && createQueryParam('search', search),
        workflowTypeId && createQueryParam('workflowTypeId', workflowTypeId),
      ].filter(Boolean),
    );
  }

  private getFiltersParams(filters): string {
    const submittedFilters = Object.keys(filters).reduce(
      (formattedFilters, key) => ({
        ...formattedFilters,
        ...(filters[key] || filters[key] === 0 ? { [key]: filters[key] } : {}),
      }),
      {},
    );
    const isFiltersProvided = Object.values(submittedFilters).length;

    return isFiltersProvided ? `&${convertFilterStructToQueryParam(submittedFilters)}` : '';
  }
}
