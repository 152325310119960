import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { TokenStorageService } from '../services/token/token-storage.service';
import { NotificationsService } from 'services/notifications/notifications.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private tokenStorage: TokenStorageService,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenStorage.isLoggedIn) {
      return true;
    }

    // console.log('Auth Service not logged in. URL: ', state.url);

    this.tokenStorage.redirectUrl = state.url;
    this.tokenStorage.dropAllTokens();
    this.notificationsService.clearViewedNotifications();
    this.router.navigate(['/entry/login']);
    return false;
  }
}

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuardService).canActivate(route, state);
};
