<div class="menu-item" [class.menu-item__active]="isActive">
    <cm-icon *ngIf="menuItem.icon" [name]="menuItem.icon" class="menu-item__icon"></cm-icon>
    {{ menuItem.label }}
</div>

<ul class="dropdown-menu" [class.dropdown-menu__last]="isLast">
    <li *ngFor="let row of menuItem.dropdownMenu" class="dropdown-menu-row">
        <a
            class="dropdown-menu-row__primary-link"
            [routerLink]="row.primaryLink.link"
            [queryParams]="row.primaryLink.queryParams"
        >
            {{ row.primaryLink.label }}
        </a>
        <a
            *ngIf="row.secondaryLink"
            class="dropdown-menu-row__secondary-link"
            [routerLink]="row.secondaryLink.link"
            [queryParams]="row.secondaryLink.queryParams"
        >
            {{ row.secondaryLink.label }}
        </a>
    </li>
</ul>
