import {
  TRANSCRIPT_REPORT_FORMAT,
  TRANSCRIPT_STEP_DATA_NOTE_TYPE,
  TRANSCRIPT_STEP_DATA_STATUS,
} from 'features/transcript/transcript.interface';

export interface AuditTranscriptModalSetting {
  certificationName: string;
  phaseCycleEnding: string;
  createdAt: string;
  auditId: number;
  professional: {
    firstName: string;
    lastName: string;
  };
  certificationInstanceId: number;
  statusId: AUDIT_PROFILE_STATUS;
  auditType: AUDIT_TYPE;
}

export interface GetTranscriptProfileParam {
  filterByAuditId: number;
  format: TRANSCRIPT_REPORT_FORMAT;
  filterById: number;
  filterByEndYear: string;
}

export enum AUDIT_PROFILE_STATUS {
  UNREVIEWED = 4,
  FAILED,
  REQUESTED_CORRECTIONS,
  APPROVED,
}

export const AUDIT_PROFILE_STATUS_NAME = {
  [AUDIT_PROFILE_STATUS.UNREVIEWED]: 'Unreviewed',
  [AUDIT_PROFILE_STATUS.FAILED]: 'Failed',
  [AUDIT_PROFILE_STATUS.REQUESTED_CORRECTIONS]: 'Requested Corrections',
  [AUDIT_PROFILE_STATUS.APPROVED]: 'Approved',
};

export interface UpdateAuditProfileParams {
  statusId: AUDIT_PROFILE_STATUS;
  certificationInstanceId: number;
  reviewedProfileId: number;
  isStatusChangeRequired: boolean;
  failedNote?: string;
  submitData: {
    submitDataId: number;
    statusId: TRANSCRIPT_STEP_DATA_STATUS;
    notes: {
      profileId: number;
      text: string;
      createdAt: string;
      type: TRANSCRIPT_STEP_DATA_NOTE_TYPE;
    }[];
  }[];
}

export enum AUDIT_TYPE {
  BATCH,
  ROLLING,
}

export interface UpdateAuditOptions {
  failedNote: string;
}
