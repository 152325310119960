import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { UserProfile } from 'services/user/user.interface';
import { Header } from '../header.component';

@Component({
  selector: 'cm-mobile-menu',
  templateUrl: 'mobile-menu.component.html',
  styleUrls: ['mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent {
  @Input() items: Header.MainMenuItem[];
  @Input() profile: UserProfile;
  @Input() profiles: UserProfile[];
  @Output() closeMobileMenu: EventEmitter<Header.MainMenuItem> = new EventEmitter();
  @Output() logout = new EventEmitter();
  showProfiles = false;
}
