import { UserListActions } from 'components/organization-users/users/users.interface';
import { SmartTable } from '@certemy/ui-components';
import { UserListPermissionsConfig } from 'components/organization-users/users/users-token.service';
import _size from 'lodash-es/size';
import { OrganizationsLabelType } from 'features/global-organizations-setting-state/global-organizations-setting.constant';

export const USER_LIST_ACTIONS: UserListActions = {
  ADD: { type: 'Add', name: 'Add user' },
  DELETE: { type: 'Delete', name: 'Delete user' },
  ACTIVATE: { type: 'Activate', name: 'Activate user' },
  DEACTIVATE: { type: 'Deactivate', name: 'Deactivate user' },
  BATCH_UPLOAD: { type: 'Batch_upload', name: 'Batch upload' },
};

export enum INVITE_STATUS {
  SELF_ENROLLED = -1,
  PENDING = 0,
  ACCEPTED = 1,
}

export const INVITE_STATUS_NAME = {
  [INVITE_STATUS.ACCEPTED]: 'Accepted',
  [INVITE_STATUS.PENDING]: 'Pending',
  [INVITE_STATUS.SELF_ENROLLED]: 'N/A',
};

export const DEFAULT_SORT_OPTION = {
  field: 'modifiedAt',
  direction: 'desc',
};

export const USER_STATUSES = {
  ACTIVE: 1,
  DELETED: 2,
  INACTIVE: 3,
};

export const getUserListTableHeaders = (
  permissionsConfig: Partial<UserListPermissionsConfig> = {},
  locationHeaders: SmartTable.Header[] = [],
): SmartTable.Header[] => {
  let result: SmartTable.Header[] = [
    {
      name: '',
      value: 'selected',
      type: SmartTable.ColumnTypeId.CHECK,
      disable: () =>
        !permissionsConfig.canAddUser &&
        !permissionsConfig.canDeleteUser &&
        !permissionsConfig.canActivateUser &&
        !permissionsConfig.canDeactivate &&
        !permissionsConfig.canBatchUpload,
    },
    { name: 'First Name', value: 'first_name', sortable: true },
    { name: 'Last Name', value: 'last_name', sortable: true },
    { name: 'Email', value: 'email', sortable: true },
  ];
  if (permissionsConfig.isOrganizationColumnVisible) {
    result.push({ name: 'Organization', value: 'organization_name' });
  }
  if (permissionsConfig.isRoleColumnVisible) {
    result.push({ name: 'Role', value: 'role' });
    result.push({ name: 'Group(s)', value: 'groups' });
  }
  if (_size(locationHeaders)) {
    result = [...result, ...locationHeaders];
  }
  return result;
};

export enum USER_LIST_TYPE {
  NEW,
  OLD,
}
export enum PROFILE_TYPE_ID {
  INDIVIDUAL = 1,
  FACILITY = 2,
}

export const PROFILE_TYPE_ID_ORGANIZATIONS_LABEL_MAPPER = {
  [PROFILE_TYPE_ID.FACILITY]: OrganizationsLabelType.PROFILE_TYPE_FACILITY,
  [PROFILE_TYPE_ID.INDIVIDUAL]: OrganizationsLabelType.PROFILE_TYPE_INDIVIDUAL,
};

export const DEFAULT_PROFILE_TYPE_ID_NAMES = {
  [PROFILE_TYPE_ID.INDIVIDUAL]: 'Individual',
  [PROFILE_TYPE_ID.FACILITY]: 'Facility',
};
