import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { getSection } from 'utils/helpers';

export interface RouteParams {
  [key: string]: string;
}
export class SerializedRouterState {
  url = '/';
  queryParams: Params = {};
  params: RouteParams = {};
  pathname = '';
  hostname = '';
  host = '';
}

export class RouterState implements RouterReducerState<SerializedRouterState> {
  activatedRoute = new SerializedRouterState();
  state = new SerializedRouterState();
  navigationId = null;
}

export namespace Selectors {
  const getRouter = createFeatureSelector<RouterState>('router');
  /** @deprecated will change on router navigationStart. navigationCancel, navigationError will not revert */
  export const getRouterState = createSelector(
    getRouter,
    router => router.state,
  );
  /** @deprecated */
  export const getRouterQueryParams = createSelector(
    getRouterState,
    state => state.queryParams,
  );
  /** @deprecated */
  export const getRouterParams = createSelector(
    getRouterState,
    state => state.params,
  );

  // Initial router state doesn't contain `activatedRoute`, provide a default one, until RouterNavigated action is fired
  export const getActivatedRouterState = createSelector(
    getRouter,
    router => router.activatedRoute || new SerializedRouterState(),
  );
  export const getActivatedRouterQueryParams = createSelector(
    getActivatedRouterState,
    state => state.queryParams,
  );
  export const getActivatedRouterParams = createSelector(
    getActivatedRouterState,
    state => state.params,
  );
  export const getActivatedUrl = createSelector(
    getActivatedRouterState,
    state => state.url,
  );
  export const getActivatedPathname = createSelector(
    getActivatedRouterState,
    state => state.pathname,
  );
  export const getCurrentSection = createSelector(
    getActivatedRouterState,
    state => getSection(state.url),
  );
}
