import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Actions as OrganizationLabelsActions } from './global-organizations-setting.actions';
import { GlobalOrganizationsSettingService } from 'features/global-organizations-setting-state/global-organizations-setting.service';
import { GlobalOrganizationsLabel } from 'features/global-organizations-setting-state/global-organizations-setting.constant';
import { UserProfile } from 'services/user/user.interface';

@Injectable()
export class GlobalOrganizationSettingsEffects {
  getLabelSettings = createEffect(() => this.actions$.pipe(
    ofType<OrganizationLabelsActions.SetLabelsSettings>(OrganizationLabelsActions.SetLabelsSettings.type),
    map(action => action.payload),
    map((payload: UserProfile) =>
      this.globalOrganizationsSettingService.getLabelSettingsMapFromArr(payload.labels, payload.organization_type_id),
    ),
    tap((settings: GlobalOrganizationsLabel) => this.globalOrganizationsSettingService.setNamesFromArr(settings)),
    tap((settings: GlobalOrganizationsLabel) => this.globalOrganizationsSettingService.setLabelsSettings(settings)),
    map((settings: GlobalOrganizationsLabel) => new OrganizationLabelsActions.SetLabelsSettingsSuccess(settings)),
  ));

  constructor(
    private actions$: Actions,
    private globalOrganizationsSettingService: GlobalOrganizationsSettingService,
  ) {}
}
