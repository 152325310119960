<div class="active-profile">
    <cm-profile-avatar
        class="avatar avatar--big"
        [imageUrl]="profile.image"
        [placeholder]="profile.avatarPlaceholder"
    ></cm-profile-avatar>
    <ng-container *ngIf="isLoggedAsProfile; else simpleProfile">
        <div class="profile profile-container profile-container--big">
            <h2 class="profile__name">{{ profile.origin.first_name }} {{ profile.origin.last_name }}</h2>
            <p class="profile__text-line">
                logged in as {{ profile.role
                }}<ng-container *ngIf="profile.organization_name"> at {{ profile.organization_name }}</ng-container>
            </p>
            <p class="profile__text-line">{{ profile.first_name }} {{ profile.last_name }}</p>
            <p class="profile__text-line profile__email">{{ profile.email }}</p>
        </div>
    </ng-container>
    <ng-template #simpleProfile>
        <div class="profile profile-container profile-container--big">
            <h2 class="profile__name">{{ profile.first_name }} {{ profile.last_name }}</h2>
            <p class="profile__text-line">
                {{ profile.role
                }}<ng-container *ngIf="profile.organization_name"> at {{ profile.organization_name }}</ng-container>
            </p>
            <p class="profile__text-line profile__email">{{ profile.email }}</p>
            <a
                *ngIf="isUserProfileLinkVisible"
                class="cm-link"
                [routerLink]="profileLink"
                (click)="stopPropagation($event)"
                >Profile details</a
            >
        </div>
    </ng-template>
</div>
<div class="possible-profiles">
    <a
        *ngFor="let possibleProfile of profiles"
        [routerLink]="getSwitchProfileLink | thunk: possibleProfile"
        (click)="stopPropagation($event)"
        class="possible-profiles__profile"
    >
        <cm-profile-avatar
            class="avatar avatar--small"
            [imageUrl]="possibleProfile.image"
            [placeholder]="possibleProfile.avatarPlaceholder"
        ></cm-profile-avatar>
        <div class="profile profile-container profile-container--small">
            <h2 class="profile__name">{{ possibleProfile.first_name }} {{ possibleProfile.last_name }}</h2>
            <p class="profile__text-line">
                {{ possibleProfile.role }}
                <ng-container *ngIf="possibleProfile.organization_name">
                    at {{ possibleProfile.organization_name }}</ng-container
                >
            </p>
            <p class="profile__text-line profile__email upcEm">{{ possibleProfile.email }}</p>
        </div>
    </a>
</div>
