import { Injectable } from '@angular/core';
import { ProfileService } from 'services/profile/profile.service';
import { MIXPANEL_EVENTS } from 'constants/mixpanel-events.constants';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class MixpanelService {
  // true - user is identified by profile id
  // false - user uses mixpanel's random distinct id
  private isIdentified = false;

  constructor(private profileService: ProfileService) {
    mixpanel.init(environment.mixpanelToken, {
      persistence: 'localStorage',
      persistence_name: 'mixpanel',
      api_host: 'https://api.mixpanel.com',
    });
  }

  track(event: string, payload?, cb?) {
    mixpanel.track(event, payload, cb);
  }

  register(params?) {
    if (this.isIdentified) {
      this.logout();
    }

    let eventName: string;

    if (params && params.invited) {
      eventName = MIXPANEL_EVENTS.USER_REGISTERED_BY_INVITATION;
    } else {
      eventName = MIXPANEL_EVENTS.USER_REGISTERED;
    }

    const id = mixpanel.get_distinct_id();
    // add previous events to the registered profile
    mixpanel.alias(this.profileService.profile.profile_id, id);
    const { profile } = this.profileService;
    const user = {
      Role: profile.role,
      Organization: profile.organization_name,
      Name: `${profile.first_name} ${profile.last_name}`,
      FirstName: profile.first_name,
      LastName: profile.last_name,
      Email: profile.email,
    };
    mixpanel.track(eventName, user);
    mixpanel.people.set_once(user);
    this.isIdentified = true;
  }

  login(profile) {
    this.identify();
    const user = {
      Role: profile.role,
      Organization: profile.name,
      Name: `${profile.first_name} ${profile.last_name}`,
      FirstName: profile.first_name,
      LastName: profile.last_name,
      Email: profile.email,
    };
    mixpanel.track(MIXPANEL_EVENTS.USER_LOGGED_IN, user);
    mixpanel.people.set_once(user);
  }

  logout() {
    mixpanel.reset();
    this.isIdentified = false;
  }

  private identify() {
    if (!this.profileService.profile) {
      return false;
    }

    mixpanel.identify(this.profileService.profile.profile_id.toString());
    this.isIdentified = true;
  }
}
