import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Provider } from '@angular/core';
import { UrgentActionsService } from 'components/urgent-actions/urgent-actions.service';
import { tap } from 'rxjs/operators';
import _get from 'lodash-es/get';
import { environment } from 'environments/environment';
import { ConfirmReloadComponent } from 'components/confirm-reload/confirm-reload.component';

@Injectable()
export class CompareCommitInterceptor implements HttpInterceptor {
  private notificationWasSend = false;

  constructor(private urgentActionsService: UrgentActionsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.GIT_COMMIT) {
      return next.handle(request).pipe(tap(response => this.compareCommits(response)));
    } else {
      return next.handle(request);
    }
  }

  private compareCommits(response: any) {
    if (this.isReloadNeeded(response) && !this.notificationWasSend) {
      this.notificationWasSend = true;
      this.urgentActionsService.add(ConfirmReloadComponent);
    }
  }

  private isReloadNeeded(response: any) {
    if (response instanceof HttpResponse) {
      const headers = _get(response, 'headers', new HttpHeaders()) as HttpHeaders;
      const lastCommit = headers.get('app-commit-hash');
      return lastCommit && lastCommit !== environment.GIT_COMMIT;
    } else {
      return false;
    }
  }
}

export const CompareCommitInterceptorService: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CompareCommitInterceptor,
  multi: true,
};
