import { Injectable } from '@angular/core';
import { SessionService } from 'services/session/session.service';

@Injectable()
export class NotificationsService {
  private _notificationsIds;
  constructor(private sessionService: SessionService) {}

  clearViewedNotifications() {
    this.sessionService.removeItem('viewedNotifications');
  }

  get viewedNotificationsIds() {
    return this.sessionService.getItem('viewedNotifications');
  }

  addToViewedNotifications(id) {
    this._notificationsIds = this.sessionService.getItem('viewedNotifications') || [];
    this._notificationsIds.push({ id });
    this.sessionService.setItem('viewedNotifications', this._notificationsIds);
  }

  dropUserSession() {
    this.sessionService.clearSession();
  }
}
