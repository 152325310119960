import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, OperatorFunction } from 'rxjs';
import { api_path } from '../../global';
import { environment } from '../../../environments/environment';

import { HttpClient, HttpErrorResponse, HttpHandler } from '@angular/common/http';

import { TokenStorageService } from '../token/token-storage.service';
import { MessageQueryService } from '@certemy/ui-components';
import { HttpError } from 'services/http/http-error.interface';
import { getErrorMessage } from 'utils/helpers';
import { catchError } from 'rxjs/operators';
import _get from 'lodash-es/get';

@Injectable()
export class HttpService extends HttpClient {
  protected apiUrl = api_path;
  private activeRequests = [];
  private requestCounter = 0;
  private enableLogging = false;

  constructor(
    httpHandler: HttpHandler,
    protected messageService: MessageQueryService,
    protected tokenStorage: TokenStorageService,
    protected router: Router,
  ) {
    super(httpHandler);

    if (this.enableLogging && !environment.production) {
      this.get = this.wrapWithProxy(this.get, 'GET');
      this.post = this.wrapWithProxy(this.post, 'POST');
      this.put = this.wrapWithProxy(this.put, 'PUT');
      this.patch = this.wrapWithProxy(this.patch, 'PATCH');
      this.delete = this.wrapWithProxy(this.delete, 'DELETE');
    }
  }

  get<T>(url: string, options?): Observable<any> {
    const req$ = super.get<T>(this.getFullUrl(url), options);
    return this.wrapRequest(req$, options);
  }

  post<T>(url: string, data: any, options?): Observable<any> {
    const req$ = super.post<T>(this.getFullUrl(url), data, options);
    return this.wrapRequest(req$, options);
  }

  put<T>(url: string, data: any, options?): Observable<any> {
    const req$ = super.put<T>(this.getFullUrl(url), data, options);
    return this.wrapRequest(req$, options);
  }

  patch<T>(url: string, data: any, options?): Observable<any> {
    const req$ = super.patch<T>(this.getFullUrl(url), data, options);
    return this.wrapRequest(req$, options);
  }

  delete<T>(url: string, options?): Observable<any> {
    const req$ = super.delete<T>(this.getFullUrl(url), options);
    return this.wrapRequest(req$, options);
  }

  handleHttpError(err, message) {
    return this.addErrorMessage(err, message);
  }

  addErrorMessage(err: HttpError, message = 'Unknown error occurred. Please try again later.'): Observable<HttpError> {
    err.showIfNotHandled = message;
    return throwError(err);
  }

  showIfHttpError<T>(defaultMessage): OperatorFunction<T, T | HttpError> {
    return catchError((err) => this.addErrorMessage(err, getErrorMessage(err, defaultMessage)));
  }

  showSuccessMessage(msg: string) {
    this.messageService.addMessage(msg);
  }

  showErrorMessage(msg: string) {
    this.messageService.addErrorMessage(msg);
  }

  private wrapRequest(req$: Observable<any>, options?): Observable<any> {
    let redirectErr = true;
    if (options && options.withoutErrorRedirect) {
      redirectErr = false;
    }
    return req$.pipe(catchError((err) => this.onCatch(err, redirectErr)));
  }

  getFullUrl(url: string): string {
    return this.apiUrl + url;
  }

  private onCatch(err: HttpErrorResponse, redirectErr: boolean = true): Observable<HttpError> {
    const error = new HttpError(err);

    switch (error.status) {
      case 201:
      case 204:
        // TODO: https://github.com/angular/angular/issues/19413 Replace after fix
        error.handled = true;
        return of(null);
      case 401:
        if (redirectErr) {
          this.tokenStorage.dropAllTokens();
          this.router.navigate(['/entry/login']);
          error.handled = true;
        } else {
          this.messageService.addErrorMessage('Unauthorized access.');
        }
        break;
      case 403:
        if (redirectErr) {
          if (error.body && _get(error, 'body.code_name') === 'ACCESS_DENIED') {
            this.router.navigate(['/access-restricted']);
          } else {
            this.router.navigate(['/forbidden']);
          }
        } else {
          this.messageService.addErrorMessage('Access forbidden.');
        }
        error.handled = true;
        break;
      case 404:
        if (redirectErr) {
          this.router.navigate(['/not-found']);
        } else {
          this.messageService.addErrorMessage('Not found.');
        }
        error.handled = true;
        break;
      case 500:
        this.messageService.addErrorMessage('Internal server error.');
        error.handled = true;
        break;
    }

    return throwError(error);
  }

  private wrapWithProxy(fn, fnName) {
    return new Proxy(fn, {
      apply(target, thisArg, argumentsList) {
        const id = thisArg.requestCounter++;
        const newRequest = { url: argumentsList[0], method: fnName, id };
        thisArg.activeRequests = [...thisArg.activeRequests, newRequest];
        console.log(`>>> ${newRequest.method} ${newRequest.url} >>>`, thisArg.activeRequests);
        return target.apply(thisArg, argumentsList).finally(() => {
          let completedRequest;
          thisArg.activeRequests = thisArg.activeRequests.filter((request) => {
            if (request.id === id) {
              completedRequest = { ...request };
              return false;
            }

            return true;
          });
          console.log(`<<< ${completedRequest.method} ${completedRequest.url} <<<`, thisArg.activeRequests);
        });
      },
    });
  }
}
