import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { routing } from './app.routes';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { TokenStorageService } from 'services/token/token-storage.service';
import { TokenService } from 'services/token/token.service';
import { HttpService } from 'services/http/http.service';
import { ProfileService } from 'services/profile/profile.service';
import { UserService } from 'services/user/user.service';
import { MixpanelService } from 'services/mixpanel/mixpanel.service';
import { ResizeService } from 'services/resize/resize.service';
import { RequestInterceptorService } from 'services/interceptors/header.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MetaReducersService } from './services/meta-reducer/meta-reducer.service';
import { SentryLoggerService } from 'services/sentry-logger/sentry-logger.service';
import { AppInitializerService } from 'services/initializer/initializer.service';
import {
  SidebarModule,
  SmartModalModule,
  TypeaheadConfigService,
  LoaderModule,
  MessageQueryModule,
  DATEPICKER_DELIMITER_TOKEN,
  DEFAULT_DATEPICKER_DELIMITER,
} from '@certemy/ui-components';
import { ErrorPagesModule } from 'pages/error-pages/error-pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterStateModule } from 'features/router-state/router-state.module';
import { ErrorHandlerProvider } from 'services/certemy-error-handler/certemy-error-handler.service';
import { ProfilesStateModule } from 'features/profiles-state/profiles-state.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ENVIRONMENT, LoggerService, LOGGER_OPTIONS } from '@certemy/common';
import { ConfirmationModule } from 'components/confirmation/confirmation.module';
import { UrgentActionsModule } from 'components/urgent-actions/urgent-actions.module';
import { ConfirmReloadModule } from 'components/confirm-reload/confirm-reload.module';
import { CompareCommitInterceptorService } from 'services/interceptors/compare-commit.interceptor';
import _get from 'lodash-es/get';
import { PermissionsService } from 'services/permissions/permissions.service';
import { PatternValidatorDirective } from 'directives/pattern.validator';
import { NumberRangeValidatorDirective } from 'directives/number-range.validator';
import { FeatureToggleModule } from './features/feature-toggle/feature-toggle.module';
import { PolicyPermissionsService } from 'services/policy-permission/policy-permissions.service';
import { NotificationsService } from 'services/notifications/notifications.service';
import { SessionService } from 'services/session/session.service';
import { PlatformBrandingStateModule } from 'features/organization-settings/platform-branding/+state/platform-branding-state.module';
import { PlatformBrandingService } from 'features/organization-settings/platform-branding/platform-branding.service';
import { CookieService } from 'ngx-cookie-service';
import { DomainService } from 'services/domain/domain.service';
import { LoginStatusService } from 'services/token/login-status.service';
import { TermsAndConditionsModalModule } from 'pages/entry/terms-and-conditions-modal/terms-and-conditions-modal.module';
import { PaymentFormModule } from 'features/payment-form/payment-form.module';
import { AuthzService } from 'services/authz/authz.service';
import { CasbinService } from 'services/casbin/casbin.service';
import { GlobalOrganizationSettingsStateModule } from 'features/global-organizations-setting-state/global-organizations-setting-state.module';
import { PdfTemplateService } from 'services/pdf-template/pdf-template.service';
import { ClientServicesService } from 'services/client-services/client-services.service';
import { PsvService } from 'services/psv/psv.service';
import { LoginProviderModule } from 'services/login-provider/login-provider.module';
import { CustomDatePickerParserFormatter } from 'services/datepicker-formatter/datepicker-formatter.service';
import { LicenseMonitoringService } from 'services/license-monitoring/license-monitoring.service';
import { ExternalRedirectGuardService } from 'components/external-redirect/external-redirect.guard';
import { UpdateProfileGuardService } from 'directives/update-profile.guard';
import {
  PolicyMultiplePermissionsGuardService,
  PolicyPermissionsGuardService,
} from 'directives/policy-permissions.guard';
import { CredentialingVerificationListPolicyPermissionsGuardService } from 'directives/credentialing-verification-list-policy-permission.guard';
import { RoleGuardService } from 'directives/role-guard';
import { AuthGuardService } from 'directives/auth-guard';
import { CanDeactivateGuardService } from 'directives/can-deactivate-guard';
import { OtpService } from 'services/otp/otp.service';
import { AuthnService } from 'services/authn/authn.service';

@NgModule({
  declarations: [AppComponent, PatternValidatorDirective, NumberRangeValidatorDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    routing,
    /** See: https://github.com/ngrx/platform/tree/master/docs/store#setup */
    StoreModule.forRoot({}, { runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),

    /** See: https://github.com/zalmoxisus/redux-devtools-extension */
    environment.production ? [] : StoreDevtoolsModule.instrument({ connectInZone: true }),

    /** See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot */
    EffectsModule.forRoot([]),

    NgbModule,
    LoaderModule.forRoot(),
    ErrorPagesModule,
    SidebarModule.forRoot(),
    RouterStateModule,
    ProfilesStateModule,
    PlatformBrandingStateModule,
    GlobalOrganizationSettingsStateModule,
    SmartModalModule.forRoot(),
    MessageQueryModule.forRoot(),
    ConfirmationModule,
    UrgentActionsModule.forRoot(),
    ConfirmReloadModule,
    FeatureToggleModule,
    TermsAndConditionsModalModule,
    PaymentFormModule,
    LoginProviderModule,
  ],
  providers: [
    PolicyPermissionsService,
    AuthGuardService,
    RoleGuardService,
    CanDeactivateGuardService,
    ProfileService,
    ClientServicesService,
    UserService,
    TokenService,
    NotificationsService,
    SessionService,
    TokenStorageService,
    MixpanelService,
    ResizeService,
    SentryLoggerService,
    HttpService,
    AuthzService,
    AuthnService,
    OtpService,
    PermissionsService,
    PsvService,
    CasbinService,
    AppInitializerService,
    MetaReducersService,
    RequestInterceptorService,
    ErrorHandlerProvider,
    { provide: TypeaheadConfigService, useValue: { escapeSearch: false } as Partial<TypeaheadConfigService> },
    UpdateProfileGuardService,
    PolicyPermissionsGuardService,
    PolicyMultiplePermissionsGuardService,
    CredentialingVerificationListPolicyPermissionsGuardService,
    CompareCommitInterceptorService,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: LOGGER_OPTIONS,
      useValue: {
        shouldSendCallback(data) {
          const exception = _get(data, 'exception.values[0].value', '');
          const isHttpExceptionThrownByBrowser =
            exception.startsWith('Http failure response') && !_get(data, 'extra.body');
          return !isHttpExceptionThrownByBrowser;
        },
      },
    },
    LoggerService,
    PlatformBrandingService,
    CookieService,
    DomainService,
    LoginStatusService,
    PdfTemplateService,
    ExternalRedirectGuardService,
    LicenseMonitoringService,
    {
      provide: DATEPICKER_DELIMITER_TOKEN,
      useValue: DEFAULT_DATEPICKER_DELIMITER,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDatePickerParserFormatter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
