import { SmartForm } from '@certemy/ui-components';

export enum ROLE_CODES {
  SUPERADMIN = 1,
  ORGANIZATION_ADMIN = 3,
  PROFESSIONAL = 8,
  IMPOSER = 10,
  SUPERVISOR = 14,
  EMPLOYER = 15,
  PARTNER = 19,
}

export interface Role {
  id: number;
  name: string;
}

export const ROLE_NAMES = {
  [ROLE_CODES.SUPERADMIN]: 'Super Admin',
  [ROLE_CODES.ORGANIZATION_ADMIN]: 'Organization Admin',
  [ROLE_CODES.PROFESSIONAL]: 'Professional',
  [ROLE_CODES.IMPOSER]: 'Imposer',
  [ROLE_CODES.SUPERVISOR]: 'Supervisor',
  [ROLE_CODES.EMPLOYER]: 'Employer',
  [ROLE_CODES.PARTNER]: 'Partner',
};

export const ROLE_OPTIONS: Role[] = [
  { id: ROLE_CODES.ORGANIZATION_ADMIN, name: ROLE_NAMES[ROLE_CODES.ORGANIZATION_ADMIN] },
  { id: ROLE_CODES.PROFESSIONAL, name: ROLE_NAMES[ROLE_CODES.PROFESSIONAL] },
  { id: ROLE_CODES.IMPOSER, name: ROLE_NAMES[ROLE_CODES.IMPOSER] },
  { id: ROLE_CODES.EMPLOYER, name: ROLE_NAMES[ROLE_CODES.EMPLOYER] },
];

export enum ORGANIZATION_ADMIN_GROUP_CODES {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  REVIEWER = 3,
  EXECUTIVE = 4,
}
