import { NgModule } from '@angular/core';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { GdprConfirmationComponent } from './gdpr-confirmation/gdpr-confirmation.component';
import { GdprConfirmationService } from './gdpr-confirmation/gdpr-confirmation.service';
import { InfoConfirmationComponent } from './info-confirmation/info-confirmation.component';

@NgModule({
  imports: [CertemyCommonModule],
  declarations: [GdprConfirmationComponent, InfoConfirmationComponent],
  exports: [GdprConfirmationComponent, InfoConfirmationComponent],
  providers: [GdprConfirmationService],
})
export class ConfirmationModule {}
