import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@certemy/ui-components';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { UserProfilesListModule } from 'components/user-profiles-list/user-profiles-list.module';
import { UserProfileCalloutModule } from './user-profile-callout/user-profile-callout.module';
import { ActiveProfileModule } from './active-profile/active-profile.module';

import { HamburgerIconComponent } from './hamburger-icon/hamburger-icon.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';

import { HeaderComponent } from './header.component';
import { HeaderSimpleComponent } from './header-simple.components';

@NgModule({
  imports: [
    CertemyCommonModule,
    RouterModule,
    UserProfileCalloutModule,
    IconModule,
    ActiveProfileModule,
    NgbTooltipModule,
    UserProfilesListModule,
  ],
  exports: [HeaderComponent, HeaderSimpleComponent],
  declarations: [
    HeaderComponent,
    HeaderSimpleComponent,
    HamburgerIconComponent,
    MobileMenuComponent,
    DropdownMenuComponent,
  ],
})
export class HeaderModule {}
