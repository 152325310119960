<div class="tac-part">
    <div class="tac-header">
        <h4 class="tac-header"><b>CERTEMY, INC. (“CERTEMY”, “WE” OR “US”) TERMS OF SERVICE</b></h4>
    </div>
    <p class="tac-paragraph">
        THESE TERMS AND CONDITIONS (THE "TERMS") ARE A LEGAL CONTRACT BETWEEN YOU AND CERTEMY. THE TERMS EXPLAIN HOW YOU
        ARE PERMITTED TO USE THE WEBSITE LOCATED AT THE URL: WWW.CERTEMY.COM AS WELL AS ALL ASSOCIATED SITES LINKED TO
        WWW.CERTEMY.COM BY CERTEMY, ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY, THE "SITE"). BY USING THIS
        SITE, YOU ARE AGREEING TO ALL THE TERMS; IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE
        USE THIS SITE, ANY SERVICES AVAILABLE THROUGH THIS SITE OR ANY INFORMATION CONTAINED ON THIS SITE.
    </p>
    <p class="tac-paragraph">
        THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER THAT AFFECTS
        YOUR RIGHTS UNDER THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH CERTEMY. YOU MAY OPT OUT OF THE
        BINDING INDIVIDUAL ARBITRATION AND CLASS ACTION WAIVER AS PROVIDED BELOW.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Changes.</b></h4>
    <p class="tac-paragraph">
        Certemy may make changes to the content and services offered on the Site (the “Services”) at any time. Certemy
        can change, update, or add or remove provisions of these Terms, at any time by posting the updated Terms on this
        Site and by using this Site after Certemy has updated the Terms, you are agreeing to all the updated Terms; if
        you do not agree with any of the updated Terms, you must stop using the Site.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>General Use.</b></h4>
    <p class="tac-paragraph">
        By using this Site, you represent, acknowledge and agree that you are at least 18 years of age, or if you are
        under 18 years of age but are at least 13 years old (a “Minor”), that you are using the Site with the consent of
        your parent or legal guardian and that you have received your parent’s or legal guardian’s permission to use the
        Site and agree to its Terms. If you are a parent or legal guardian of a Minor, you hereby agree to bind the
        Minor to these Terms and to fully indemnify and hold harmless Certemy if the Minor breaches any of these Terms.
        If you are not at least 13 years old, you may not use the Site at any time or in any manner or submit any
        information to Certemy or the Site.
    </p>
    <p class="tac-paragraph">
        Certemy provides content through, or allows users of the Site or Services to provide content through (including
        as described under the heading “Communications Among Users” below), the Site and through the Services, including
        content that is or may be copyrighted and/or trademarked work of Certemy or Certemy’s third-party licensors and
        suppliers or other users of the Site (collectively, the “Materials”). Materials may include logos, graphics,
        video, images, software and other content.
    </p>
    <p class="tac-paragraph">
        Subject to the terms and conditions of these Terms, and your compliance with these Terms, Certemy hereby grants
        you a limited, personal, non-exclusive and non-transferable license to use and to display the Materials and to
        use this Site solely for your personal use. Except for the foregoing license, including as may be set forth in
        any other agreement governing your use of the Services, you have no other rights in the Site or any Materials
        and you may not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance or
        in any way exploit any of the Site or Materials in any manner.
    </p>
    <p class="tac-paragraph">
        If you breach any of these Terms, the above license will terminate automatically and you must immediately
        destroy any downloaded or printed Materials.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Using the Site and the Services on the Site.</b></h4>
    <p class="tac-paragraph">
        You can simply view the Site and not use any Services on the Site. You need not register with Certemy to simply
        visit and view the Site.
    </p>
    <p class="tac-paragraph">
        However, in order to access certain password-restricted areas of the Site and to use certain Services and
        Materials offered on and through the Site, you must register with Certemy for an account and receive a password.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Password Restricted Areas of this Site.</b></h4>
    <p class="tac-paragraph">
        If you desire to register for an account with Certemy, you may be required to submit some or all of the
        following information through the account registration page on the Site: email address, first name, last name,
        industry (customers create custom password). You will also have the ability to provide additional optional
        information, such as industry, which is not required to register for an account but may be helpful to Certemy in
        providing you with more a more customized experience when using the Site or its Services. Once you have
        submitted your account registration information, Certemy administrator shall have the right to approve or reject
        the requested registration, in Certemy administrator’s sole discretion. If your account is approved by Certemy
        administrator, you will be sent an e-mail that contains a password that will allow you to log-on to the Site
        using that password (the “Certemy Password”) for the first time you log into your account on the Site to
        complete the account registration process.
    </p>
    <p class="tac-paragraph">
        You are responsible for maintaining the confidentiality of your Certemy Password ("Password"), and you are
        responsible for all activities that occur using your Password. You agree not to share your Password, let others
        access or use your Password or do anything else that might jeopardize the security of your Password. You agree
        to notify Certemy if your Password on this Site is lost, stolen, if you are aware of any unauthorized use of
        your Password on this Site or if you know of any other breach of security in relation to this Site.
    </p>
    <p class="tac-paragraph">
        All the information that you provide when registering for an account and otherwise through the Site must be
        accurate, complete and up to date. You may change, correct or remove any information from your account by either
        logging into your account directly and making the desired changes or contacting Certemy using the contact
        information at the end of these Terms requesting that we make the change.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Electronic Communications.</b></h4>
    <p class="tac-paragraph">
        By using the Site and/or the Services provided on or through the Site, you consent to receiving electronic
        communications from Certemy. These electronic communications may include notices about applicable fees and
        charges, transactional information and other information concerning or related to the Site and/or Services
        provided on or through the Site. These electronic communications are part of your relationship with Certemy. You
        agree that any notices, agreements, disclosures or other communications that we send you electronically will
        satisfy any legal communication requirements, including that such communications be in writing.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Privacy Policy.</b></h4>
    <p class="tac-paragraph">
        Please review Certemy’s Privacy Policy, which is available at
        <a class="cm-link" target="_blank" href="https://www.certemy.com/privacy-policy">www.certemy.com</a> (the
        “Privacy Policy”) which explains how we use information that you submit to Certemy.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Communications Among Users</b></h4>
    <p class="tac-paragraph">
        By using the Site and/or the Services provided on or through the Site, you acknowledge and agree that the Site
        serves as a portal through which information, including information concerning your credential, license
        qualification, or similar status is displayed or communicated by and among users of the Site and the Services,
        including, without limitation, the applicable third party that grants such credential, license, qualification or
        similar status (a “Certifying Body”), and is not a communication by or on behalf of Certemy. In accordance
        therewith, without limiting the generality of the foregoing, any and all information concerning your credential,
        qualification, license or similar status provided, generated or displayed to you or any other person using or
        accessing the Site is solely a communication by the applicable Certifying Body. Similarly, any information
        provided by you on or through the Site and/or the Services is solely a communication by you to the applicable
        Certifying Body and/or third party accessing or using the Site and/or the Services. Without limiting the
        generality of the provisions under the headings “Disclaimer of Warranties” and “Limitation of Liability” below,
        Certemy disclaims any responsibility for the accuracy of any such communications among users of the Site or
        Services as described above and shall have no liability whatsoever as a result thereof.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Links to Third-Party Sites.</b></h4>
    <p class="tac-paragraph">
        This Site may be linked to other web sites that are not Certemy sites (collectively, “Third-Party Sites”).
        Certain areas of the Site may allow you to interact and/or conduct transactions with such Third-Party Site and,
        in certain situations, you may be transferred to a Third-Party Site through a link but it may appear that you
        are still on this Site. In any case, you acknowledge and agree that the Third-Party Sites may have different
        privacy policies and terms and conditions and/or user guides and business practices than Certemy, and you
        further acknowledge and agree that your use of such Third-Party Sites is governed by the respective Third-Party
        Site privacy policy and terms and conditions and/or user guides. You hereby agree to comply with any and all
        terms and conditions, users guides and privacy policies of any of Third-Party Sites. Certemy is providing links
        to the Third-Party Sites to you as a convenience, and Certemy does not verify, make any representations or take
        responsibility for such Third-Party Sites, including, without limitation, the truthfulness, accuracy, quality or
        completeness of the content, services, links displayed and/or any other activities conducted on or through such
        Third-Party Sites. YOU AGREE THAT CERTEMY WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECTLY
        OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR THROUGH ANY
        THIRD-PARTY SITES AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY
        DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR
        BUSINESS PRACTICES OF ANY THIRD-PARTY. Any reference on the Site to any product, service, publication,
        institution, organization of any third-party entity or individual does not constitute or imply Certemy's
        endorsement or recommendation.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Submissions.</b></h4>
    <p class="tac-paragraph">
        You are responsible for the information, opinions, messages, comments, photos, videos, graphics, sounds and
        other content or material that you submit, upload, post or otherwise make available on or through the Site (each
        a “Submission”) and through the Services available in connection with this Site. You may not upload, post or
        otherwise make available on this Site any material protected by copyright, trademark, or any other proprietary
        right without the express permission of the owner of such copyright, trademark or other proprietary right owned
        by a third-party, and the burden of determining whether any material is protected by any such right is on you.
        You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary
        rights, violation of contract, privacy or publicity rights or any other harm resulting from any Submission that
        you make. You have full responsibility for each Submission you make, including its legality, reliability and
        appropriateness.
    </p>
    <p class="tac-paragraph">
        Unless otherwise explicitly stated herein or in Certemy Privacy Policy, you agree that any Submission provided
        by you in connection with this Site is provided on a non-proprietary and non-confidential basis. You hereby
        grant to Certemy a non-exclusive, perpetual, irrevocable, royalty-free, fully paid-up, worldwide license
        (including the right to sublicense through multiple tiers) to use, reproduce, process, adapt, publicly perform,
        publicly display, modify, prepare derivative works, publish, transmit and distribute each of your Submissions,
        or any portion thereof, in any form, medium or distribution method now known or hereafter existing, known or
        developed, and authorize others to use the Submissions. We may modify or adapt your Submissions in order to
        transmit, display or distribute them over computer networks and in various media and/or make changes to the
        Submissions as necessary to conform and adapt them to any requirements or limitations of any networks, devices,
        services or media. Certemy agrees to use any personally identifiable information contained in any of your
        Submissions in accordance with Certemy’s Privacy Policy.
    </p>
    <p class="tac-paragraph">
        You agree to pay for all royalties, fees, damages and any other monies owing any person by reason of any
        Submissions posted by you to or through this Site.
    </p>
    <p class="tac-paragraph">
        When you provide Submissions you agree that those Submissions shall not be in violation of the “Unauthorized
        Activities” paragraph below.
        <b
            >Those prohibitions do not require Certemy to monitor, police or remove any Submissions or other information
            submitted by you or any other user.</b
        >
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Unauthorized Activities.</b></h4>
    <p class="tac-paragraph">When using this Site and/or the services, you agree not to:</p>
    <ul class="terms__listing">
        <li>
            Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and
            publicity) of others.
        </li>
        <li>Use racially, ethnically, or otherwise offensive language.</li>
        <li>Discuss or incite illegal activity.</li>
        <li>Use explicit/obscene language or solicit/post sexually explicit images (actual or simulated).</li>
        <li>Post anything that exploits children or minors or that depicts cruelty to animals.</li>
        <li>Post any copyrighted or trademarked materials without the express permission from the owner.</li>
        <li>
            Disseminate any unsolicited or unauthorized advertising, promotional materials, 'junk mail', 'spam', 'chain
            letters', 'pyramid schemes', or any other form of such solicitation.
        </li>
        <li>Use any robot, spider, scraper or other automated means to access the Site.</li>
        <li>Take any action that imposes an unreasonable or disproportionately large load on our infrastructure.</li>
        <li>Alter the opinions or comments posted by others on this Site.</li>
        <li>Post anything clearly false or misleading.</li>
        <li>Post anything unrelated to our business, products or services.</li>
        <li>
            Post anything contrary to our public image, goodwill or reputation, provided that the foregoing will not
            apply to you if applicable law prohibits such limitations and restrictions.
        </li>
    </ul>
    <p class="tac-paragraph"></p>
    <p class="tac-paragraph">
        This list of prohibitions provides examples and is not complete or exclusive. Certemy reserves the right to (a)
        terminate access to your account, your ability to post to this Site (or use the Services) and (b) refuse, delete
        or remove any Submissions; with or without cause and with or without notice, for any reason or no reason, or for
        any action that Certemy determines is inappropriate or disruptive to this Site or to any other user of this Site
        and/or Services.
        <b
            >Certemy may report to law enforcement authorities any actions that may be illegal, and any reports it
            receives of such conduct. When legally required or at Certemy’s discretion, Certemy will cooperate with law
            enforcement agencies in any investigation of alleged illegal activity on this Site or on the Internet.</b
        >
    </p>
    <p class="tac-paragraph">
        Unauthorized use of any Materials or third-party content contained on this Site may violate certain laws and
        regulations.
    </p>
    <p class="tac-paragraph">
        You agree to indemnify and hold Certemy and its officers, directors, employees, affiliates, agents, licensors,
        and business partners harmless from and against any and all costs, damages, liabilities, and expenses (including
        attorneys’ fees and costs of defense) Certemy or any other indemnified party suffers in relation to, arising
        from, or for the purpose of avoiding, any claim or demand from a third-party that your use of this Site or the
        use of this Site by any person using your user name and/or password (including without limitation, your
        participation in the posting areas or, your Submissions) violates any applicable law or regulation, or the
        copyrights, trademark rights or other rights of any third-party.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Proprietary Rights.</b></h4>
    <p class="tac-paragraph">
        Certemy is a trademark of Certemy in the United States. Other trademarks, names and logos on this Site are the
        property of their respective owners.
    </p>
    <p class="tac-paragraph">
        Unless otherwise specified in these Terms, all information and screens appearing on this Site, including
        documents, services, site design, text, graphics, logos, images and icons, as well as the arrangement thereof,
        are the sole property of Certemy. All rights not expressly granted herein are reserved. Except as otherwise
        required or limited by applicable law, any reproduction, distribution, modification, retransmission, or
        publication of any copyrighted material is strictly prohibited without the express written consent of the
        copyright owner or license.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Intellectual Property Infringement.</b></h4>
    <p class="tac-paragraph">
        Certemy respects the intellectual property rights of others, and we ask you to do the same. Certemy may, in
        appropriate circumstances and at our discretion, terminate service and/or access to this Site for users who
        infringe the intellectual property rights of others. If you believe that your work is the subject of copyright
        infringement and/or trademark infringement and appears on our Site, please provide Certemy’s designated agent
        the following information:
    </p>
    <ul class="terms__listing">
        <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed.
        </li>
        <li>
            Identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple
            works at a single online site are covered by a single notification, a representative list of such works at
            that site.
        </li>
        <li>
            Identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled at the Site, and information reasonably
            sufficient to permit Certemy to locate the material.
        </li>
        <li>
            Information reasonably sufficient to permit Certemy to contact you as the complaining party, such as an
            address, telephone number, and, if available, an electronic mail address at which you may be contacted.
        </li>
        <li>
            A statement that you have a good faith belief that use of the material in the manner complained of is not
            authorized by the copyright and/or trademark owner, its agent, or the law.
        </li>
        <li>
            A statement that the information in the notification is accurate, and under penalty of perjury, that you are
            authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        </li>
    </ul>
    <p class="tac-paragraph"></p>
    <p class="tac-paragraph">
        Certemy’s agent for notice of claims of copyright or trademark infringement on this Site can be reached as
        follows:<br /><br />
        Certemy, Inc.<br />
        3420 Bristol Street, Suite 509<br />
        Costa Mesa, CA 92626<br />
        Attn: Legal Department<br /><br />
    </p>
    <p class="tac-paragraph">
        Please also note that for copyright infringements under Section 512(f) of the Copyright Act, any person who
        knowingly materially misrepresents that material or activity is infringing may be subject to liability.
    </p>
    <h5 class="tac-underlined">Submitting a DMCA Counter-Notification</h5>
    <p class="tac-paragraph">
        We will notify you that we have removed or disabled access to copyright-protected material that you provided, if
        such removal is pursuant to a valid DMCA take-down notice that we have received. If you receive such notice from
        us, you may provide us with a counter-notification in writing to Certemy designated agent that includes all of
        the following information:
    </p>
    <ol class="terms__listing">
        <li>Your physical or electronic signature;</li>
        <li>
            Identification of the material that has been removed or to which access has been disabled, and the location
            at which the material appeared before it was removed or access to it was disabled;
        </li>
        <li>
            A statement from you under the penalty of perjury, that you have a good faith belief that the material was
            removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled;
            and
        </li>
        <li>
            Your name, physical address and telephone number, and a statement that you consent to the jurisdiction of a
            court for the judicial district in which your physical address is located, or if your physical address is
            outside of the United States, for any judicial district in which Certemy may be located, and that you will
            accept service of process from the person who provided notification of allegedly infringing material or an
            agent of such person.
        </li>
    </ol>
    <h5 class="tac-underlined">Termination of Repeat Infringers</h5>
    <p class="tac-paragraph"></p>
    <p class="tac-paragraph">
        Certemy reserves the right, in its sole discretion, to terminate the account or access of any user of our web
        site and/or service who is the subject or repeated DMCA or other infringement notifications.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Disclaimer of Warranties.</b></h4>
    <p class="tac-paragraph">
        Your use of this Site and/or the Services is at your own risk. The Materials have not been verified or
        authenticated in whole or in part by Certemy, and they may include inaccuracies or typographical or other
        errors. Certemy does not warrant the accuracy of timeliness of the Materials contained on this Site. Certemy has
        no liability for any errors or omissions in the Materials, whether provided by Certemy, our licensors or
        suppliers or other users.
    </p>
    <p class="tac-paragraph">
        CERTEMY, FOR ITSELF AND ITS LICENSORS, MAKES NO EXPRESS, IMPLIED OR STATUTORY REPRESENTATIONS, WARRANTIES, OR
        GUARANTEES IN CONNECTION WITH THIS SITE, THE SERVICES, OR ANY MATERIALS RELATING TO THE QUALITY, SUITABILITY,
        TRUTH, ACCURACY OR COMPLETENESS OF ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THIS SITE, INCLUDING
        WITHOUT LIMITATION THE MATERIALS. UNLESS OTHERWISE EXPLICITLY STATED, TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, THIS SITE, THE SERVICES, AND MATERIALS, AND ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED
        ON THIS SITE IS PROVIDED TO YOU ON AN “AS IS,” “AS AVAILABLE” AND “WHERE-IS” BASIS WITH NO WARRANTY OF IMPLIED
        WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
        CERTEMY DOES NOT PROVIDE ANY WARRANTIES AGAINST VIRUSES, SPYWARE OR MALWARE THAT MAY BE INSTALLED ON YOUR
        COMPUTER.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Limitation of Liability.</b></h4>
    <p class="tac-paragraph">
        CERTEMY SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR DISPLAYING, COPYING, OR DOWNLOADING ANY
        MATERIALS TO OR FROM THIS SITE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CERTEMY BE
        LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
        DAMAGES (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) HOWEVER ARISING, EVEN IF
        CERTEMY KNOWS THERE IS A POSSIBILITY OF SUCH DAMAGE.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Local Laws; Export Control.</b></h4>
    <p class="tac-paragraph">
        Certemy controls and operates this Site from its headquarters in the United States of America and the Materials
        may not be appropriate or available for use in other locations. If you use this Site outside the United States
        of America, you are responsible for following applicable local laws.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Feedback.</b></h4>
    <p class="tac-paragraph">
        If you send or transmit any communications, comments, questions, suggestions, or related materials to Certemy,
        whether by letter, email, telephone, or otherwise (collectively, “Feedback”), suggesting or recommending changes
        to the Site, any Services offered through the Site or Materials, including, without limitation, new features or
        functionality relating thereto, all such Feedback is, and will be treated as, non-confidential and
        non-proprietary. Except as prohibited by applicable law, you hereby assign all right, title, and interest in,
        and Certemy is free to use, without any attribution or compensation to you, any ideas, know-how, concepts,
        techniques, or other intellectual property and proprietary rights contained in the Feedback, whether or not
        patentable, for any purpose whatsoever, including but not limited to, developing, manufacturing, having
        manufactured, licensing, marketing, and selling, directly or indirectly, products and services using such
        Feedback. Where the foregoing assignment is prohibited by law, you hereby grant Us an exclusive, transferable,
        worldwide, royalty-free, fully paid up license (including the right to sublicense) to use and exploit all
        Feedback as We may determine in our sole discretion. Notwithstanding the foregoing, you understand and agree
        that Certemy is not obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or
        techniques contained in the Feedback, and you have no right to compel such use, display, reproduction, or
        distribution.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Language.</b></h4>
    <p class="tac-paragraph">
        The Parties hereto have expressly required that this agreement and all documents and notices relating thereto be
        drafted in the English language.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>General.</b></h4>
    <p class="tac-paragraph">
        Certemy prefers to advise you if we feel you are not complying with these Terms and to recommend any necessary
        corrective action. However, certain violations of these Terms, as determined by Certemy, may result in immediate
        termination of your access to this Site without prior notice to you. The Federal Arbitration Act, California
        state law and applicable U.S. federal law, without regard to the choice or conflicts of law provisions, will
        govern these Terms. Foreign laws do not apply. The United Nations on Contracts for the International Sale of
        Goods and any laws based on the Uniform Computer Information Transactions Act (UCITA) shall not apply to this
        Agreement. Any disputes relating to these Terms or this Site will be heard in the courts located in Los Angeles
        County in the State of California. If any of these Terms is found to be inconsistent with applicable law, then
        such term shall be interpreted to reflect the intentions of the parties, and no other terms will be modified.
        Certemy’s failure to enforce any of these Terms is not a waiver of such term. These Terms are the entire
        agreement between you and Certemy and supersede all prior or contemporaneous negotiations, discussions or
        agreements between you and Certemy about this Site. The proprietary rights, disclaimer of warranties,
        representations made by you, indemnities, limitations of liability and general provisions shall survive any
        termination of these Terms.
    </p>
</div>
<div class="tac-part">
    <h4 class="tac-header"><b>Contact Us.</b></h4>
    <p class="tac-paragraph">
        If you have any questions about these Terms or otherwise need to contact Certemy for any reason, you can reach
        us at legal&#64;certemy.com or (866) 907-4088.
    </p>
</div>
<div class="tac-part">
    <div class="terms__title">
        <h4 class="tac-header"><b>DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER.</b></h4>
        <h5 class="tac-header"><b>Please Read This Provision Carefully. It Affects Your Legal Rights.</b></h5>
    </div>
    <p class="tac-paragraph">
        This Dispute Resolution and Arbitration; Class Action Waiver Provision (this “Provision”) facilitates the prompt
        and efficient resolution of any dispute (e.g., claim or controversy, whether based in contract, statute,
        regulation, ordinance, tort – including, but not limited to, fraud, misrepresentation, fraudulent inducement, or
        negligence – or any other legal or equitable theory, and includes the validity, enforceability or scope of this
        Provision (with the exception of the enforceability of the Class Action Waiver clause below) that may arise
        between you and Certemy (a “Dispute”). Effectively, then, “Dispute” is given the broadest meaning enforceable by
        law and includes any claims against other parties relating to services or products provided or billed to you
        (such as sur licensors, suppliers, dealers or third-party vendors) whenever you also assert claims against
        Certemy in the same proceeding.
    </p>
    <p class="tac-paragraph">
        This Provision provides that all disputes between you and Certemy shall be resolved by binding arbitration
        because acceptance of these Terms constitutes a waiver of your right to litigate claims and all opportunity to
        be heard by a judge or jury. To be clear, there is no judge or jury in arbitration, and court review of an
        arbitration award is limited. The arbitrator must follow this agreement and can award the same damages and
        relief as a court (including attorney’s fees). You may, however, opt-out of this Provision which means you would
        have a right or opportunity to bring claims in a court, before a judge or jury, and/or to participate in or be
        represented in a case filed in court by others (including, but not limited to, class actions). EVERYONE AGREES
        THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED
        ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
        ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS PROVISION.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Pre-Arbitration Claim Resolution</h5>
    <p class="tac-paragraph">
        For all Disputes other than payment disputes, whether pursued in court or arbitration, you must first give
        Certemy an opportunity to resolve the Dispute which is first done by emailing Certemy at legal&#64;certemy.com the
        following information: (1) your name, (2) your address, (3) A written description of your reason and basis for
        the dispute, and (4) A description of the specific relief you seek. If we do not resolve the Dispute within 45
        days after receiving your notification, then you may pursue your Dispute in arbitration. You may pursue your
        dispute in a court only under the circumstances described below.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Exclusions from Arbitration/Right to Opt Out</h5>
    <p class="tac-paragraph">
        Notwithstanding the above, you or Certemy may choose to pursue a Dispute in court and not by arbitration if: (a)
        The dispute qualifies for initiation in small claims court; or (b) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES
        WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the “Opt-Out Deadline”). You may opt-out
        of this Provision by emailing Certemy at legal&#64;certemy.com the following information: (1) your name; (2) your
        address; (3) a clear statement that you do not wish to resolve disputes with Certemy through arbitration. Your
        decision to opt-out of this Arbitration Provision will have no adverse effect on your relationship with Certemy.
        Note that any opt-out request received after the Opt-Out Deadline will not be valid and you must pursue your
        dispute in arbitration or small claims court.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Arbitration Procedures</h5>
    <p class="tac-paragraph">
        If this Provision applies and the dispute is not resolved as provided above (Pre-Arbitration Claim Resolution)
        either you or Certemy may initiate arbitration proceedings. The American Arbitration Association (“AAA”),
        www.adr.org, or JAMS, www.jamsadr.com, will arbitrate all disputes, and the arbitration will be conducted before
        a single arbitrator. The arbitration shall be commenced as an individual arbitration only, and shall in no event
        be commenced as a class arbitration or a consolidated or representative action or arbitration. All issues shall
        be for the arbitrator to decide, including the scope of this Provision.
    </p>
    <p class="tac-paragraph">
        For arbitration before AAA, for Disputes of less than $75,000, the AAA’s Supplementary Procedures for
        Consumer-Related Disputes will apply; for Disputes involving $75,000 or more, the AAA’s Commercial Arbitration
        Rules will apply. In either instance, the AAA’s Optional Rules For Emergency Measures Of Protection shall apply.
        The AAA rules are available at www.adr.org or by calling 1-800-778-7879. For arbitration before JAMS, the JAMS
        Comprehensive Arbitration Rules & Procedures and the JAMS Recommended Arbitration Discovery Protocols For
        Domestic, Commercial Cases will apply. The JAMS rules are available at www.jamsadr.com or by calling
        1-800-352-5267. This Provision governs in the event it conflicts with the applicable arbitration rules. Under no
        circumstances will class action or representative procedures or rules apply to the arbitration.
    </p>
    <p class="tac-paragraph">
        Because these Terms concern interstate commerce, the Federal Arbitration Act (“FAA”) governs the arbitrability
        of all disputes. However, the arbitrator will apply applicable substantive law consistent with the FAA and the
        applicable statute of limitations or condition precedent to suit.
    </p>
    <p class="tac-paragraph">
        Arbitration Award – The arbitrator may award on an individual basis any relief that would be available pursuant
        to applicable law, and will not have the power to award relief to, against or for the benefit of any person who
        is not a party to the proceeding. The arbitrator will make any award in writing but need not provide a statement
        of reasons unless requested by a party or if required by applicable law. Such award will be final and binding on
        the parties, except for any right of appeal provided by the FAA or other applicable law, and may be entered in
        any court having jurisdiction over the parties for purposes of enforcement.
    </p>
    <p class="tac-paragraph">
        Location of Arbitration – You or Certemy may initiate arbitration in either Los Angeles, California or the
        federal judicial district that includes your billing address.
    </p>
    <p class="tac-paragraph">
        Payment of Arbitration Fees and Costs – So long as you place a request in writing prior to commencement of the
        arbitration, Certemy will pay all arbitration filing fees and AAA or JAMS hearing fees and any arbitrator's
        hearing fees, costs and expenses upon your written request to the arbitrator given at or before the first
        evidentiary hearing in the arbitration. But, you will still be responsible for all additional fees and costs
        that you incur in the arbitration which include but are not limited to attorneys’ fees or expert witnesses. In
        addition to any fees and costs recoverable under applicable law, if you provide notice and negotiate in good
        faith with Certemy as provided in the section above titled “Pre-Arbitration Claim Resolution” and the arbitrator
        concludes that you are the prevailing party in the arbitration, you will be entitled to recover reasonable
        attorneys’ fees and costs as determined by the arbitrator.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Class Action Waiver</h5>
    <p class="tac-paragraph">
        Except as otherwise provided in this Provision, the arbitrator may not consolidate more than one person’s
        claims, and may not otherwise preside over any form of a class or representative proceeding or claims (such as a
        class action, consolidated action, representative action, or private attorney general action) unless both you
        and Certemy specifically agree to do so in writing following initiation of the arbitration. If you choose to
        pursue your Dispute in court by opting out of the Arbitration Provision, as specified above, this Class Action
        Waiver will not apply to you. Neither you, nor any other user of the Site can be a class representative, class
        member, or otherwise participate in a class, consolidated, or representative proceeding without having complied
        with the opt-out requirements above.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Jury Waiver</h5>
    <p class="tac-paragraph">
        You understand and agree that by accepting this Provision in these Terms, you and Certemy are each waiving the
        right to a jury trial or a trial before a judge in a public court. In the absence of this Provision, you and
        Certemy might otherwise have had a right or opportunity to bring disputes in a court, before a judge or jury,
        and/or to participate or be represented in a case filed in court by others (including class actions). Except as
        otherwise provided below, those rights are waived. Other rights that you would have if you went to court (e.g.,
        the rights to both appeal and certain types of discovery) may be more limited or may also be waived.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Severability</h5>
    <p class="tac-paragraph">
        If any clause within this Provision (other than the Class Action Waiver clause above) is found to be illegal or
        unenforceable, that clause will be severed from this Provision whose remainder will be given full force and
        effect. If the Class Action Waiver clause is found to be illegal or unenforceable, this entire Provision will be
        unenforceable and the dispute will be decided by a court.
    </p>
</div>
<div class="tac-part">
    <h5 class="tac-underlined">Continuation</h5>
    <p class="tac-paragraph">
        This Provision shall survive the termination of your account with Certemy or our affiliates and your
        discontinued use of this Site. Notwithstanding any provision in this Agreement to the contrary, Certemy agree
        that if it makes any change to this Provision (other than a change to the Notice Address), you may reject any
        such change and require Certemy to adhere to the language in this Provision existing as of the date the dispute
        arose.
    </p>
</div>
