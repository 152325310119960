import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { Subject } from 'rxjs';
import { UserProfile } from 'services/user/user.interface';

@Component({
  selector: 'cm-user-profile-callout',
  templateUrl: './user-profile-callout.component.html',
  styleUrls: ['./user-profile-callout.component.scss'],
})
export class UserProfileCalloutComponent implements OnInit, OnDestroy {
  profile: UserProfile;
  profiles: UserProfile[];

  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.store
      .select(Selectors.profilesState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ availableProfiles, currentProfile }) => {
        this.profiles = availableProfiles;
        this.profile = currentProfile;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
