import { Injectable } from '@angular/core';
import * as casbin from 'casbin';
import { ROLE_CODES } from 'constants/role.constants';
import { from, Observable, of, throwError } from 'rxjs';
import { Permissions } from 'services/permissions/permissions.interface';
import { PermissionsService } from 'services/permissions/permissions.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class CasbinService {
  private enforcer: casbin.Enforcer;
  private model: casbin.Model;
  private roleId: number;
  private organizationId: number;

  ////// THE MASTER SWITCH TO REMOVE PERMISSIONS, SET TO FALSE TO DISABLE
  private readonly masterSwitchToEnablePermissions: boolean = true;

  constructor(private permissionService: PermissionsService) {}

  public isMasterSwitchEnabled() {
    return this.masterSwitchToEnablePermissions;
  }

  public isPermissionEnabled() {
    if (this.isMasterSwitchEnabled()) {
      // @TODO: Enable this for all roles?
      if (this.roleId === ROLE_CODES.ORGANIZATION_ADMIN) {
        return true;
      }
    }
    return false;
  }

  private getModel(): Observable<any> {
    if (this.model) {
      return of(this.model);
    }
    return this.permissionService.getEnforcerModel().pipe(
      map((response: Permissions.PolicyEnforcerModelResponse) => {
        if (response.data.enforcerModel) {
          // decode the model base64 encoded string
          const policyModel = window.atob(response.data.enforcerModel);
          this.model = casbin.newModelFromString(policyModel);
          return this.model;
        } else {
          console.error('Missing model');
          return throwError('Missing model');
        }
      }),
    );
  }

  initialize(roleId: number, organizationId: number): Observable<any> {
    // set the roleId;
    this.roleId = roleId;
    this.organizationId = organizationId;

    if (this.isPermissionEnabled() === false) {
      return of(true);
    }

    const policyObjectPrefix = '';

    return this.getModel().pipe(
      switchMap(() => from(casbin.newEnforcer(this.model))),
      tap((enforcer: casbin.Enforcer) => (this.enforcer = enforcer)),
      switchMap(() => this.setup(policyObjectPrefix)),
      switchMap((response: Permissions.PolicyListResponse) => {
        if (this.enforcer) {
          const policeArr = [];
          response.data.forEach((policyConfig: Permissions.PolicyConfig) => {
            policeArr.push(
              this.enforcer.addPolicy.apply(this.enforcer, [
                policyConfig.object,
                policyConfig.action,
                policyConfig.effect,
              ]),
            );
          });
          return from(Promise.all(policeArr));
        } else {
          console.error('Missing enforcer');
          return of(true);
        }
      }),
      catchError(() => of(true)),
    );
  }

  clearPolicies() {
    this.model = null;
    this.enforcer = null;
  }

  cachePoliciesFromSession() {
    return this.permissionService.cachePoliciesFromSession();
  }

  setup(policyObject: string, policySubjectOverride?: Permissions.PolicyConfigOverride) {
    return this.permissionService.getPolicies(policyObject, policySubjectOverride);
  }

  async checkPermission(
    policyObject: string,
    policyAction: string,
    forceCheck: boolean = false,
    policySubjectOverride?: Permissions.PolicyConfigOverride,
  ): Promise<boolean> {
    if (this.isPermissionEnabled() === false && !forceCheck) {
      return Promise.resolve(true);
    }
    if (!this.enforcer) {
      return Promise.resolve(false);
    }

    const enforcementResult = await this.enforcer.enforce(policyObject, policyAction);

    // console.log(policyObject, policyAction, enforcementResult);
    // enforce the action
    return enforcementResult;
  }
}
