import { UserProfile } from 'services/user/user.interface';
export { UserProfile } from 'services/user/user.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResponseStatus } from '@certemy/common';
import { PlatformBrandingSettings } from '../platform-branding.interfaces';
import { DEFAULT_PLATFORM_SETTINGS } from '../platform-branding.constants';

export class PlatformBrandingState {
  brandingSettings: PlatformBrandingSettings = DEFAULT_PLATFORM_SETTINGS;
  status: ResponseStatus = ResponseStatus.INITIAL;
}

const brandingState = createFeatureSelector<PlatformBrandingState>('branding');
const brandingSettings = createSelector(
  brandingState,
  state => state.brandingSettings,
);
const platformLogoConfig = createSelector(
  brandingSettings,
  settings => ({
    imageConfig: settings.avatar,
    logoFormType: settings.logoFormType,
  }),
);

const platformColorSettings = createSelector(
  brandingSettings,
  settings => settings.colorSettings,
);

const platformFontSettings = createSelector(
  brandingSettings,
  settings => ({
    baseFont: settings.baseFont,
    headingFont: settings.headingFont,
  }),
);

export const Selectors = {
  brandingState,
  brandingSettings,
  platformLogoConfig,
  platformColorSettings,
  platformFontSettings,
};
