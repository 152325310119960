import { Component } from '@angular/core';
import { BaseErrorPageConfig } from '../base-error-page/base-error-page.component';

@Component({
  selector: 'cm-access-restricted',
  templateUrl: './access-restricted.component.html',
})
export class AccessRestrictedComponent {
  config: BaseErrorPageConfig = {
    header: 'Access Restricted',
    body:
      "Your access to this page has been restricted. Please contact your organization's administrator to request access.",
  };
}
