import { Injectable } from '@angular/core';
import { Features } from './feature-toggle.interface';
import { Selectors as ProfileSelectors } from 'features/profiles-state/+state/profiles-state.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import _flatten from 'lodash-es/flatten';

@Injectable()
export class FeatureToggleService {
  private features$: Observable<Features>;

  constructor(private store: Store<any>) {
    this.features$ = this.store.select(ProfileSelectors.features);
  }

  hasFeatures(feature: string[] | string): Observable<boolean> {
    return this.features$.pipe(map(features => _flatten([feature]).every(featureName => features[featureName])));
  }
}
