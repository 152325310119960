import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot } from '@angular/router';
import { getMergedChildRouteParams } from 'utils/helpers';
import { SerializedRouterState } from './+state/router-state.state';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export class CustomRouterStateSerializer implements RouterStateSerializer<SerializedRouterState> {
  serialize(routerState: RouterStateSnapshot): SerializedRouterState {
    const { url } = routerState;
    const { pathname, hostname, host } = new URL(url, `${window.location.protocol}//${window.location.host}`);
    const queryParams = routerState.root.queryParams;
    const params = getMergedChildRouteParams(routerState.root);
    return { url, queryParams, params, pathname, hostname, host };
  }
}
