import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { globalOrganizationSettingsReducer } from 'features/global-organizations-setting-state/global-organizations-setting.reducer';
import { GlobalOrganizationSettingsEffects } from 'features/global-organizations-setting-state/global-organizations-setting.effects';
import { GlobalOrganizationsSettingService } from 'features/global-organizations-setting-state/global-organizations-setting.service';

@NgModule({
  imports: [
    StoreModule.forFeature('globalOrganizationSettings', globalOrganizationSettingsReducer),
    EffectsModule.forFeature([GlobalOrganizationSettingsEffects]),
  ],
  providers: [GlobalOrganizationsSettingService],
})
export class GlobalOrganizationSettingsStateModule {}
