import { Action } from '@ngrx/store';
import { type } from 'services/actions-cache/actions-cache.service';
import { GlobalOrganizationsLabel } from 'features/global-organizations-setting-state/global-organizations-setting.constant';
import { UserProfile } from 'services/user/user.interface';

export namespace Actions {
  export class SetLabelsSettings implements Action {
    static readonly type = type<'[GLOBAL_ORGANIZATION_SETTING] SetLabelsSettings'>(
      '[GLOBAL_ORGANIZATION_SETTING] SetLabelsSettings',
    );
    readonly type = SetLabelsSettings.type;
    constructor(public payload: UserProfile) {}
  }

  export class SetLabelsSettingsSuccess implements Action {
    static readonly type = type<'[GLOBAL_ORGANIZATION_SETTING] SetLabelsSettingsSuccess'>(
      '[GLOBAL_ORGANIZATION_SETTING] SetLabelsSettingsSuccess',
    );
    readonly type = SetLabelsSettingsSuccess.type;

    constructor(public payload: GlobalOrganizationsLabel) {}
  }

  export class Reset implements Action {
    static readonly type = type<'[GLOBAL_ORGANIZATION_SETTING] Reset'>('[GLOBAL_ORGANIZATION_SETTING] Reset');
    readonly type = Reset.type;
  }
}

export type ActionType = Actions.SetLabelsSettings | Actions.SetLabelsSettingsSuccess | Actions.Reset;
