import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { Observable } from 'rxjs';
import { ShowIfHttpError } from '@certemy/common';
import { DomainService } from 'services/domain/domain.service';
import { TokenStorageService } from 'services/token/token-storage.service';
import { HttpHeaders } from '@angular/common/http';
import {
  getAdjustedPlatformSettings,
  updateCssVariablesForPlatformSettings,
} from 'features/organization-settings/platform-branding/platform-branding.helpers';
import { Actions as BrandingActions } from 'features/organization-settings/platform-branding/+state/platform-branding.actions';
import { PlatformBrandingSettings } from 'features/organization-settings/platform-branding/platform-branding.interfaces';
import _get from 'lodash-es/get';
import { Store } from '@ngrx/store';

@Injectable()
export class PlatformBrandingService {
  constructor(
    private http: HttpService,
    private domainService: DomainService,
    private tokenStorageService: TokenStorageService,
    private store: Store<any>,
  ) {}

  @ShowIfHttpError('An error occurred while updating platform settings.')
  updateSettings(platformSettings) {
    return this.http.put(`/organization-branding-settings`, platformSettings);
  }

  @ShowIfHttpError('An error occurred while getting platform settings. Default settings will be applied')
  getPlatformBrandingSettings(): Observable<any> {
    return this.http.get(
      `/organization-branding-settings?domainName=${this.domainService.getDomainNameFromUrl() ||
        this.domainService.getDomainNameFromToken(this.tokenStorageService.userToken)}`,
    );
  }

  @ShowIfHttpError('An error occurred. Please try again later.')
  getBrandingSettingsByVerificationToken(
    verificationToken: string,
  ): Observable<{ domainName: string; organizationBrandingSettings: PlatformBrandingSettings }> {
    const headers = new HttpHeaders({ email_verification_token: `${verificationToken}` });
    return this.http.get(`/user/email/branding_settings`, { headers });
  }

  initPlatformBrandingOrRedirect(data: { domainName: string; organizationBrandingSettings: PlatformBrandingSettings }) {
    if (!data) {
      return;
    }

    const domainName = _get(data, 'domainName', '');
    if (domainName) {
      this.domainService.redirectAfterInit(domainName);
      return;
    }

    const settings = _get(data, 'organizationBrandingSettings');
    const adjustedSettings = getAdjustedPlatformSettings(settings);
    updateCssVariablesForPlatformSettings(adjustedSettings);
    this.store.dispatch(new BrandingActions.GetBrandingSettingsSuccess(adjustedSettings));
  }
}
