import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TokenService } from 'services/token/token.service';
import { TokenStorageService } from 'services/token/token-storage.service';
import * as myGlobals from './global';
import { environment } from '../environments/environment';
import { NgbDatepickerConfig, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-mini';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { debounceTime, delay, filter, scan, switchMap, take, takeWhile } from 'rxjs/operators';
import { of, NEVER, combineLatest } from 'rxjs';
import { Selectors } from 'features/router-state/+state/router-state.state';
import { Store } from '@ngrx/store';
import { UserService } from 'services/user/user.service';
import { Selectors as ProfileSelectors } from 'features/profiles-state/+state/profiles-state.state';
import { registerLocaleData } from '@angular/common';
import frCA from '@angular/common/locales/fr-CA';
import { CasbinService } from 'services/casbin/casbin.service';
import { UserProfile } from 'services/user/user.interface';
import en from '@angular/common/locales/en';

@Component({
  selector: 'cm-certemy',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private renewInterval;
  private renewIntervalTime = 30;
  private currentProfile: UserProfile;

  constructor(
    private tokenService: TokenService,
    private tokenStorage: TokenStorageService,
    private datepickerConfig: NgbDatepickerConfig,
    private ngbPaginationConfig: NgbPaginationConfig,
    private router: Router,
    private ngZone: NgZone,
    private store: Store<any>,
    private userService: UserService,
    private casbinService: CasbinService,
  ) {}

  async ngOnInit() {
    this.setDatepickerRange();
    this.setPaginationConfig();

    // check to see if the tokens need to be renewed
    this.tokenRenew();
    // protractor wait till ngZone will be stable
    this.ngZone.runOutsideAngular(() => {
      this.renewInterval = setInterval(() => this.tokenRenew(), this.renewIntervalTime * myGlobals.MINUTE);
    });

    this.handleFirstErroredRoute();
    this.handleRouterChangeUrl();
    this.handleCurrencyProfileChange();

    console.log(`Git commit: ${environment.GIT_COMMIT}`);
  }

  ngOnDestroy() {
    clearInterval(this.renewInterval);
  }

  tokenRenew(): any {
    if (this.tokenStorage.userToken && this.isTokenNeedRenew()) {
      this.tokenService.renewToken().subscribe(null, err => console.log(err));
    }
  }

  private handleRouterChangeUrl() {
    combineLatest([this.store.select(Selectors.getActivatedUrl), this.store.select(ProfileSelectors.currentProfile)])
      .pipe(
        debounceTime(500),
        filter(
          ([url, profile]) =>
            this.tokenStorage.isLoggedIn &&
            !this.userService.isIgnoreSessionUrls(url) &&
            !this.isAdminLoginAsProf(profile),
        ),
        switchMap(([url, profile]) => this.userService.setUserLatestSessionUrl(url)),
      )
      .subscribe();
  }

  private isAdminLoginAsProf(profile: UserProfile): boolean {
    return !!(profile && profile.origin);
  }

  private handleCurrencyProfileChange() {
    this.store
      .select(ProfileSelectors.currentProfile)
      .pipe(filter(value => !!value))
      .subscribe(currentProfile => {
        this.currentProfile = currentProfile;

        switch (currentProfile.currency) {
          case 'usd':
            return registerLocaleData(en);
          case 'cad':
            registerLocaleData(en);
            registerLocaleData(frCA);
            return;
          case 'eur':
          default:
            return registerLocaleData(frCA);
        }
      });
  }

  private handleFirstErroredRoute() {
    this.router.events
      .pipe(
        takeWhile(event => {
          return !(event instanceof NavigationEnd);
        }),
        switchMap(event => {
          if (event instanceof NavigationError || event instanceof NavigationCancel) {
            // in case of other redirect logic, after NavigationError should be no redirect(RouterEvents)
            return of(null).pipe(delay(100));
          } else {
            return NEVER;
          }
        }),
        scan<any, any>((totalTry) => totalTry + 1, 0),
        take(2),
      )
      .subscribe(totalTry => {
        if (totalTry === 1) {
          this.router.navigateByUrl('/');
        } else {
          // Prevent recursive fallback
          this.router.navigateByUrl('/not-found');
        }
      });
  }

  private isTokenNeedRenew(): boolean {
    // We need some delta in time, because server and client has different timestamp for token.
    const timeDelta = 5;
    return (
      this.tokenStorage.isTokenExpired(this.renewIntervalTime + timeDelta) &&
      !this.tokenStorage.isTokenExpired(timeDelta)
    );
  }

  private setDatepickerRange() {
    const currentDate = moment();

    this.datepickerConfig.minDate = {
      day: currentDate.get('date'),
      month: currentDate.get('month') + 1,
      year: currentDate.get('year') - 100,
    };
    this.datepickerConfig.maxDate = {
      day: currentDate.get('date'),
      month: currentDate.get('month') + 1,
      year: currentDate.get('year') + 100,
    };
  }

  private setPaginationConfig() {
    this.ngbPaginationConfig.rotate = true;
  }
}
