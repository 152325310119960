import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { ShowIfHttpError } from '@certemy/common';
import { Observable } from 'rxjs';
import { LoginProviderResponse } from 'services/login-provider/login-provider.interface';
import { DomainService } from 'services/domain/domain.service';
import { HttpParams } from '@angular/common/http';
import { api_path, DEFAULT_PRODUCTION_DOMAIN_NAME } from '../../global';
import { LoaderService } from '@certemy/ui-components';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EntryService {
  private _messageType: any = {};

  constructor(private http: HttpService, private domainService: DomainService, private loaderService: LoaderService) {}

  getMessageType() {
    return this._messageType;
  }

  setMessageType(messageType) {
    this._messageType = messageType;
  }

  getOrganizationRegister(id = '') {
    return this.getAuthRegisterProfile(`?signUpDataId=${id}`);
  }

  getProfessionalRegister() {
    return this.getAuthRegisterProfile();
  }

  private getAuthRegisterProfile(queryParams: string = '') {
    return this.http.get(`/auth/register/profile${queryParams}`);
  }

  setUserRegister(body) {
    return this.http.post(`/auth/register/profile`, body);
  }

  setSelfEnrollmentProfessionalRegister(body) {
    return this.http.post(`/self_enrollment/register/profile`, body);
  }

  @ShowIfHttpError('An error occurred while getting login providers.')
  getLoginProviderByDomainName(): Observable<LoginProviderResponse> {
    const domainName = this.domainService.getDomainNameFromUrl() || DEFAULT_PRODUCTION_DOMAIN_NAME;
    return this.http.get(`/auth/login-provider/${domainName}`);
  }

  oneLoginBtnClick(params) {
    const href = `${api_path}/auth/onelogin/login`;
    this.changeHref(params, href);
  }

  oktaBtnClick(params) {
    const href = `${api_path}/auth/okta/login`;
    this.changeHref(params, href);
  }

  azureBtnClick(params) {
    const href = `${api_path}/auth/azure/login`;
    this.changeHref(params, href);
  }

  entraBtnClick(params) {
    const href = `${api_path}/auth/entra/login`;
    this.changeHref(params, href);
  }

  pingBtnClick(params) {
    const href = `${api_path}/auth/ping/login`;
    this.changeHref(params, href);
  }

  private changeHref(params, href) {
    this.loaderService.show();
    const queryParams = new HttpParams({
      fromObject: params,
    }).toString();
    return (window.location.href = `${href}?${queryParams}`);
  }
}
