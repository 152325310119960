import { ResponseStatus } from '@certemy/common';
import { LoginProviderState } from 'services/login-provider/+state/login-provider.state';
import { Actions, ActionType } from 'services/login-provider/+state/login-provider.actions';
import { DEFAULT_LOGIN_PROVIDER } from 'services/login-provider/login-provider.constants';

export function loginProviderReducer(state = new LoginProviderState(), action: ActionType) {
  switch (action.type) {
    case Actions.GetLoginProviders.type:
      return {
        ...state,
        status: ResponseStatus.PENDING,
      };
    case Actions.GetLoginProvidersSuccess.type:
      return {
        ...state,
        ...action.payload,
        status: ResponseStatus.COMPLETE,
      };
    case Actions.GetLoginProvidersError.type:
      return {
        ...state,
        loginProviders: DEFAULT_LOGIN_PROVIDER,
        status: ResponseStatus.ERROR,
      };
    case Actions.Reset.type:
      return {
        ...state,
        loginProviders: DEFAULT_LOGIN_PROVIDER,
        status: ResponseStatus.INITIAL,
      };
    default:
      return state;
  }
}
