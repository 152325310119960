import { Injectable } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
export interface SentryLogState {
  actions: string[];
}

@Injectable()
export class SentryLoggerService {
  private state: SentryLogState = {
    actions: [],
  };
  private readonly stackSize = 20;

  constructor() {
    this.logger = this.logger.bind(this);
  }

  logger(reducer: ActionReducer<any>) {
    return (state: any, action: any): any => {
      const newState = reducer(state, action);
      this.addLog(action.type);

      return newState;
    };
  }

  getLogs() {
    return { actions: JSON.stringify(this.state.actions) };
  }

  private addLog(action: string) {
    let actions: string[];

    if (this.state.actions.length < this.stackSize) {
      actions = [...this.state.actions, action];
    } else {
      const [_, ...restActions] = this.state.actions;
      actions = [...restActions, action];
    }

    this.state = { actions };
  }
}
