import { Injectable } from '@angular/core';
import { MessageQueryService, SmartModalCloseType, SmartModalService } from '@certemy/ui-components';
import { LOGIN_STATUSES } from 'constants/auth.constants';
import { LOGIN_ERRORS } from 'pages/entry/entry.helpers';
import { TermsAndConditionsModalComponent } from 'pages/entry/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntryService } from 'services/entry/entry.service';
import { Router } from '@angular/router';

@Injectable()
export class LoginStatusService {
  constructor(
    private messageQueryService: MessageQueryService,
    private smartModalService: SmartModalService,
    private entryService: EntryService,
    private router: Router,
  ) {}

  handleLoginError(
    loginStatus: LOGIN_STATUSES,
    showNotification = false,
    email = '',
  ): Observable<{ success: boolean; acceptTerms?: boolean; errorMessage?: string }> {
    switch (loginStatus) {
      case LOGIN_STATUSES.INACTIVE_PROFILE:
        return this.handleError(LOGIN_ERRORS.DEACTIVATED_ACCOUNT, showNotification);

      case LOGIN_STATUSES.FAIL:
        return this.handleError(LOGIN_ERRORS.INCORRECT_INFO, showNotification);

      case LOGIN_STATUSES.MEMBER_CLICKS_ERROR:
        return this.handleError(LOGIN_ERRORS.MEMBER_CLICKS_ERROR, showNotification);

      case LOGIN_STATUSES.MEMBER_CLICKS_VALIDATION_ERROR:
        return this.handleError(LOGIN_ERRORS.MEMBER_CLICKS_VALIDATION_ERROR, showNotification);

      case LOGIN_STATUSES.CONFIRMATION_REQUIRED:
        this.entryService.setMessageType({ msg: 'professional', email });
        this.router.navigate(['/entry/message']);
        return of({ success: false });

      case LOGIN_STATUSES.TERMS_AND_CONDITIONS_NOT_ACCEPTED:
        return this.smartModalService.open(TermsAndConditionsModalComponent, {}, { size: 'lg' }).pipe(
          map(({ type }: { type: SmartModalCloseType }) => {
            const success = type !== SmartModalCloseType.REJECT;
            return { success, acceptTerms: success };
          }),
        );

      default:
        return of({ success: true });
    }
  }

  processLoginStatus(loginStatus: LOGIN_STATUSES): void {
    switch (loginStatus) {
      case LOGIN_STATUSES.CERTIFICATION_ALREADY_EXIST:
        this.messageQueryService.addErrorMessage(LOGIN_ERRORS.EXISTING_CERTIFICATION);
        break;
      case LOGIN_STATUSES.TESTING_WORKFLOW_ALREADY_EXIST:
        this.messageQueryService.addErrorMessage(LOGIN_ERRORS.EXISTING_WORKFLOW);
    }
  }

  private handleError(errorMessage: string, showNotification: boolean) {
    if (showNotification) {
      this.messageQueryService.addErrorMessage(errorMessage);
    }
    return of({ success: false, ...(!showNotification ? { errorMessage } : {}) });
  }
}
