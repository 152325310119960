import { Injectable } from '@angular/core';
import { ShowIfHttpError } from '@certemy/common';
import { Observable } from 'rxjs';
import { HttpService } from 'services/http/http.service';
import { PsvService } from 'services/psv/psv.service';
import {
  GetIngestionInfoResponse,
  GetIngestionInfoSourceDetailsResponse,
  LicenseMonitoringReportProgressPayload,
  LicenseMonitoringTask,
  MonitoringResultEdgeDocument,
} from './license-monitoring.interfaces';

@Injectable()
export class LicenseMonitoringService {
  constructor(private psvService: PsvService, private http: HttpService) {}

  @ShowIfHttpError('An error has occured.')
  getMonitoringTask(id: string): Observable<LicenseMonitoringTask> {
    return this.http.get(`/license-monitoring/task/${id}`);
  }

  @ShowIfHttpError('An error has occured.')
  getMonitoringReport(key: string): Observable<MonitoringResultEdgeDocument> {
    return this.psvService.get(`/v1/license-requirement-monitoring/monitoring-report/${key}`);
  }

  @ShowIfHttpError('An error has occured.')
  getIngestionInfo(key: string): Observable<GetIngestionInfoResponse> {
    return this.psvService.get(`/v1/license-requirement-monitoring/monitoring-report/ingestion-info/${key}`);
  }

  @ShowIfHttpError('An error has occured.')
  getIngestionInfoSourceDetails(key: string): Observable<GetIngestionInfoSourceDetailsResponse> {
    return this.psvService.get(
      `/v1/license-requirement-monitoring/monitoring-report/ingestion-info/${key}/source-details`,
    );
  }

  @ShowIfHttpError('An error has occured.')
  saveReport(key: string, data: LicenseMonitoringReportProgressPayload) {
    return this.psvService.post(`/v1/license-requirement-monitoring/monitoring-report/progress/${key}`, data);
  }

  @ShowIfHttpError('An error has occured.')
  editReport(key: string) {
    return this.psvService.post(`/v1/license-requirement-monitoring/monitoring-report/reopen/${key}`, {});
  }

  getPreviewReportUrl(key: string): string {
    return this.psvService.getFullUrl(`/v1/license-requirement-monitoring/monitoring-report/preview-report/${key}.pdf`);
  }
}
