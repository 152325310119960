import { Component } from '@angular/core';
import { BaseErrorPageConfig } from '../base-error-page/base-error-page.component';

@Component({
  selector: 'cm-forbidden',
  templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent {
  config: BaseErrorPageConfig = {
    header: '403',
    body: "You don't have permission to access this page.",
  };
}
