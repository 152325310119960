import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { profilesReducer } from './+state/profiles-state.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProfilesEffects } from './+state/profiles-state.effects';

@NgModule({
  imports: [StoreModule.forFeature('profiles', profilesReducer), EffectsModule.forFeature([ProfilesEffects])],
  providers: [],
})
export class ProfilesStateModule {}
