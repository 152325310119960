import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class GdprConfirmationService implements OnDestroy {
  subscriptions = {};
  onDestroy$ = new Subject<void>();

  constructor() {
    fromEvent(window, 'message')
      .pipe(
        filter((event: any) => !!event.data && typeof event.data === 'string'),
        map(event => this.parseMessage(event)),
        filter(event => !!event),
        takeUntil(this.onDestroy$),
      )
      .subscribe(event => this.notify(event));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  notify({ event, message }) {
    const subscriptions = this.subscriptions[message.type];
    if (subscriptions) {
      subscriptions.forEach(callback => callback(event, message));
    }
  }

  sendMessage(contentWindow, message, url) {
    const stringifiedMessage = JSON.stringify(message);
    contentWindow.postMessage(stringifiedMessage, url);
  }

  subscribe(type, callback) {
    if (!this.subscriptions[type]) {
      this.subscriptions[type] = [];
    }

    this.subscriptions[type] = [...this.subscriptions[type], callback];

    return () => {
      this.subscriptions[type] = this.subscriptions[type].filter(
        subscriptionCallback => subscriptionCallback !== callback,
      );
    };
  }

  private parseMessage(event) {
    try {
      const message = JSON.parse(event.data);
      return { event, message };
    } catch (e) {
      console.log(`Cannot parse message: ${e.message}`, event);
      return null;
    }
  }
}
