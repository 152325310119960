import { ORGANIZATION_TYPE } from 'constants/constants';
import _get from 'lodash-es/get';

export enum OrganizationsLabelType {
  // location
  LOCATION_REGION = 1,
  LOCATION_OFFICE = 2,
  // admin title
  ADMIN_TITLE_GLOBAL_EXECUTIVE = 3,
  ADMIN_TITLE_COUNTRY_MANAGER = 4,
  ADMIN_TITLE_REGIONAL_MANAGER = 5,
  ADMIN_TITLE_OFFICE_MANAGER = 6,
  // module admin
  MODULE_CREDENTIALING_ADMIN = 7,
  MODULE_TESTING_ADMIN = 8,
  MODULE_COMPLIANCE_ADMIN = 9,
  // module prof
  MODULE_CREDENTIALING_PROF = 10,
  MODULE_TESTING_PROF = 11,
  MODULE_COMPLIANCE_PROF = 12,
  // professional types
  PROFILE_TYPE_FACILITY = 13,
  PROFILE_TYPE_INDIVIDUAL = 14,
  // monitoring module
  MODULE_MONITORING_ADMIN = 15,
  // digital wallet
  DIGITAL_WALLET_CERTIFICATES = 16,
  DIGITAL_WALLET_EDUCATION = 17,
  DIGITAL_WALLET_GENERAL = 18,
  DIGITAL_WALLET_RECEIPTS = 19,
  DIGITAL_WALLET_RENEWALS = 20,
  DIGITAL_WALLET_TESTING = 21,
  DIGITAL_WALLET_VERIFICATIONS = 22,

  // WARNING! THE VALUES BELOW AREN'T IN THE DATABASE
  MODULE_CERTEMY_TASKS = -1,
  // general - Presently only on FE side. Always put at the bottom
  GENERAL_PROFESSIONAL = -2,
  GENERAL_ORG_ADMIN = -3,
}

export enum ORGANIZATION_MENU_GROUP {
  CERTEMY_TASKS,
  CREDENTIALING,
  TESTING,
  COMPLIANCE,
  SETTINGS,
  USER_GUIDE,
  MONITORING,
}

export enum ORGANIZATION_LABELS_MODULE {
  DIGITAL_WALLET = 7,
}

export const DEFAULT_PROFESSIONAL_MENU_MODULE_LABELS = {
  [OrganizationsLabelType.MODULE_CREDENTIALING_PROF]: 'My Credentials',
  [OrganizationsLabelType.MODULE_TESTING_PROF]: 'My Testing',
};

export const ORGANIZATION_MENU_GROUP_NAME: GlobalOrganizationsLabel = {
  [ORGANIZATION_MENU_GROUP.CERTEMY_TASKS]: 'Certemy Tasks',
  [ORGANIZATION_MENU_GROUP.CREDENTIALING]: 'Credentialing',
  [ORGANIZATION_MENU_GROUP.TESTING]: 'Testing',
  [ORGANIZATION_MENU_GROUP.COMPLIANCE]: 'Compliance',
  [ORGANIZATION_MENU_GROUP.SETTINGS]: 'Settings',
  [ORGANIZATION_MENU_GROUP.USER_GUIDE]: 'User Guide',
  [ORGANIZATION_MENU_GROUP.MONITORING]: 'Monitoring',
};

export const DEFAULT_LABEL_SETTINGS: GlobalOrganizationsLabel = {
  [OrganizationsLabelType.LOCATION_REGION]: 'Region',
  [OrganizationsLabelType.LOCATION_OFFICE]: 'Office',

  [OrganizationsLabelType.ADMIN_TITLE_GLOBAL_EXECUTIVE]: 'Executive',
  [OrganizationsLabelType.ADMIN_TITLE_COUNTRY_MANAGER]: 'Country manager',
  [OrganizationsLabelType.ADMIN_TITLE_REGIONAL_MANAGER]: 'Regional manager',
  [OrganizationsLabelType.ADMIN_TITLE_OFFICE_MANAGER]: 'Office manager',

  [OrganizationsLabelType.MODULE_CREDENTIALING_ADMIN]:
    ORGANIZATION_MENU_GROUP_NAME[ORGANIZATION_MENU_GROUP.CREDENTIALING],
  [OrganizationsLabelType.MODULE_TESTING_ADMIN]: ORGANIZATION_MENU_GROUP_NAME[ORGANIZATION_MENU_GROUP.TESTING],
  [OrganizationsLabelType.MODULE_COMPLIANCE_ADMIN]: ORGANIZATION_MENU_GROUP_NAME[ORGANIZATION_MENU_GROUP.COMPLIANCE],
  [OrganizationsLabelType.MODULE_MONITORING_ADMIN]: ORGANIZATION_MENU_GROUP_NAME[ORGANIZATION_MENU_GROUP.MONITORING],

  [OrganizationsLabelType.MODULE_CREDENTIALING_PROF]:
    DEFAULT_PROFESSIONAL_MENU_MODULE_LABELS[OrganizationsLabelType.MODULE_CREDENTIALING_PROF],
  [OrganizationsLabelType.MODULE_TESTING_PROF]:
    DEFAULT_PROFESSIONAL_MENU_MODULE_LABELS[OrganizationsLabelType.MODULE_TESTING_PROF],
  [OrganizationsLabelType.MODULE_COMPLIANCE_PROF]:
    DEFAULT_PROFESSIONAL_MENU_MODULE_LABELS[OrganizationsLabelType.MODULE_CREDENTIALING_PROF],

  [OrganizationsLabelType.MODULE_CERTEMY_TASKS]: ORGANIZATION_MENU_GROUP_NAME[ORGANIZATION_MENU_GROUP.CERTEMY_TASKS],

  [OrganizationsLabelType.GENERAL_PROFESSIONAL]: 'Professional',
  [OrganizationsLabelType.GENERAL_ORG_ADMIN]: 'Organization Admin',

  [OrganizationsLabelType.PROFILE_TYPE_FACILITY]: 'Facility',
  [OrganizationsLabelType.PROFILE_TYPE_INDIVIDUAL]: 'Professional',

  [OrganizationsLabelType.DIGITAL_WALLET_CERTIFICATES]: 'Certificates',
  [OrganizationsLabelType.DIGITAL_WALLET_EDUCATION]: 'Education',
  [OrganizationsLabelType.DIGITAL_WALLET_GENERAL]: 'General',
  [OrganizationsLabelType.DIGITAL_WALLET_RECEIPTS]: 'Receipts',
  [OrganizationsLabelType.DIGITAL_WALLET_RENEWALS]: 'Renewals',
  [OrganizationsLabelType.DIGITAL_WALLET_TESTING]: 'Testing',
  [OrganizationsLabelType.DIGITAL_WALLET_VERIFICATIONS]: 'Verifications',
};

const TEMPORARY_DEFAULT_LABEL_SETTINGS: TemporaryGlobalOrganizationsLabel = {
  [OrganizationsLabelType.GENERAL_PROFESSIONAL]: {
    [ORGANIZATION_TYPE.BOARD]: 'Professional',
    [ORGANIZATION_TYPE.EMPLOYER]: 'Employee',
  },
  [OrganizationsLabelType.GENERAL_ORG_ADMIN]: {
    [ORGANIZATION_TYPE.BOARD]: 'Organization Admin',
    [ORGANIZATION_TYPE.EMPLOYER]: 'Employer',
  },
};

// todo remove after adding General labels
export function getTemporaryDefaultLabelSettings(
  organizationTypeId: ORGANIZATION_TYPE = ORGANIZATION_TYPE.BOARD,
): GlobalOrganizationsLabel {
  return Object.keys(DEFAULT_LABEL_SETTINGS).reduce((result, key) => {
    const value = _get(
      TEMPORARY_DEFAULT_LABEL_SETTINGS,
      `[${key}][${organizationTypeId}]`,
      DEFAULT_LABEL_SETTINGS[key],
    );
    return {
      ...result,
      [key]: value,
    };
  }, {});
}

interface GlobalOrganizationsLabelByType {
  [key: string]: string;
}

export interface GlobalOrganizationsLabel {
  [key: number]: string;
}

export interface TemporaryGlobalOrganizationsLabel {
  [key: number]: GlobalOrganizationsLabelByType;
}

export interface LabelSettingRow {
  defaultLabel: string;
  labelTypeId: OrganizationsLabelType;
  moduleDescription: string;
  moduleId: number;
  moduleName: string;
  organizationLabel: string;
  systemLabel: string;
}

export interface LabelSettingResponse {
  rows: LabelSettingRow[];
}

export function replaceOrganizationLabel(text, labels: GlobalOrganizationsLabel) {
  return (text || '')
    .replace(/{region}/g, labels[OrganizationsLabelType.LOCATION_REGION])
    .replace(/{office}/g, labels[OrganizationsLabelType.LOCATION_OFFICE])
    .replace(/{country_manager}/g, labels[OrganizationsLabelType.ADMIN_TITLE_COUNTRY_MANAGER])
    .replace(/{regional_manager}/g, labels[OrganizationsLabelType.ADMIN_TITLE_REGIONAL_MANAGER])
    .replace(/{executive}/g, labels[OrganizationsLabelType.ADMIN_TITLE_GLOBAL_EXECUTIVE])
    .replace(/{professional}/g, labels[OrganizationsLabelType.GENERAL_PROFESSIONAL])
    .replace(/{orgAdmin}/g, labels[OrganizationsLabelType.GENERAL_ORG_ADMIN])
    .replace(/{employee}/g, labels[OrganizationsLabelType.PROFILE_TYPE_INDIVIDUAL])
    .replace(/Certificates/g, labels[OrganizationsLabelType.DIGITAL_WALLET_CERTIFICATES])
    .replace(/Education/g, labels[OrganizationsLabelType.DIGITAL_WALLET_EDUCATION])
    .replace(/General/g, labels[OrganizationsLabelType.DIGITAL_WALLET_GENERAL])
    .replace(/Receipts/g, labels[OrganizationsLabelType.DIGITAL_WALLET_RECEIPTS])
    .replace(/Renewals/g, labels[OrganizationsLabelType.DIGITAL_WALLET_RENEWALS])
    .replace(/Testing/g, labels[OrganizationsLabelType.DIGITAL_WALLET_TESTING])
    .replace(/Verifications/g, labels[OrganizationsLabelType.DIGITAL_WALLET_VERIFICATIONS]);
}
