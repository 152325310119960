import { RouterModule } from '@angular/router';
import { authGuard } from 'directives/auth-guard';
import { roleGuard } from 'directives/role-guard';
import { NotFoundComponent } from 'pages/error-pages/not-found/not-found.component';
import { ForbiddenComponent } from 'pages/error-pages/forbidden/forbidden.component';
import { AccessRestrictedComponent } from 'pages/error-pages/access-restricted/access-restricted.component';
import { updateProfileGuard } from 'directives/update-profile.guard';
import { credentialingVerificationListPolicyPermissionsGuard } from 'directives/credentialing-verification-list-policy-permission.guard';

const routerModuleComponents: any[] = [
  { path: '', redirectTo: 'entry/login', pathMatch: 'full' },
  {
    path: 'external-login',
    loadChildren: () =>
      import('./pages/external-id-provider-form/external-id-provider-form.module').then(
        (m) => m.ExternalIdProviderFormModule,
      ),
  },
  {
    path: 'organization',
    loadChildren: () => import('./pages/organization/organization.module').then((m) => m.OrganizationModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'imposer',
    loadChildren: () => import('./pages/imposer/imposer.module').then((m) => m.ImposerModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'employer',
    loadChildren: () => import('./pages/employer/employer.module').then((m) => m.EmployerModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: 'entry',
    loadChildren: () => import('./pages/entry/entry.module').then((m) => m.EntryModule),
  },
  {
    path: 'professional',
    loadChildren: () => import('./pages/professional/professional.module').then((m) => m.ProfessionalModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./pages/supervisor/supervisor.module').then((m) => m.SupervisorModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'verifier',
    loadChildren: () => import('./pages/verifier/verifier.module').then((m) => m.VerifierModule),
    canActivate: [authGuard, roleGuard, credentialingVerificationListPolicyPermissionsGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'partner',
    loadChildren: () => import('./pages/partner/partner.module').then((m) => m.PartnerModule),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'payment-checkout',
    loadChildren: () => import('pages/payment-resolvers/payment-checkout.module').then((m) => m.PaymentCheckoutModule),
    canActivate: [authGuard],
  },
  // {
  //   path: 'dev',
  //   loadChildren: () => import('./pages/dev/dev.module').then((m) => m.DevModule),
  // },
  {
    path: 'public-registry',
    loadChildren: () => import('./pages/public-registry/public-registry.module').then((m) => m.PublicRegistryModule),
  },
  {
    path: 'subscription-details',
    loadChildren: () =>
      import('features/subscriptions/subscription-details/subscription-details.module').then(
        (m) => m.SubscriptionDetailsModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'switch-profile',
    loadChildren: () => import('./pages/switch-profile/switch-profile.module').then((m) => m.SwitchProfileModule),
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then((m) => m.DownloadModule),
  },
  {
    path: 'access-restricted',
    component: AccessRestrictedComponent,
  },
  {
    path: 'report-admin',
    loadChildren: () =>
      import('./pages/license-verification/report-admin/report-admin.module').then((m) => m.ReportAdminModule),
    canActivate: [authGuard],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [updateProfileGuard],
  },
  {
    path: '',
    loadChildren: () => import('./pages/static-pages/static-pages.module').then((m) => m.StaticPagesModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
const routerModalOutlet: any[] = [
  {
    path: 'candidate-card',
    loadChildren: () =>
      import(
        'features/testing-dashboards/qualification-dashboard/modal/wrapper/qualification-entry-modal-wrapper.module'
      ).then((m) => m.QualificationEntryModalWrapperModule),
    outlet: 'modal',
  },
];
export const routing = RouterModule.forRoot([...routerModuleComponents, ...routerModalOutlet], {
  paramsInheritanceStrategy: 'always',
});
