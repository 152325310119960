export enum REPORT_TYPE {
  TRANSCRIPT = 4,
  AUDIT_LIST = 5,
  AUDIT_DETAILS = 6,
  VOUCHERS_LIST = 7,
  TESTING_WORKFLOW_QUALIFICATION_DASHBOARD = 8,
  TESTING_WORKFLOW_CANDIDATES_DASHBOARD = 9,
  TESTING_WORKFLOW_SCORE_SUMMARY = 10,
  TESTING_WORKFLOW_SCORE_DETAIL = 11,
  COMPLIANCE_DETAILS = 13,
  COMPLIANCE_CE_DETAILS = 14,
  PUBLIC_REGISTRY = 15,
  REGISTRY_DEPENDENCY_DEPENDENTS = 16,
  REGISTRY_DEPENDENCY_INDEPENDENTS = 17,
  ONBOARDING_DETAILS = 18,
  PSV_DETAILS = 19,
}

export enum REPORT_FORMAT {
  XLSX,
  PDF,
  HTML,
  JSON = 3,
}

export enum GENERATE_REPORT_RESPONSE_TYPE {
  ARRAY_BUFFER = 'arraybuffer',
  TEXT = 'text',
}

export const PUBLIC_REPORT_PARAM_NAME = '_publicReport';
