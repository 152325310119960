import { UserProfile } from 'services/user/user.interface';
export { UserProfile } from 'services/user/user.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResponseStatus } from '@certemy/common';
import { GlobalOrganizationsLabel } from 'features/global-organizations-setting-state/global-organizations-setting.constant';

export class GlobalOrganizationSettingsState {
  labelsSettings: {
    labels: GlobalOrganizationsLabel;
    status: ResponseStatus;
  } = {
    labels: {},
    status: ResponseStatus.INITIAL,
  };
}

const settingState = createFeatureSelector<GlobalOrganizationSettingsState>('globalOrganizationSettings');

const labelsSettings = createSelector(
  settingState,
  state => state.labelsSettings,
);
const labels = createSelector(
  labelsSettings,
  settings => settings.labels,
);

export const Selectors = {
  settingState,
  labelsSettings,
  labels,
};
