import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'services/http/http.service';
import { ShowIfHttpError } from '@certemy/common';
import { DomainService } from 'services/domain/domain.service';
import { TokenStorageService } from 'services/token/token-storage.service';
import { map } from 'rxjs/operators';
import _isUndefined from 'lodash-es/isUndefined';
import _merge from 'lodash-es/merge';
import _size from 'lodash-es/size';
import {
  DEFAULT_LABEL_SETTINGS,
  getTemporaryDefaultLabelSettings,
  GlobalOrganizationsLabel,
  LabelSettingResponse,
  LabelSettingRow,
  ORGANIZATION_MENU_GROUP,
  ORGANIZATION_MENU_GROUP_NAME,
  OrganizationsLabelType,
  replaceOrganizationLabel,
} from 'features/global-organizations-setting-state/global-organizations-setting.constant';
import { ORGANIZATION_TYPE } from 'constants/constants';

@Injectable()
export class GlobalOrganizationsSettingService {
  constructor(
    private http: HttpService,
    private domainService: DomainService,
    private tokenStorageService: TokenStorageService,
  ) {}

  private _names: GlobalOrganizationsLabel = ORGANIZATION_MENU_GROUP_NAME;
  private _labelSettings: GlobalOrganizationsLabel = DEFAULT_LABEL_SETTINGS;

  get names() {
    return this._names;
  }

  setNamesFromArr(labelSettings: GlobalOrganizationsLabel) {
    const names = this.names;
    const newValues = Object.keys(labelSettings).reduce((obj, key) => {
      switch (Number(key) as OrganizationsLabelType) {
        case OrganizationsLabelType.MODULE_CREDENTIALING_ADMIN:
          obj[ORGANIZATION_MENU_GROUP.CREDENTIALING] =
            labelSettings[key] || names[ORGANIZATION_MENU_GROUP.CREDENTIALING];
          break;
        case OrganizationsLabelType.MODULE_TESTING_ADMIN:
          obj[ORGANIZATION_MENU_GROUP.TESTING] = labelSettings[key] || names[ORGANIZATION_MENU_GROUP.TESTING];
          break;
        case OrganizationsLabelType.MODULE_COMPLIANCE_ADMIN:
          obj[ORGANIZATION_MENU_GROUP.COMPLIANCE] = labelSettings[key] || names[ORGANIZATION_MENU_GROUP.COMPLIANCE];
          break;
        case OrganizationsLabelType.MODULE_CERTEMY_TASKS:
          obj[ORGANIZATION_MENU_GROUP.CERTEMY_TASKS] =
            labelSettings[key] || names[ORGANIZATION_MENU_GROUP.CERTEMY_TASKS];
          break;
        case OrganizationsLabelType.MODULE_MONITORING_ADMIN:
          obj[ORGANIZATION_MENU_GROUP.MONITORING] = labelSettings[key] || names[ORGANIZATION_MENU_GROUP.MONITORING];
          break;
      }
      return obj;
    }, {});
    this._names = {
      ...names,
      ...newValues,
    };
  }

  setLabelsSettings(labelSettings: GlobalOrganizationsLabel) {
    this._labelSettings = {
      ...DEFAULT_LABEL_SETTINGS,
      ...labelSettings,
    };
  }

  @ShowIfHttpError('An error occurred while getting labels settings')
  getLabelsSettings(): Observable<LabelSettingRow[]> {
    return this.http
      .get(
        `/organization-label-settings?domainName=${this.domainService.getDomainNameFromUrl() ||
          this.domainService.getDomainNameFromToken(this.tokenStorageService.userToken)}`,
      )
      .pipe(map((response: LabelSettingResponse) => response.rows));
  }

  getLabelSettingsMapFromArr(
    settings: LabelSettingRow[],
    organizationTypeId: ORGANIZATION_TYPE = ORGANIZATION_TYPE.BOARD,
  ): GlobalOrganizationsLabel {
    const defaultLabelSettings = getTemporaryDefaultLabelSettings(organizationTypeId);
    const dataFromDB = settings.reduce((result, item) => {
      const { labelTypeId, organizationLabel, defaultLabel } = item;
      if (!_isUndefined(labelTypeId)) {
        result[labelTypeId] = organizationLabel || defaultLabel || defaultLabelSettings[labelTypeId] || '';
      }
      return result;
    }, {});
    return _merge({}, defaultLabelSettings, dataFromDB);
  }

  replaceOptionsWithOrganizationLabel(options: any[]): any[] {
    if (_size(options)) {
      return options.map(option => {
        const result = {
          ...option,
        };
        ['name', 'templateName', 'folderName'].forEach(key => {
          if (option.hasOwnProperty(key)) {
            result[key] = this.replaceOrganizationLabel(option[key]);
          }
        });
        return result;
      });
    }
    return options;
  }

  replaceOrganizationLabel(text) {
    return replaceOrganizationLabel(text, this._labelSettings);
  }
}
