import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Actions as BrandingActions } from './platform-branding.actions';
import { PlatformBrandingService } from '../platform-branding.service';
import { PlatformBrandingSettings } from '../platform-branding.interfaces';
import {
  DEFAULT_CSS_VARIABLES_OBJECT_VALUE,
  getAdjustedPlatformSettings,
  updateCssVariables,
  updateCssVariablesForPlatformSettings,
} from '../platform-branding.helpers';
import { TokenStorageService } from 'services/token/token-storage.service';
import { DomainService } from 'services/domain/domain.service';

@Injectable()
export class PlatformBrandingEffects {
  getSettings = createEffect(() => this.actions$.pipe(
    ofType(BrandingActions.GetBrandingSettings.type),
    tap(() => this.domainService.changeDomainIfDomainNameChanged()),
    switchMap(() =>
      this.platformBrandingService.getPlatformBrandingSettings().pipe(
        map((settings: PlatformBrandingSettings) => {
          settings = getAdjustedPlatformSettings(settings);
          updateCssVariablesForPlatformSettings(settings);
          return new BrandingActions.GetBrandingSettingsSuccess(settings);
        }),
        catchError(() => {
          updateCssVariables(DEFAULT_CSS_VARIABLES_OBJECT_VALUE);
          return of(new BrandingActions.GetBrandingSettingsError());
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private platformBrandingService: PlatformBrandingService,
    private tokenStorage: TokenStorageService,
    private domainService: DomainService,
  ) {}
}
