import { ResponseStatus } from '@certemy/common';
import { GlobalOrganizationSettingsState } from 'features/global-organizations-setting-state/global-organizations-setting.state';
import { Actions, ActionType } from 'features/global-organizations-setting-state/global-organizations-setting.actions';
import { DEFAULT_LABEL_SETTINGS } from 'features/global-organizations-setting-state/global-organizations-setting.constant';

export function globalOrganizationSettingsReducer(state = new GlobalOrganizationSettingsState(), action: ActionType) {
  switch (action.type) {
    case Actions.SetLabelsSettings.type:
      return {
        ...state,
        labelsSettings: {
          ...state.labelsSettings,
          status: ResponseStatus.PENDING,
        },
      };
    case Actions.SetLabelsSettingsSuccess.type:
      return {
        ...state,
        labelsSettings: {
          labels: action.payload,
          status: ResponseStatus.COMPLETE,
        },
      };
    case Actions.Reset.type:
      return {
        ...state,
        labelsSettings: {
          labels: DEFAULT_LABEL_SETTINGS,
          status: ResponseStatus.INITIAL,
        },
      };
    default:
      return state;
  }
}
