import cssVars from 'css-vars-ponyfill';
import {
  CSS_VARIABLES_NAMES,
  DEFAULT_COLOR_SETTINGS,
  DEFAULT_FONT_SETTINGS,
  DEFAULT_LOGO_CONFIG,
} from './platform-branding.constants';
import { ColorSettings, FontSettings, PlatformBrandingSettings } from './platform-branding.interfaces';

export function getColorDifference(colorObject, darkColorObject) {
  return Object.keys(colorObject).reduce((colorMap, key) => {
    return {
      ...colorMap,
      [key]: colorObject[key] - darkColorObject[key],
    };
  }, {});
}

export function updateCssVariables(variablesObject: { [key: string]: string }) {
  cssVars({
    variables: variablesObject,
    watch: true,
  });
}

export function getCssColorsObjectToUpdate(colorSettings: ColorSettings): { [key: string]: string } {
  return {
    [CSS_VARIABLES_NAMES.BASE_COLOR]: colorSettings.baseColor,
    [CSS_VARIABLES_NAMES.BASE_HOVER_COLOR]: colorSettings.hoverBaseColor,
    [CSS_VARIABLES_NAMES.BASE_ACTIVE_COLOR]: colorSettings.activeBaseColor,
    [CSS_VARIABLES_NAMES.MENU_BASE_COLOR]: colorSettings.menuBaseColor,
    [CSS_VARIABLES_NAMES.MENU_BUTTON_COLOR]: colorSettings.menuButtonColor,
    [CSS_VARIABLES_NAMES.MENU_BUTTON_HOVER_COLOR]: colorSettings.menuButtonHoverColor,
  };
}

export function getCssFontObjectToUpdate(colorSettings: FontSettings): { [key: string]: string } {
  return {
    [CSS_VARIABLES_NAMES.BASE_FONT]: colorSettings.baseFont,
    [CSS_VARIABLES_NAMES.HEADING_FONT]: colorSettings.headingFont,
  };
}

export const DEFAULT_CSS_VARIABLES_OBJECT_VALUE = getCssVariablesObject({
  font: DEFAULT_FONT_SETTINGS,
  color: DEFAULT_COLOR_SETTINGS,
});

export function getCssVariablesObject(styleSettings: {
  color: ColorSettings;
  font: FontSettings;
}): { [key: string]: string } {
  return {
    ...getCssColorsObjectToUpdate(styleSettings.color),
    ...getCssFontObjectToUpdate(styleSettings.font),
  };
}

export function updateCssVariablesForPlatformSettings(settings: PlatformBrandingSettings) {
  const { baseFont, headingFont } = settings;
  updateCssVariables(
    getCssVariablesObject({
      font: { baseFont, headingFont },
      color: settings.colorSettings,
    }),
  );
}

export function getAdjustedPlatformSettings(settings: PlatformBrandingSettings): PlatformBrandingSettings {
  if (!settings.avatar) {
    return {
      ...settings,
      avatar: DEFAULT_LOGO_CONFIG.imageConfig,
      logoFormType: DEFAULT_LOGO_CONFIG.logoFormType,
    };
  }

  return settings;
}
