import { SmartTable } from '@certemy/ui-components';
import { AUDIT_PROFILE_STATUS_NAME } from 'features/audit/audit-transcript-modal/audit-transcript-modal.interface';

export const defaultAuditDashboard = {
  diplomatsTraced: 0,
  completedCycle: 0,
  diplomatsAudited: 0,
  completedAudits: 0,
  auditRemaining: 0,
};

export class AuditDetailsFilters {
  page = 1;
  search = '';
  orderId = 'firstName';
  orderType = SmartTable.Order.DESC;
  order: SmartTable.OrderedHeader;
}

export const auditDetailsHeaders: SmartTable.Header[] = [
  { name: 'First Name', value: 'firstName', sortable: true },
  { name: 'Last Name', value: 'lastName', sortable: true },
  { name: 'Required Units', value: 'requiredUnits' },
  { name: 'Completed Units', value: 'completedUnits' },
  {
    name: 'Status',
    value: (row: SmartTable.Row) => AUDIT_PROFILE_STATUS_NAME[row.statusId],
    fieldType: 'statusId',
    sortable: true,
    type: SmartTable.ColumnTypeId.CUSTOM,
  } as any,
];
