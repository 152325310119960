import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import {
  IPermissions,
  PolicyObj,
  PolicyPermissionsService,
} from 'services/policy-permission/policy-permissions.service';
import { map } from 'rxjs/operators';
import _isArray from 'lodash-es/isArray';
import { of } from 'rxjs';

@Injectable()
export class PolicyPermissionsGuardService  {
  constructor(private router: Router, private policyPermissionsService: PolicyPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const policyObj = route.data.policyObj;
    const policyAction = route.data.policyAction;

    return this.policyPermissionsService.hasPermissions(policyObj, policyAction).pipe(
      map(permission => {
        if (permission[policyAction]) {
          return true;
        }

        this.router.navigate(['/access-restricted']);
        return false;
      }),
    );
  }
}

export const policyPermissionsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return inject(PolicyPermissionsGuardService).canActivate(route);
};

@Injectable()
export class PolicyMultiplePermissionsGuardService  {
  constructor(private router: Router, private policyPermissionsService: PolicyPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const policyArr = route.data.policyArr as PolicyObj[];
    if (!_isArray(policyArr)) {
      return of(true);
    }

    return this.policyPermissionsService.hasMultiplePermissions(policyArr).pipe(
      map((permission: IPermissions) => {
        if (Object.values(permission).every(val => val)) {
          return true;
        }

        this.router.navigate(['/access-restricted']);
        return false;
      }),
    );
  }
}

export const policyMultiplePermissionsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return inject(PolicyMultiplePermissionsGuardService).canActivate(route);
};
