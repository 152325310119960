import { Component, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { ComputedFrom } from '@certemy/common';
import { UserProfile } from 'services/user/user.interface';

@Component({
  selector: 'cm-active-profile',
  templateUrl: 'active-profile.component.html',
  styleUrls: ['active-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveProfileComponent {
  @Input() profile: UserProfile;
  @ComputedFrom('profile')
  get isLoggedAsProfile() {
    return !!this.profile.origin;
  }
  showProfileCallout = false;

  @HostListener('mouseover')
  onProfileMouseOver() {
    this.showProfileCallout = true;
  }

  @HostListener('mouseleave')
  onMouseLeaveEvent() {
    this.showProfileCallout = false;
  }

  closeProfileCalloutHandler() {
    this.showProfileCallout = false;
  }
}
