import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { loginProviderReducer } from 'services/login-provider/+state/login-provider.reducer';
import { LoginProviderEffects } from 'services/login-provider/+state/login-provider.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    StoreModule.forFeature('loginProvider', loginProviderReducer),
    EffectsModule.forFeature([LoginProviderEffects]),
  ],
  providers: [],
})
export class LoginProviderModule {}
