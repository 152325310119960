import { UserProfile } from 'services/user/user.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResponseStatus } from '@certemy/common';

export class ProfilesState {
  currentProfile: UserProfile;
  availableProfiles: UserProfile[] = [];
  status: ResponseStatus = ResponseStatus.INITIAL;
}

const profilesState = createFeatureSelector<ProfilesState>('profiles');
const currentProfile = createSelector(
  profilesState,
  state => state.currentProfile,
);
const currentRoleId = createSelector(
  currentProfile,
  profile => profile.role_id,
);
const currentOrganizationRoleId = createSelector(
  currentProfile,
  profile => profile.organizationRoleId,
);
const currentEmail = createSelector(
  currentProfile,
  profile => profile.email,
);
const isLoggedAsProfile = createSelector(
  currentProfile,
  profile => profile.origin,
);
const features = createSelector(
  profilesState,
  state => state.currentProfile.features,
);
const paymentSymbol = createSelector(
  currentProfile,
  state => state.symbol,
);
const paymentCurrency = createSelector(
  currentProfile,
  state => state.currency,
);
const profileLocations = createSelector(
  currentProfile,
  state => state.locations,
);
const organizationTypeId = createSelector(
  currentProfile,
  state => state.organization_type_id,
);

export const Selectors = {
  profilesState,
  currentProfile,
  currentRoleId,
  currentOrganizationRoleId,
  isLoggedAsProfile,
  features,
  paymentSymbol,
  paymentCurrency,
  currentEmail,
  profileLocations,
  organizationTypeId,
};
