import { Action } from '@ngrx/store';
import { type } from 'services/actions-cache/actions-cache.service';
import { LoginProviderResponse } from 'services/login-provider/login-provider.interface';

export namespace Actions {
  export class GetLoginProviders implements Action {
    static readonly type = type<'[LOGIN_PROVIDER] GetLoginProviders'>('[LOGIN_PROVIDER] GetLoginProviders');
    readonly type = GetLoginProviders.type;
  }

  export class GetLoginProvidersSuccess implements Action {
    static readonly type = type<'[LOGIN_PROVIDER] GetLoginProvidersSuccess'>(
      '[LOGIN_PROVIDER] GetLoginProvidersSuccess',
    );
    readonly type = GetLoginProvidersSuccess.type;

    constructor(public payload: LoginProviderResponse) {}
  }

  export class GetLoginProvidersError implements Action {
    static readonly type = type<'[LOGIN_PROVIDER] GetLoginProvidersError'>('[LOGIN_PROVIDER] GetLoginProvidersError');
    readonly type = GetLoginProvidersError.type;
  }

  export class Reset implements Action {
    static readonly type = type<'[LOGIN_PROVIDER] Reset'>('[LOGIN_PROVIDER] Reset');
    readonly type = Reset.type;
  }
}

export type ActionType =
  | Actions.GetLoginProviders
  | Actions.GetLoginProvidersSuccess
  | Actions.GetLoginProvidersError
  | Actions.Reset;
