import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { PolicyObj, PolicyPermissionsService } from 'services/policy-permission/policy-permissions.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class CredentialingVerificationListPolicyPermissionsGuardService  {
  constructor(private router: Router, private policyPermissionsService: PolicyPermissionsService) {}

  canActivate() {
    const multiplePermissions: Record<string, PolicyObj> = {
      credentialingVerificationList: {
        name: 'credentialingVerificationList',
        url: 'credentialing/verification_list/all',
        action: 'access',
      },
      credentialingPrimaryVerification: {
        name: 'credentialingPrimaryVerification',
        url: 'credentialing/primary_verification/all',
        action: 'access',
      },
      credentialingSecondaryVerification: {
        name: 'credentialingSecondaryVerification',
        url: 'credentialing/secondary_verification/all',
        action: 'access',
      },
    };

    return this.policyPermissionsService
      .hasMultiplePermissions(Object.values(multiplePermissions))
      .pipe(take(1))
      .pipe(
        map(permissions => {
          if (
            permissions.credentialingVerificationList &&
            (permissions.credentialingPrimaryVerification || permissions.credentialingSecondaryVerification)
          ) {
            return true;
          }

          this.router.navigate(['/access-restricted']);
          return false;
        }),
      );
  }
}

export const credentialingVerificationListPolicyPermissionsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return inject(CredentialingVerificationListPolicyPermissionsGuardService).canActivate();
};
