import { catchError, map, switchMap, tap, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { HttpService } from 'services/http/http.service';
import { MixpanelService } from 'services/mixpanel/mixpanel.service';
import { LoggerService } from '@certemy/common';
import { NotificationsService } from 'services/notifications/notifications.service';
import { DomainService } from 'services/domain/domain.service';
import { Actions as ProfileActions } from 'features/profiles-state/+state/profiles-state.actions';
import { Store } from '@ngrx/store';
import { LoginStatusService } from 'services/token/login-status.service';
import { LOGIN_STATUSES } from 'constants/auth.constants';
import { CasbinService } from 'services/casbin/casbin.service';
import { SIGN_IN_TYPES } from 'pages/entry/entry.helpers';

@Injectable()
export class TokenService {
  private _renewPath = '/token/renew';

  constructor(
    private http: HttpService,
    private tokenStorageService: TokenStorageService,
    private mixpanelService: MixpanelService,
    private loggerService: LoggerService,
    private notificationsService: NotificationsService,
    private domainService: DomainService,
    private store: Store<any>,
    private loginStatusService: LoginStatusService,
    private casbinService: CasbinService,
  ) {}

  signUpUser(url: string, body: any) {
    return this.http.post(url, body);
  }

  resetUserPassword(email) {
    return this.http.post('/auth/password/request', email);
  }

  updateUserPassword(params) {
    return this.http.post('/auth/password/reset', params);
  }

  renewToken(): Observable<{ token: string | null }> {
    return this.http.get(this._renewPath).pipe(
      map(({ code_name, token }) => {
        if (code_name.indexOf('SUCCESS') === -1) {
          return { token: null };
        }
        this.tokenStorageService.replaceUserToken(token);

        return { token };
      }),
    );
  }

  userLogout() {
    this.casbinService.cachePoliciesFromSession().subscribe();
    this.deleteCookie().subscribe();
    this.mixpanelService.logout();
    this.loggerService.setUserContext();
    this.clearTokens();
    this.casbinService.clearPolicies();
    this.notificationsService.clearViewedNotifications();
  }

  getLoginType(): Observable<any> {
    return this.http.get(`/auth/login-type/${this.domainService.getSubdomainForLogin()}`).pipe(
      map((result) => {
        return result ? result['loginType'] : null;
      }),
    );
  }

  getUserLogin(body: { username: string; password: string }): Observable<any> {
    return this.http
      .post('/auth/login', { ...body, domain: this.domainService.getSubdomainForLogin() })
      .pipe(switchMap(({ token, code_name }) => this.processTokenAndLoginStatus(token, code_name)));
  }

  getUserLoginByToken(token: string, signInType: SIGN_IN_TYPES, organizationId?: number): Observable<any> {
    return this.http
      .post('/auth/login/token', { token, signInType, organizationId })
      .pipe(switchMap(({ token, code_name, email }) => this.processTokenAndLoginStatus(token, code_name, email)));
  }

  verifyUserByToken(token: string, signInType: SIGN_IN_TYPES, organizationId?: number): Observable<any> {
    return this.http
      .post('/auth/verify/token', { token, signInType, organizationId })
      .pipe(map(({ result }) => result));
  }

  validateUserSelfEnrollmentLogin(body: {
    firstName: string;
    lastName: string;
    email: string;
    link: string;
  }): Observable<any> {
    return this.http.post('/self-enrollment/validate/register', body);
  }

  getUserSelfEnrollmentLogin(body: {
    username?: string;
    password?: string;
    token?: string;
    signInType?: string;
    payload: { link: string };
    organizationId?: number;
  }): Observable<any> {
    return this.http.post('/self_enrollment/login', body).pipe(
      catchError((error) => throwError(error)),
      switchMap(({ token, code_name }) => this.processTokenAndLoginStatus(token, code_name)),
    );
  }

  getMemberClicksUserSelfEnrollmentLogin(body: {
    username: string;
    password: string;
    payload: { link: string };
  }): Observable<any> {
    return this.http.post('/self_enrollment/login-memberclicks', body).pipe(
      catchError((error) => throwError(error)),
      switchMap(({ token, code_name }) => this.processTokenAndLoginStatus(token, code_name)),
    );
  }

  getMembersuiteUserSelfEnrollmentLogin(body: { token: string; payload: { link: string } }): Observable<any> {
    return this.http.post('/self_enrollment/login-membersuite', body).pipe(
      catchError((error) => throwError(error)),
      switchMap(({ token, code_name }) => this.processTokenAndLoginStatus(token, code_name)),
    );
  }

  forceLoginUserByToken(token): Observable<any> {
    return this.http.post('/auth/login/token', { token, signInType: SIGN_IN_TYPES.PASSWORDLESS }).pipe(
      map(({ result }) => {
        this.tokenStorageService.userToken = result.token;

        return result;
      }),
    );
  }

  getUserLoginEmail(token): Observable<any> {
    return this.http.post('/user/email/verify', { token }).pipe(
      map((data) => {
        const verifiedToken = data.token;
        if (verifiedToken) {
          this.tokenStorageService.userToken = verifiedToken;
          this.domainService.redirectAfterInit(this.domainService.getDomainNameFromToken(verifiedToken));
        }

        return data;
      }),
    );
  }

  changeUserEmail(params): Observable<any> {
    return this.http.patch('/user/email', params);
  }

  replaceUserToken(token) {
    this.tokenStorageService.userToken = token;
  }

  acceptTermsAndConditions(): Observable<any> {
    return this.http
      .post('/user/accept_terms_and_conditions', {})
      .pipe(tap(({ token }) => this.replaceUserToken(token)));
  }

  private clearTokens() {
    this.tokenStorageService.dropAllTokens();
  }
  private deleteCookie(): Observable<any> {
    return this.http.post('/identity/session/end', {});
  }

  getProfilesIfLoggedIn() {
    if (this.tokenStorageService.isLoggedIn) {
      this.store.dispatch(new ProfileActions.Login());
    }
  }

  private processTokenAndLoginStatus(token: string, loginStatus: LOGIN_STATUSES, email = ''): Observable<any> {
    return of(true).pipe(
      switchMap(() => this.loginStatusService.handleLoginError(loginStatus, false, email)),
      switchMap(({ success, acceptTerms, errorMessage }) => {
        this.tokenStorageService.userToken = token;
        if (acceptTerms) {
          return this.acceptTermsAndConditions().pipe(
            catchError(() => {
              this.userLogout();
              return of(null);
            }),
            map(() => errorMessage),
          );
        }
        if (!success) {
          this.tokenStorageService.dropAllTokens();
          if (!errorMessage) {
            return NEVER;
          }
        }
        return of(errorMessage);
      }),
      switchMap((message) => {
        if (message) {
          return of(message);
        }
        return this.domainService.changeDomain({ token: this.tokenStorageService.userToken }).pipe(
          filter((changeDomainRequired: boolean) => !changeDomainRequired),
          tap(() => this.loginStatusService.processLoginStatus(loginStatus)),
          tap(() => this.getProfilesIfLoggedIn()),
          map(() => null),
        );
      }),
    );
  }
}
