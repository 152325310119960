export interface PaymentType {
  organizationId: number;
  type: PAYMENT_TYPES;
}

export interface PayflowProConfig {
  partner: string;
  vendor: string;
  user: string;
  password: string;
}

export interface StripeConfig {
  account: string;
}

export interface PayflowProConfig {
  partner: string;
  vendor: string;
  user: string;
  password: string;
}

export interface StripeConfig {
  account: string;
}

export interface SquareConfig {
  applicationId: string;
  accessToken: string;
  locationId: string;
}

export interface ExactConfig {
  responseKey: 'string';
  transactionLogin: 'string';
  transactionKey: 'string';
}

export interface EPayConfig {
  id: 'string';
  key: 'string';
}

export interface AuthorizeNetConfig {
  apiLogin: 'string';
  clientKey: 'string';
  transactionKey: 'string';
}

export type PaymentConfig =
  | SquareConfig
  | StripeConfig
  | PayflowProConfig
  | ExactConfig
  | EPayConfig
  | AuthorizeNetConfig;

export interface PaymentSettings extends PaymentType {
  payflowProConfig?: PayflowProConfig;
  stripeConfig?: StripeConfig;
  squareConfig?: SquareConfig;
  exactConfig?: ExactConfig;
  ePayConfig?: EPayConfig;
  authorizeNetConfig?: AuthorizeNetConfig;
}

export enum PAYMENT_TYPES {
  STRIPE = 'STRIPE',
  PAYFLOW_PRO = 'PAYFLOW_PRO',
  SQUARE = 'SQUARE',
  VOUCHER = 'voucher',
  EXACT = 'EXACT',
  EPAY = 'EPAY',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
}

export enum PAYFLOW_STATUS {
  SUCCESS = '0',
}

export interface PayPlowResponse {
  RESULT: PAYFLOW_STATUS;
  SECURETOKEN: string;
  SECURETOKENID: string;
  RESPMSG: string;
}

export interface PaymentTokenPayload {
  SECURETOKEN: string;
  SECURETOKENID: string;
}
