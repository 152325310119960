import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../token/token-storage.service';
import { Injectable, Provider } from '@angular/core';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private tokenStorage: TokenStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.updateHeaders(request.headers);
    const clonedRequest = request.clone({ headers, withCredentials: true });
    return next.handle(clonedRequest);
  }

  private updateHeaders(headers) {
    const token = this.tokenStorage.userToken;
    headers = headers
      .append('X-Content-Type-Options', 'nosniff')
      .append('Strict-Transport-Security', 'max-age=63072000; includeSubDomains; preload')
      .append('Referrer-Policy', 'strict-origin-when-cross-origin');

    if (token) {
      headers = headers.delete('Authorization').append('Authorization', `Bearer ${token}`);
    }

    const date: any = new Date();
    headers = headers.delete('If-Modified-Since').append('If-Modified-Since', date.toGMTString());

    if (!headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    } else if (headers.get('Content-Type') === 'multipart/form-data') {
      headers = headers.delete('Content-Type', 'multipart/form-data');
    }

    return headers;
  }
}

export const RequestInterceptorService: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestInterceptor,
  multi: true,
};
