import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { TokenStorageService } from 'services/token/token-storage.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import _get from 'lodash-es/get';
import { DEFAULT_PRODUCTION_DOMAIN_NAME } from '../../global';
import { Observable, of } from 'rxjs';
import { CHANGE_DOMAIN_TYPE } from 'constants/constants';

@Injectable()
export class DomainService {
  readonly ENVIRONMENT_NAME = {
    PRODUCTION: 'production',
    LOCAL: 'local',
  };
  readonly NO_REDIRECT_ON_INIT_LINK_PART = [
    'invited',
    'reset-password',
    'entry/self-enrollments',
    'verify-email',
    'login-user',
  ];

  readonly NO_PATHNAME_LINK_PARTS = ['reset-password', 'login-user', 'first-login', 'existing-account'];

  constructor(private tokenStorage: TokenStorageService, private cookieService: CookieService) {}

  getDomainNameFromToken(token): string {
    const decodedJWT = token ? jwtDecode(token) : null;
    return _get(decodedJWT, 'organization_domain_name', '');
  }

  getDomainNameFromUrl(returnLocalDomain?: boolean, returnFullSubdomain?: boolean): string {
    if (this.isLocalEnvironment()) {
      return returnLocalDomain ? window.location.hostname : '';
    }
    const environmentName = environment.name.toLowerCase();
    const host = window.location.host;
    const indexOfEnvironment = host.indexOf(environmentName);
    const dotExistsBeforeEnvName = new RegExp(/\./).test(host[indexOfEnvironment - 1]);
    const endForSlice = dotExistsBeforeEnvName ? indexOfEnvironment - 1 : indexOfEnvironment;
    const indexOfCertemy = this.getIndexOfMainDomain();

    if (returnFullSubdomain) {
      return host.slice(0, dotExistsBeforeEnvName ? endForSlice : indexOfCertemy);
    }

    return this.isProductionEnvironment() ? host.slice(0, indexOfCertemy) : host.slice(0, endForSlice); // returns 'app' or domain name if production
  }

  getSubdomainForLogin() {
    return this.getDomainNameFromUrl(true, true);
  }

  changeDomain(changeDomainInfo: { token?: string; domainName?: string; isRenew?: boolean } = {}): Observable<boolean> {
    const currentToken = changeDomainInfo.token || '';
    const isRenew = changeDomainInfo.isRenew || false;
    const environmentName = environment.name.toLowerCase();
    const domainName = this.getDomainNameFromToken(currentToken) || changeDomainInfo.domainName;

    const subDomainUrl = `https://${domainName}${
      this.isProductionEnvironment() ? '' : `.${environmentName}`
    }.certemy.com`;
    const urlWithoutSubDomain = `https://${
      this.isProductionEnvironment() ? DEFAULT_PRODUCTION_DOMAIN_NAME : `${environmentName}`
    }.certemy.com`;
    const redirectUrl = domainName ? subDomainUrl : urlWithoutSubDomain;

    const locationOrigin = `${window.location.protocol}//${window.location.host}`;

    // intialize process redirect as false
    let processRedirect = false;

    // if renew flag is set or if not local environment and location origin does not equal redirect url
    // set process redirect to true
    if (isRenew || (!this.isLocalEnvironment() && locationOrigin !== redirectUrl)) {
      processRedirect = true;
    }

    // if process redirect is set to true but the location href contains 'external-login'
    // set the process redirect to false
    if (processRedirect && window.location.href.indexOf('external-login') !== -1) {
      processRedirect = false;
    }

    // if process redirect replace the location
    if (processRedirect) {
      let changeDomainType;
      if (locationOrigin !== redirectUrl) {
        this.setTokenToCookies(currentToken);
        changeDomainType = CHANGE_DOMAIN_TYPE.CHANGE_LOCATION_ONLY;
      }
      if (isRenew) {
        changeDomainType = CHANGE_DOMAIN_TYPE.IS_RENEW;
      }
      this.setTokenToCookies(changeDomainType, 'changeDomainType');
      const pathName =
        !isRenew && locationOrigin !== window.location.href && !this.urlHasDefinitePart(this.NO_PATHNAME_LINK_PARTS)
          ? this.tokenStorage.redirectUrl || `${window.location.pathname}`
          : '';
      const subDomainWithPathName = redirectUrl + pathName + window.location.search;

      // drop token
      this.tokenStorage.dropUserToken();

      window.location.replace(subDomainWithPathName);

      return of(true);
    }

    return of(false);
  }

  changeDomainIfDomainNameChanged(isRenew?: boolean) {
    if (
      isRenew ||
      (this.tokenStorage.userToken &&
        this.getDomainNameFromToken(this.tokenStorage.userToken) !== this.getDomainNameFromUrl())
    ) {
      return this.changeDomain({ token: this.tokenStorage.userToken, isRenew });
    }
    return of(false);
  }

  removeTokenFromCookies(cookieName: string = 'token') {
    if (this.getTokenFromCookies(cookieName) && !this.isLocalEnvironment()) {
      this.cookieService.delete(cookieName, '/', 'certemy.com');
    }
  }

  loginUserIfRedirected() {
    const token = this.getTokenFromCookies();
    if (token) {
      this.tokenStorage.userToken = token;
    }
  }

  private setTokenToCookies(value, cookieName: string = 'token') {
    if (this.canSetTokenToCookies()) {
      this.cookieService.set(cookieName, value, undefined, '/', 'certemy.com');
    }
  }

  private canSetTokenToCookies(): boolean {
    const queryParams = window.location.search;
    return !queryParams.includes('memberSuiteUserToken');
  }

  getTokenFromCookies(cookieName: string = 'token'): string {
    return this.cookieService.get(cookieName);
  }

  private isLocalEnvironment(): boolean {
    return environment.name.toLowerCase() === this.ENVIRONMENT_NAME.LOCAL;
  }

  private isProductionEnvironment(): boolean {
    return environment.name.toLowerCase() === this.ENVIRONMENT_NAME.PRODUCTION;
  }

  private urlHasDefinitePart(definiteParts: string[]) {
    const currentHref = window.location.href;
    return definiteParts.some(link => currentHref.includes(link));
  }

  dropTokenOnExactLinks(): void {
    if (this.urlHasDefinitePart(this.NO_REDIRECT_ON_INIT_LINK_PART)) {
      this.tokenStorage.dropAllTokens();
    }
  }

  redirectAfterInit(domainName: string) {
    if (domainName && !this.subDomainIncludesCustomDomain(domainName)) {
      this.changeDomain({ domainName });
    }
  }

  private getIndexOfMainDomain(): number {
    return window.location.host.indexOf('.certemy.com');
  }

  private subDomainIncludesCustomDomain(domainName: string): boolean {
    const indexOfCertemy = this.getIndexOfMainDomain();
    return window.location.host.slice(0, indexOfCertemy).includes(domainName);
  }
}
