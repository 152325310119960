import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges } from '@angular/core';
import _get from 'lodash-es/get';

const IMAGE_ICON_MAP = {
  'user-icon-colored': 'user-icon',
  'facility-avatar': 'facility-icon',
};

@Component({
  selector: 'cm-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAvatarComponent implements OnChanges {
  @Input() placeholder = '';
  @Input() imageUrl: string;
  @Input() uploadEnabled = false;
  @Input() responsivePlaceholder: any;
  iconName = '';
  canUseColoredPlaceholder = false;

  private imageUrlData: string;

  constructor(private element: ElementRef) {}

  ngOnChanges(changes) {
    if (changes.placeholder) {
      const placeholderImageName = this.placeholder.slice(
        this.placeholder.lastIndexOf('/') + 1,
        this.placeholder.lastIndexOf('.'),
      );
      this.iconName = IMAGE_ICON_MAP[placeholderImageName];
    }

    if (changes.imageUrl) {
      this.imageUrlData = _get(this.imageUrl, 'length') ? this.imageUrl : this.placeholder;
      this.canUseColoredPlaceholder = this.iconName && this.placeholder === this.imageUrlData;
    }

    if (!this.canUseColoredPlaceholder) {
      this.element.nativeElement.style.background = `center / contain no-repeat url(${this.imageUrlData})`;
      if (this.responsivePlaceholder && this.imageUrlData === this.placeholder) {
        const responsiveSrc = [];
        for (const prop in this.responsivePlaceholder) {
          if (this.responsivePlaceholder.hasOwnProperty(prop)) {
            responsiveSrc.push(`url(${this.responsivePlaceholder[prop]}) ${prop}`);
          }
        }
        this.element.nativeElement.style.backgroundImage = `-webkit-image-set(${responsiveSrc.join(',')})`;
        this.element.nativeElement.style.backgroundImage = `image-set(${responsiveSrc.join(',')})`;
      }
    } else {
      this.element.nativeElement.style.backgroundImage = 'none';
    }
  }
}
