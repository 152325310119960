import { NgModule } from '@angular/core';
import { SharedCommonModule } from '@certemy/common';

import { PermissionsPipe } from './pipes/PermissionsPipe';
import { StatusPipe } from './pipes/StatusPipe';
import { SwitchMultiCasePipe } from './pipes/SwitchMultiCasePipe';
import { InteractableDirective } from 'features/certification-file/image/interactable/interactable.directive';
import { FeatureToggleModule } from 'features/feature-toggle/feature-toggle.module';
import { CurrencyLocalePipe } from 'common/pipes/CurrencyLocalePipe';
import { NumberLocalePipe } from 'common/pipes/NumberLocalePipe';
import { OptionSortPipe } from 'common/pipes/OptionSortPipe';
import { DomSanitizerPipe } from 'common/pipes/DomSanitizer.pipe';

const pipes = [
  PermissionsPipe,
  StatusPipe,
  SwitchMultiCasePipe,
  CurrencyLocalePipe,
  NumberLocalePipe,
  OptionSortPipe,
  DomSanitizerPipe,
];
const directives = [InteractableDirective];

@NgModule({
  imports: [SharedCommonModule],
  exports: [SharedCommonModule, ...pipes, ...directives, FeatureToggleModule],
  declarations: [...pipes, ...directives],
  providers: [],
})
export class CertemyCommonModule {}
