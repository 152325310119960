import { Injectable } from '@angular/core';
import { CasbinService } from 'services/casbin/casbin.service';
import { from, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PolicyObj {
  name: string;
  url: string;
  action: string;
  forceCheck?: boolean;
}

export type IPolicies<T extends keyof any> = { [K in T]?: PolicyObj };

export interface IPermissions<T = any> {
  [key: string]: boolean;
}

@Injectable()
export class PolicyPermissionsService {
  constructor(private casbinService: CasbinService) {}

  hasPermissions<T = any>(policyUrl: string, policyAction: string | string[]): Observable<IPermissions<T>> {
    const actions = typeof policyAction === 'string' ? [policyAction] : policyAction;
    const policiesList = actions.map(action => ({ name: action, url: policyUrl, action }));

    return this.getPermissions(policiesList);
  }

  hasMultiplePermissions<T = any>(policiesList: PolicyObj[]): Observable<IPermissions<T>> {
    return this.getPermissions(policiesList);
  }

  private getPermissions<T = any>(policiesList: PolicyObj[]): Observable<IPermissions<T>> {
    const mappedActions = policiesList.map(({ url, action, forceCheck }) => {
      return from(this.casbinService.checkPermission(url, action, forceCheck));
    });

    return zip(...mappedActions).pipe(
      map(results => {
        return results.reduce((permissionsMap, isEnable, index) => {
          const { name } = policiesList[index];
          permissionsMap[name] = isEnable;
          return permissionsMap;
        }, {}) as IPermissions<T>;
      }),
    );
  }
}
