import { Store } from '@ngrx/store';
import { Actions } from 'features/profiles-state/+state/profiles-state.actions';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { requestCompleted } from '@certemy/common';
import { TokenStorageService } from 'services/token/token-storage.service';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { tap } from 'rxjs/operators';
import { InitialNavigation } from 'features/router-state/+state/router-state.actions';
import { Actions as BrandingActions } from 'features/organization-settings/platform-branding/+state/platform-branding.actions';
import { Selectors as BrandingSelectors } from 'features/organization-settings/platform-branding/+state/platform-branding.state';
import { DomainService } from 'services/domain/domain.service';
import { Actions as LoginProviderActions } from 'services/login-provider/+state/login-provider.actions';
import { DEFAULT_PRODUCTION_DOMAIN_NAME } from '../../global';

export const AppInitializerFactory = (
  store: Store<any>,
  tokenStorage: TokenStorageService,
  domainService: DomainService,
) => () => {
  domainService.dropTokenOnExactLinks();
  domainService.loginUserIfRedirected();

  store.dispatch(new BrandingActions.GetBrandingSettings());
  const domain = domainService.getDomainNameFromUrl();
  if (domain && domain !== DEFAULT_PRODUCTION_DOMAIN_NAME) {
    store.dispatch(new LoginProviderActions.GetLoginProviders());
  }
  const currentUrl = window.location.pathname;
  if (tokenStorage.isLoggedIn) {
    store.dispatch(new Actions.GetProfiles());
    return requestCompleted(store.select(Selectors.profilesState))
      .pipe(
        tap(() => domainService.removeTokenFromCookies()),
        tap(() => store.dispatch(new InitialNavigation({ url: currentUrl }))),
      )
      .toPromise();
  } else {
    return requestCompleted(store.select(BrandingSelectors.brandingState));
  }
};

export const AppInitializerService: Provider = {
  provide: APP_INITIALIZER,
  useFactory: AppInitializerFactory,
  multi: true,
  deps: [Store, TokenStorageService, DomainService],
};
