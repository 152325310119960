import { NgModule } from '@angular/core';

import { UserProfilesListComponent } from './user-profiles-list.component';
import { RouterModule } from '@angular/router';
import { ProfileAvatarModule } from 'components/profile-avatar/profile-avatar.module';
import { CertemyCommonModule } from 'common/certemy-common.module';

@NgModule({
  imports: [RouterModule, ProfileAvatarModule, CertemyCommonModule],
  exports: [UserProfilesListComponent],
  declarations: [UserProfilesListComponent],
  providers: [],
})
export class UserProfilesListModule {}
