import { SmartTable } from '@certemy/ui-components';
import { AUDIT_PROFILE_STATUS } from 'features/audit/audit-transcript-modal/audit-transcript-modal.interface';
import { RollingAuditFilters } from 'features/audit/rolling-audit-list/rolling-audit-list.interface';

export const ROLLING_AUDIT_PROFILE_STATUS_NAME = {
  [AUDIT_PROFILE_STATUS.UNREVIEWED]: 'Pending',
  [AUDIT_PROFILE_STATUS.FAILED]: 'Failed',
  [AUDIT_PROFILE_STATUS.REQUESTED_CORRECTIONS]: 'Corrections requested',
  [AUDIT_PROFILE_STATUS.APPROVED]: 'Passed',
};

export class RollingAuditListFilters {
  page = 1;
  search = '';
  orderId = 'userFirstName';
  orderType = SmartTable.Order.DESC;
  order: SmartTable.OrderedHeader;
  filters: RollingAuditFilters = {};
}

export const defaultRollingAuditTableData = {
  rows: [],
  count: 0,
};

export enum AUDIT_BORDER_COLOR {
  RESUBMIT_REQUIRED = '#8C1812',
}

export const STATUS_OPTIONS = [
  { id: AUDIT_PROFILE_STATUS.UNREVIEWED, name: ROLLING_AUDIT_PROFILE_STATUS_NAME[AUDIT_PROFILE_STATUS.UNREVIEWED] },
  { id: AUDIT_PROFILE_STATUS.FAILED, name: ROLLING_AUDIT_PROFILE_STATUS_NAME[AUDIT_PROFILE_STATUS.FAILED] },
  {
    id: AUDIT_PROFILE_STATUS.REQUESTED_CORRECTIONS,
    name: ROLLING_AUDIT_PROFILE_STATUS_NAME[AUDIT_PROFILE_STATUS.REQUESTED_CORRECTIONS],
  },
  { id: AUDIT_PROFILE_STATUS.APPROVED, name: ROLLING_AUDIT_PROFILE_STATUS_NAME[AUDIT_PROFILE_STATUS.APPROVED] },
];
