import { SmartTable } from '@certemy/ui-components';

export class BatchAuditListFilters {
  page = 1;
  search = '';
  orderId = 'certificationName';
  orderType = SmartTable.Order.DESC;
  order: SmartTable.OrderedHeader;
}

export enum BATCH_AUDIT_STATUS {
  IN_PROGRESS = 1,
  COMPLETED = 2,
  DELETED = 3,
  INACTIVE = 4,
}

export const BATCH_AUDIT_STATUS_NAME = {
  [BATCH_AUDIT_STATUS.IN_PROGRESS]: 'In Progress',
  [BATCH_AUDIT_STATUS.COMPLETED]: 'Completed',
  [BATCH_AUDIT_STATUS.DELETED]: 'Deleted',
  [BATCH_AUDIT_STATUS.INACTIVE]: 'Inactive',
};
