import { NgModule } from '@angular/core';
import { FeatureToggleService } from 'features/feature-toggle';
import { FeatureToggleDirective } from 'features/feature-toggle/feature-toggle.directive';
import { FeatureToggleGuardService } from 'features/feature-toggle/feature-toggle.guard';

@NgModule({
  declarations: [FeatureToggleDirective],
  exports: [FeatureToggleDirective],
  providers: [FeatureToggleGuardService, FeatureToggleService],
})
export class FeatureToggleModule {}
