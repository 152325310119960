<div class="modal-header">
    <h2 class="title">Terms and conditions of our system were updated.</h2>
    <p>Please see the changed version and accept to proceed.</p>
</div>
<div class="modal-body">
    <div class="terms"><cm-terms-and-conditions></cm-terms-and-conditions></div>
    <div class="inline-form-control">
        <div class=" terms-conditions">
            <cm-checkbox [(ngModel)]="isTermsAndCondAccepted">
                I've read and accepted new Terms and Conditions
            </cm-checkbox>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="inline-form-control">
        <div><button class="btn ch-button" [disabled]="!isTermsAndCondAccepted" (click)="onAccept()">Done</button></div>
    </div>
</div>
