<div class="mobile-menu" *ngIf="!showProfiles">
    <div class="mobile-menu-list" *ngIf="items.length">
        <a
            *ngFor="let menuItem of items"
            class="menu-link"
            [routerLink]="menuItem.link"
            (click)="closeMobileMenu.emit(menuItem)"
            >{{ menuItem.label }}</a
        >
    </div>
    <div class="menu-link" (click)="showProfiles = true">Profile</div>
    <div class="menu-link" (click)="logout.emit()" data-id="logout-btn">Log out</div>
</div>
<cm-user-profiles-list *ngIf="showProfiles" class="mobile-user-callout" [profile]="profile" [profiles]="profiles">
</cm-user-profiles-list>
