<div class="wrapper" #wrapper>
    <div *ngFor="let element of (elements$ | async)" class="action">
        <ng-container *ngIf="(isTemplateRef | thunk: element)">
            <ng-template *ngTemplateOutlet="element; context: (getContext | thunk: element)"></ng-template>
        </ng-container>

        <ng-container *ngIf="!(isTemplateRef | thunk: element)">
            <ng-template *ngComponentOutlet="element"></ng-template>
        </ng-container>
    </div>
</div>
