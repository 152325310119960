export enum EDUCATION_OPPORTUNITIES {
  NO_REQUIREMENTS = 1,
  LIST_OF_OPPORTUNITIES = 2,
  REQUIREMENTS_WITH_LIST_OF_OPPORTUNITIES = 3,
}

export enum EDUCATION_TYPES {
  SPECIFIC_COURSE = 1,
  GENERAL_CE = 2,
  SPECIFIC_SUB_FIELD = 3,
}

export const EDUCATION_TYPE_NAMES = Object.freeze({
  [EDUCATION_TYPES.SPECIFIC_COURSE]: 'Specific Course',
  [EDUCATION_TYPES.GENERAL_CE]: 'General CE',
  [EDUCATION_TYPES.SPECIFIC_SUB_FIELD]: 'Specific Subfield',
});

export enum EDUCATION_FORMATS {
  ALL = 1,
  ONLINE_SELF_PACED = 2,
  OFFLINE_SELF_PACED = 3,
  WEBINAR = 4,
  LIVE_CONFERENCE = 5,
}

export const EDUCATION_INSTANCE = Object.freeze({
  COURSE: 'courseinstance',
  WEBINAR: 'webinarinstance',
});

export const EDUCATION_FORMAT_NAMES = Object.freeze({
  ALL: 'All',
  ONLINE_SELF_PACED: 'Online Self Paced',
  OFFLINE_SELF_PACED: 'Offline Self Paced',
  WEBINAR: 'Webinar',
  LIVE_CONFERENCE: 'Live Conference',
  [EDUCATION_FORMATS.ALL]: 'All',
  [EDUCATION_FORMATS.ONLINE_SELF_PACED]: 'Online Self Paced',
  [EDUCATION_FORMATS.OFFLINE_SELF_PACED]: 'Offline Self Paced',
  [EDUCATION_FORMATS.WEBINAR]: 'Webinar',
  [EDUCATION_FORMATS.LIVE_CONFERENCE]: 'Live Conference',
});

export const EDUCATION_FORMAT_RADIO = Object.freeze({
  WEBINAR: 0,
  ONLINE_SELF_PACED: 1,
});

export default Object.freeze({
  EDUCATION_OPPORTUNITIES,
  EDUCATION_TYPES,
  EDUCATION_TYPE_NAMES,
  EDUCATION_FORMATS,
  EDUCATION_INSTANCE,
  EDUCATION_FORMAT_NAMES,
  EDUCATION_FORMAT_RADIO,
});
