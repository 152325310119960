import { Pipe, PipeTransform } from '@angular/core';
import { COMPLIANCE_STATUS_NAMES, SUPERVISOR_STATUS_NAMES, STEP_TYPES_NAMES } from 'constants/status.constants';
import { EDUCATION_FORMAT_NAMES } from 'constants/education-step.constants';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  constructor() {}

  transform(statusId, statusName) {
    let status;

    switch (statusName) {
      case 'supervisor':
        status = SUPERVISOR_STATUS_NAMES[statusId];
        break;
      case 'compliance':
        status = COMPLIANCE_STATUS_NAMES[statusId];
        break;
      case 'step-type':
        status = STEP_TYPES_NAMES[statusId];
        break;
      case 'education-formats':
        status = EDUCATION_FORMAT_NAMES[statusId];
        break;
      default:
        status = 'N/A';
    }

    return status;
  }
}
