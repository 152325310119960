import { ResponseStatus } from '@certemy/common';
import { PlatformBrandingState } from './platform-branding.state';
import { Actions, ActionType } from './platform-branding.actions';
import { DEFAULT_PLATFORM_SETTINGS } from '../platform-branding.constants';

export function brandingReducer(state = new PlatformBrandingState(), action: ActionType) {
  switch (action.type) {
    case Actions.GetBrandingSettings.type:
      return {
        ...state,
        status: ResponseStatus.PENDING,
      };
    case Actions.GetBrandingSettingsSuccess.type:
      return {
        ...state,
        brandingSettings: action.payload,
        status: ResponseStatus.COMPLETE,
      };
    case Actions.GetBrandingSettingsError.type:
      return {
        ...state,
        brandingSettings: DEFAULT_PLATFORM_SETTINGS,
        status: ResponseStatus.ERROR,
      };
    case Actions.SetPlatformLogo.type:
      return {
        ...state,
        brandingSettings: {
          ...state.brandingSettings,
          avatar: action.payload.imageConfig,
          logoFormType: action.payload.logoFormType,
        },
      };
    case Actions.SetColorSettings.type:
      return {
        ...state,
        brandingSettings: {
          ...state.brandingSettings,
          colorSettings: action.payload,
        },
      };

    case Actions.SetFontSettings.type:
      return {
        ...state,
        brandingSettings: {
          ...state.brandingSettings,
          baseFont: action.payload.baseFont,
          headingFont: action.payload.headingFont,
        },
      };
    case Actions.SetBaseFont.type:
      return {
        ...state,
        brandingSettings: {
          ...state.brandingSettings,
          baseFont: action.payload,
        },
      };
    case Actions.SetHeadingFont.type:
      return {
        ...state,
        brandingSettings: {
          ...state.brandingSettings,
          headingFont: action.payload,
        },
      };
    case Actions.Reset.type:
      return {
        ...state,
        brandingSettings: DEFAULT_PLATFORM_SETTINGS,
      };
    default:
      return state;
  }
}
