import { NgModule } from '@angular/core';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { ConfirmReloadComponent } from 'components/confirm-reload/confirm-reload.component';

@NgModule({
  imports: [CertemyCommonModule],
  declarations: [ConfirmReloadComponent],
  providers: [],
  exports: [ConfirmReloadComponent],
})
export class ConfirmReloadModule {}
