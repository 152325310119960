import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { Observable, of, throwError } from 'rxjs';
import { ShowIfHttpError } from '@certemy/common';
import { PAYMENT_TYPES, PaymentSettings, PaymentType } from './payment.interface';
import { switchMap } from 'rxjs/operators';
import { PAYMENT_FORM_PLACE, PaymentProcessorError } from 'features/payment-form/payment-form.constants';

@Injectable()
export class PaymentService {
  constructor(private http: HttpService) {}

  @ShowIfHttpError('Cannot receive payment settings.')
  getPaymentSettings(): Observable<PaymentSettings> {
    return this.http.get('/payment/settings');
  }

  @ShowIfHttpError('Cannot receive payment settings.')
  getPublicPaymentSettings(stepId: number | null, place: PAYMENT_FORM_PLACE): Observable<PaymentSettings> {
    return this.http.get('/payment/settings/public', { params: { ...(stepId ? { stepId } : {}) } }).pipe(
      switchMap((response: PaymentSettings) =>
        [PAYMENT_TYPES.EPAY, PAYMENT_TYPES.EXACT, PAYMENT_TYPES.AUTHORIZE_NET].includes(response.type) &&
        [PAYMENT_FORM_PLACE.EDUCATION, PAYMENT_FORM_PLACE.PHASE, PAYMENT_FORM_PLACE.VOUCHERS].includes(place)
          ? throwError({
              body: {
                error_details: PaymentProcessorError,
              },
            })
          : of(response),
      ),
    );
  }

  @ShowIfHttpError('Cannot update payment settings.')
  updatePaymentSettings(body: PaymentSettings): Observable<void> {
    return this.http.put('/payment/settings', body);
  }

  @ShowIfHttpError('Cannot get payment token.')
  getPaymentToken(amount: number, stepId?: number): Observable<PaymentType> {
    const params = {
      amount,
      ...(stepId ? { stepId } : {}),
    };

    return this.http.get('/payment/token', { params });
  }
}
