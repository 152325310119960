<header class="header" *cmLet="logoConfig$ | async as logoConfig">
    <div class="header__logo">
        <a class="logo" [routerLink]="settings.logoLink">
            <img
                class="logo-image"
                [ngClass]="{ 'is-circle': logoConfig.logoFormType === CROPPER_MODES.CIRCLE }"
                [src]="logoConfig.imageConfig.imageFile"
            />
        </a>
    </div>

    <div class="header__main-menu">
        <ng-container *ngFor="let menuItem of settings.mainMenu; let i = index">
            <cm-dropdown-menu
                *ngIf="menuItem.dropdownMenu"
                [menuItem]="menuItem"
                [isLast]="i === settings.mainMenu.length - 1"
            ></cm-dropdown-menu>
            <a
                *ngIf="!menuItem.dropdownMenu"
                [routerLink]="menuItem.externalLink ? null : menuItem.link"
                [routerLinkActive]="menuItem.externalLink ? '' : 'header__link--active'"
                class="header__link"
                (click)="onRouterLinkClick(menuItem)"
            >
                <cm-icon *ngIf="menuItem.icon" [name]="menuItem.icon" class="header__icon"></cm-icon>
                {{ menuItem.label }}
            </a>
        </ng-container>

        <a
            *ngIf="isProfileLinkVisible"
            class="header__link header__link--profile"
            [routerLink]="profileDetailsLink$ | async"
            routerLinkActive="header__link--active"
        >
            <cm-active-profile class="active-profile" [profile]="profile"></cm-active-profile>
        </a>

        <cm-active-profile *ngIf="!isProfileLinkVisible" class="active-profile" [profile]="profile"></cm-active-profile>

        <div class="header__item log-out">
            <button
                type="button"
                class="log-out__btn"
                (click)="onLogout()"
                data-id="logout-btn"
                ngbTooltip="Log out"
                placement="bottom"
                container="body"
            >
                <img class="log-out__img" src="/assets/images/log-out.svg" />
            </button>
        </div>

        <div class="header__item show-mobile">
            <button class="mobile-menu-btn" (click)="onToggleMobileMenu()">
                <cm-hamburger-icon [class.open]="showMobileMenu"></cm-hamburger-icon>
            </button>
        </div>
    </div>
</header>
<cm-mobile-menu
    class="mobile-menu"
    *ngIf="showMobileMenu"
    [items]="settings.mainMenu"
    [profile]="profile"
    [profiles]="profiles"
    (logout)="onLogout()"
    (closeMobileMenu)="closeMobileMenu($event)"
>
</cm-mobile-menu>
