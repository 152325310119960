import { ROUTER_NAVIGATION, ROUTER_NAVIGATED, ROUTER_CANCEL, ROUTER_ERROR } from '@ngrx/router-store';
import { RouterStateActions } from './router-state.actions';
import { RouterState } from './router-state.state';

export function routerReducer(state: RouterState = new RouterState(), action: RouterStateActions): RouterState {
  switch (action.type) {
    case ROUTER_NAVIGATED: {
      return {
        ...state,
        activatedRoute: action.payload.routerState,
      };
    }
    case ROUTER_NAVIGATION:
    case ROUTER_ERROR:
    case ROUTER_CANCEL:
      return {
        ...state,
        state: action.payload.routerState,
        navigationId: action.payload.event.id,
      };
    default: {
      return state;
    }
  }
}
