import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Actions as LoginProviderActions } from './login-provider.actions';
import { LoginProviderResponse } from 'services/login-provider/login-provider.interface';
import { EntryService } from 'services/entry/entry.service';

@Injectable()
export class LoginProviderEffects {
  getSettings = createEffect(() => this.actions$.pipe(
    ofType(LoginProviderActions.GetLoginProviders.type),
    switchMap(() => this.entryService.getLoginProviderByDomainName()),
    map((response: LoginProviderResponse) => {
      return new LoginProviderActions.GetLoginProvidersSuccess(response);
    }),
    catchError(() => {
      return of(new LoginProviderActions.GetLoginProvidersError());
    }),
  ));

  constructor(private actions$: Actions, private entryService: EntryService) {}
}
