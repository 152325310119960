import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { DecimalPipe } from '@angular/common';
import { Selectors as ProfileSelectors } from 'features/profiles-state/+state/profiles-state.state';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _isNumber from 'lodash-es/isNumber';

@UntilDestroy()
@Pipe({
  name: 'cmCurrency',
})
export class CurrencyLocalePipe implements PipeTransform, OnDestroy {
  locale = 'en-US';
  constructor(private store: Store<any>) {
    this.store
      .select(ProfileSelectors.paymentCurrency)
      .pipe(
        tap((currency) => {
          switch (currency) {
            case 'usd':
            case 'cad':
              this.locale = 'en';
              break;
            case 'eur':
            default:
              this.locale = 'fr-CA';
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
  transform(number = 0) {
    if (!_isNumber(number)) {
      return number;
    }
    return new DecimalPipe(this.locale).transform(number, '1.2');
  }
  ngOnDestroy() {}
}
