import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Header } from '../header.component';
import { NavigationEnd, Router } from '@angular/router';
import { ComputedFrom } from '@certemy/common';
import { HttpParams } from '@angular/common/http';
import _size from 'lodash-es/size';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cm-dropdown-menu',
  templateUrl: 'dropdown-menu.component.html',
  styleUrls: ['dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  @Input() menuItem: Header.MainMenuItem;
  @Input() isLast = false;
  currentPathName = window.location.href.substring(window.location.origin.length);

  @ComputedFrom('currentPathName')
  get isActive(): boolean {
    return this.menuItem.dropdownMenu.some((row: Header.DropdownMenuRow) => {
      if (_size(row.hiddenLinks)) {
        const match = row.hiddenLinks.some((hiddenLink: Header.CommonDropdownLink) => {
          let hiddenLinkQueryParams = '';
          if (hiddenLink.queryParams) {
            hiddenLinkQueryParams = new HttpParams({ fromObject: hiddenLink.queryParams }).toString();
            return (
              this.currentPathName.includes(hiddenLink.link) &&
              window.location.search.includes(`?${hiddenLinkQueryParams}`)
            );
          }
          return this.currentPathName.includes(hiddenLink.link);
        });
        if (match) {
          return true;
        }
      }
      if (row.secondaryLink && row.secondaryLink.queryParams) {
        const queryParams = new HttpParams({ fromObject: row.secondaryLink.queryParams }).toString();
        if (this.currentPathName.includes('?') && this.currentPathName.includes(row.secondaryLink.link)) {
          return this.currentPathName.includes(row.secondaryLink.link + `?${queryParams}`);
        }
      }
      if (row.primaryLink.queryParams) {
        const queryParams = new HttpParams({ fromObject: row.primaryLink.queryParams }).toString();
        if (this.currentPathName.includes(row.primaryLink.link)) {
          return this.currentPathName.includes(row.primaryLink.link + `?${queryParams}`);
        }
      }
      return (
        this.currentPathName.includes(row.primaryLink.link) ||
        (row.secondaryLink && this.currentPathName.includes(row.secondaryLink.link))
      );
    });
  }

  constructor(private router: Router, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPathName = this.router.routerState.snapshot.url;
        this.changeDetector.markForCheck();
      }
    });
  }

  ngOnDestroy() {}
}
