import { Action } from '@ngrx/store';
import { type } from 'services/actions-cache/actions-cache.service';
import { GetUserProfilesResponse } from 'services/user/user.interface';

export namespace Actions {
  export class GetProfiles implements Action {
    static readonly type = type<'[PROFILES] GET_PROFILES'>('[PROFILES] GET_PROFILES');
    readonly type = GetProfiles.type;
  }

  export class GetProfilesSuccess implements Action {
    static readonly type = type<'[PROFILES] GET_PROFILES_SUCCESS'>('[PROFILES] GET_PROFILES_SUCCESS');
    readonly type = GetProfilesSuccess.type;

    constructor(public payload: GetUserProfilesResponse) {}
  }

  export class GetProfilesError implements Action {
    static readonly type = type<'[PROFILES] GET_PROFILES_ERROR'>('[PROFILES] GET_PROFILES_ERROR');
    readonly type = GetProfilesError.type;
  }

  export class Login implements Action {
    static readonly type = type<'[PROFILES] LOGIN'>('[PROFILES] LOGIN');
    readonly type = Login.type;
  }

  export class SwitchProfile implements Action {
    static readonly type = type<'[PROFILES] SWITCH_PROFILE'>('[PROFILES] SWITCH_PROFILE');
    readonly type = SwitchProfile.type;

    constructor(public payload: { profileId: number }) {}
  }

  export class LoginAsProfile implements Action {
    static readonly type = type<'[PROFILES] LOGIN_AS'>('[PROFILES] LOGIN_AS');
    readonly type = LoginAsProfile.type;

    constructor(public payload: { profileId: number }) {}
  }
}

export type ActionType =
  | Actions.GetProfiles
  | Actions.GetProfilesSuccess
  | Actions.GetProfilesError
  | Actions.Login
  | Actions.SwitchProfile
  | Actions.LoginAsProfile;
