import { Inject, Injectable, Optional } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-mini';
import { DATEPICKER_DELIMITER_TOKEN, DEFAULT_DATEPICKER_DELIMITER } from '@certemy/ui-components';

@Injectable()
export class CustomDatePickerParserFormatter extends NgbDateParserFormatter {
  constructor(@Optional() @Inject(DATEPICKER_DELIMITER_TOKEN) private delimiter: string) {
    super();
    if (!delimiter) {
      this.delimiter = DEFAULT_DATEPICKER_DELIMITER;
    }
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = moment(value, `M${this.delimiter}D${this.delimiter}YYYY`, true);
      if (date.isValid()) {
        return {
          day: date.date(),
          month: date.month() + 1,
          year: date.year(),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? moment([date.year, date.month - 1, date.day]).format(`MM${this.delimiter}DD${this.delimiter}YYYY`)
      : '';
  }
}
