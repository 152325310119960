import { Pipe, PipeTransform } from '@angular/core';
import { objectsLocaleCompare } from 'utils/helpers';

@Pipe({
  name: 'optionSort',
})
export class OptionSortPipe implements PipeTransform {
  transform(arr, param = 'name', ascOrder = true) {
    const result = [...arr].sort(objectsLocaleCompare(param));
    if (ascOrder) {
      return result;
    } else {
      return result.reverse();
    }
  }
}
