import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UrgentActionsService {
  elements$ = new BehaviorSubject([]);

  constructor() {}

  add(element: any) {
    const currentValue = this.elements$.getValue();

    if (!currentValue.includes(element)) {
      this.elements$.next([...currentValue, element]);
    }
  }

  remove(element: any) {
    this.elements$.next(this.elements$.getValue().filter(el => el !== element));
  }
}
