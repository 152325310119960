<main class="static-pages">
    <cm-header-simple></cm-header-simple>
    <div class="not-found">
        <div class="not-found__message">
            <h2 class="not-found__title">{{ config.header }}</h2>
            <p class="ch-text">{{ config.body }}</p>
            <a [routerLink]="[config.link || '/']" class="ch-button">{{ config.buttonText || 'Home Page' }}</a>
        </div>
    </div>
</main>
