import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Selectors } from 'features/organization-settings/platform-branding/+state/platform-branding.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CROPPER_MODES } from '@certemy/ui-components';
import { DEFAULT_LOGO_CONFIG } from 'features/organization-settings/platform-branding/platform-branding.constants';

@Component({
  selector: 'cm-header-simple',
  templateUrl: './header-simple.component.html',
  styleUrls: ['./header-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSimpleComponent {
  CROPPER_MODES = CROPPER_MODES;

  logoConfig$ = this.store
    .select(Selectors.platformLogoConfig)
    .pipe(
      map(({ imageConfig, logoFormType }) =>
        imageConfig.imageFile ? { imageConfig, logoFormType } : DEFAULT_LOGO_CONFIG,
      ),
    );

  constructor(private store: Store<any>) {}
}
