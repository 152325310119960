import { NgModule } from '@angular/core';

import { ActiveProfileComponent } from './active-profile.component';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { ProfileAvatarModule } from 'components/profile-avatar/profile-avatar.module';
import { UserProfileCalloutModule } from 'components/header/user-profile-callout/user-profile-callout.module';

@NgModule({
  imports: [CertemyCommonModule, ProfileAvatarModule, UserProfileCalloutModule],
  exports: [ActiveProfileComponent],
  declarations: [ActiveProfileComponent],
})
export class ActiveProfileModule {}
