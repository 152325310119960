const typesCache = new Set();

export function type<T>(label: T): T {
  if (typesCache.has(label)) {
    throw new Error(`Action type "${label}" already exists.`);
  }

  typesCache.add(label);

  return label;
}
