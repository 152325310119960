import { NgModule } from '@angular/core';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { ProfileAvatarComponent } from './profile-avatar.component';
import { IconModule } from '@certemy/ui-components';

@NgModule({
  imports: [CertemyCommonModule, IconModule],
  declarations: [ProfileAvatarComponent],
  exports: [ProfileAvatarComponent],
})
export class ProfileAvatarModule {}
