import { Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { environment } from '../../../environments/environment';
import { combineLatest, from, fromEvent, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import _get from 'lodash-es/get';
import _size from 'lodash-es/size';
import { ProfileService } from 'services/profile/profile.service';
import {
  PaymentForm,
  PyflowProToken,
  StripeErrorsMessages,
  StripeToken,
  DataForPayment,
} from './payment-form.interface';
import { LoaderService, MessageQueryService } from '@certemy/ui-components';
import { PAYFLOW_STATUS, PAYMENT_TYPES, PayPlowResponse } from 'services/payment/payment.interface';
import { PaymentService } from 'services/payment/payment.service';
import { HttpParams } from '@angular/common/http';
import { ShowIfHttpError } from '@certemy/common';
import { getErrorMessage } from 'utils/helpers';

@Injectable()
export class PaymentFormService {
  stripeInstance: any;
  renderer: Renderer2;
  defaultPaymentData: DataForPayment = { key: '', data: [] };

  constructor(
    private http: HttpService,
    private zone: NgZone,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private paymentService: PaymentService,
    private rendererFactory: RendererFactory2,
    private messageService: MessageQueryService,
  ) {
    // Need to get zone stable event for e2e
    zone.runOutsideAngular(() => {
      if (!(window as any).Stripe) {
        this.messageService.addErrorMessage('Unknown error occurred. Please refresh the page and try again.');
      }
      this.stripeInstance = new (window as any).Stripe(environment.stripeKey);
    });
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  onPay(
    url: string,
    paymentForm: PaymentForm,
    paymentData: DataForPayment = this.defaultPaymentData,
    stepId?: number,
  ): Observable<any> {
    switch (paymentForm.type) {
      case PAYMENT_TYPES.STRIPE:
        return this.onStripePay(url, paymentForm, paymentData);
      case PAYMENT_TYPES.PAYFLOW_PRO:
        return this.onPayflowPay(url, paymentForm, paymentData, stepId);
      case PAYMENT_TYPES.SQUARE:
      case PAYMENT_TYPES.EPAY:
      case PAYMENT_TYPES.EXACT:
      case PAYMENT_TYPES.AUTHORIZE_NET:
        return this.pay(url, paymentForm.data, paymentData, paymentForm.type);
      default:
        return null;
    }
  }

  @ShowIfHttpError('An error occurred. Please, try again later')
  submitVoucher(url: string, voucher: string, paymentData: DataForPayment): Observable<any> {
    const voucherBody = this.getPaymentBody(PAYMENT_TYPES.VOUCHER, paymentData, { voucher });

    return this.http.post(url, voucherBody);
  }

  @ShowIfHttpError('An error occurred. Please, try again later')
  validateVoucher({ voucherValue, certification_id }): Observable<any> {
    return this.http.get(`/voucher-instance/validate/${voucherValue}/${certification_id}`);
  }

  @ShowIfHttpError('An error occurred. Please, try again later')
  private onStripePay(url: string, paymantForm: PaymentForm, paymentData: DataForPayment): Observable<any> {
    const res$ = this.getStripeToken(paymantForm).pipe(
      switchMap(token => this.pay(url, { token: token.id }, paymentData, paymantForm.type)),
    );
    return this.loaderService.wrapObservable(res$);
  }

  private getStripeToken(form: PaymentForm): Observable<StripeToken> {
    return this.getPaymentToken(form.data.card.value, {
      name: form.data.name.value,
    }).pipe(
      switchMap((res: any) => (res.token ? of(res.token) : throwError(res))),
      catchError(err => {
        const message: StripeErrorsMessages = { validation: _get(err, 'error.message', 'Stripe Error') };
        return throwError(message);
      }),
    );
  }

  private getPaymentToken(cardElement, extras) {
    return from(this.zone.run(() => this.stripeInstance.createToken(cardElement, extras)));
  }

  private onPayflowPay(
    url: string,
    paymentForm: PaymentForm,
    paymentData: DataForPayment,
    stepId?: number,
  ): Observable<any> {
    const res$ = this.getPayflowToken(paymentForm.amount, stepId).pipe(
      switchMap(tokenPayload => this.payflowPay(url, tokenPayload, paymentForm, paymentData)),
    );

    return this.loaderService.wrapObservable(res$);
  }

  private payflowPay(url: string, tokenPayload: any, paymentForm: any, paymentData: DataForPayment) {
    const iframe = this.renderer.createElement('iframe');
    const qs = new HttpParams({
      fromObject: {
        ACCT: paymentForm.data.card.value.cardNumber,
        EXPDATE: paymentForm.data.card.value.expDate,
        CVV2: paymentForm.data.card.value.cvv,
        SECURETOKEN: tokenPayload.SECURETOKEN,
        SECURETOKENID: tokenPayload.SECURETOKENID,
      },
    }).toString();
    iframe.src = `${environment.PAYFLOW_URL}?${qs}`;
    this.renderer.appendChild(document.body, iframe);

    return fromEvent<MessageEvent>(window, 'message').pipe(
      filter(event => event.data.SECURETOKEN),
      map(event => event.data),
      take(1),
      tap(() => this.renderer.removeChild(document.body, iframe)),
      switchMap((res: PayPlowResponse) => (res.RESULT === PAYFLOW_STATUS.SUCCESS ? of(res) : throwError(res))),
      switchMap((res: any) => this.pay(url, { payflow: res }, paymentData, PAYMENT_TYPES.PAYFLOW_PRO)),
      catchError(err => {
        const message: StripeErrorsMessages = {
          validation: _get(err, 'error.message', 'Payment failed. Please, try again.'),
        };
        return throwError(message);
      }),
    );
  }

  private getPayflowToken(amount: number, stepId?: number): Observable<PyflowProToken> {
    return this.paymentService.getPaymentToken(amount, stepId).pipe(
      switchMap((res: any) => (res.RESULT === PAYFLOW_STATUS.SUCCESS ? of(res) : throwError(res))),
      catchError(err => {
        const message: StripeErrorsMessages = {
          validation: _get(err, 'error.message', 'Payment failed. Please, try again.'),
        };
        return throwError(message);
      }),
    );
  }

  subscribeOnFormChanges(holderNameFormData$, cardFormData$, paymentType: PAYMENT_TYPES) {
    return combineLatest(holderNameFormData$, cardFormData$).pipe(
      map(([name, card]) => {
        return {
          data: { name, card },
          type: paymentType,
        };
      }),
    );
  }

  @ShowIfHttpError('An error occurred. Please, try again later')
  private pay(url: string, data, paymentData: DataForPayment, type: PAYMENT_TYPES): Observable<any> {
    const res$ = this.http.post(url, this.getPaymentBody(type, paymentData, data)).pipe(
      catchError(err => {
        const message: StripeErrorsMessages = {
          validation: 'Payment failed. Please, try again.',
          payment: _get(err, 'body.error_message', ''),
        };
        return throwError(message);
      }),
    );

    return this.loaderService.wrapObservable(res$);
  }

  private getPaymentBody(type: PAYMENT_TYPES, paymentData: DataForPayment, data) {
    const options = {};

    if (paymentData && _size(paymentData.data)) {
      options[paymentData.key] = paymentData.data;
    }

    return {
      type,
      profile_id: this.profileService.profile.profile_id,
      ...options,
      ...data,
    };
  }
}
