<cm-loader>
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</cm-loader>
<cm-sidebar class="sidebar"> <router-outlet></router-outlet> </cm-sidebar>
<cm-message-query class="message-query"></cm-message-query>
<!--<cm-gdpr-confirmation></cm-gdpr-confirmation>-->
<cm-urgent-actions></cm-urgent-actions>
