import {
  PaymentFormData,
  DataForPayment,
  FeeFormOptionsForPayment,
  VoucherForPayment,
} from 'features/payment-form/payment-form.interface';

export function getPaymentData(key: string, data: FeeFormOptionsForPayment[] | VoucherForPayment[]): DataForPayment {
  return { key, data };
}

export const DEFAULT_CARD_FORM_DATA_VALUE = {
  value: null,
  valid: false,
  errorMessage: null,
};

export const FORM_PLACEHOLDERS = {
  number: '•••• •••• •••• ••••',
  name: 'Full Name',
  expiry: '••/••',
  cvc: '•••',
};

export class HolderNameData implements PaymentFormData<string> {
  get errorMessage(): string {
    return this.valid ? null : 'Fill in the name field';
  }

  get valid(): boolean {
    return !!this.value;
  }

  constructor(public value: string) {}
}

export enum PAYMENT_FORM_PLACE {
  FEE_STEP,
  EDUCATION,
  PHASE,
  VOUCHERS,
}

export const PaymentProcessorError = 'Your Organization payment processor is not supported here.';
