import { Component } from '@angular/core';

@Component({
  selector: 'cm-confirm-reload',
  templateUrl: './confirm-reload.component.html',
  styleUrls: ['./confirm-reload.component.scss'],
})
export class ConfirmReloadComponent {
  onConfirm() {
    window.location.reload();
  }
}
