import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResponseStatus } from '@certemy/common';
import { LoginProviderRecord } from 'services/login-provider/login-provider.interface';
import {
  DEFAULT_LOGIN_PROVIDER,
  mapLoginProviderRecordToSettings,
} from 'services/login-provider/login-provider.constants';

export class LoginProviderState {
  loginProviders: LoginProviderRecord[] = DEFAULT_LOGIN_PROVIDER;
  organizationId: number;
  status: ResponseStatus = ResponseStatus.INITIAL;
}

const loginProviderState = createFeatureSelector<LoginProviderState>('loginProvider');

const loginProviderMappedState = createSelector(
  loginProviderState,
  state => mapLoginProviderRecordToSettings(state),
);

export const Selectors = {
  loginProviderState,
  loginProviderMappedState,
};
