import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {
  constructor() {}

  get session() {
    return sessionStorage;
  }

  setItem(key: string, value) {
    this.session.setItem(key, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(this.session.getItem(key));
  }

  removeItem(key: string) {
    this.session.removeItem(key);
  }

  clearSession() {
    this.session.clear();
  }
}
