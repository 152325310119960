import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Actions as ProfileActions } from './profiles-state.actions';
import { UserService } from 'services/user/user.service';
import { MixpanelService } from 'services/mixpanel/mixpanel.service';
import { getSectionByRoleId } from 'utils/helpers';
import { TokenStorageService } from 'services/token/token-storage.service';
import { ProfileService } from 'services/profile/profile.service';
import { GetUserProfilesResponse, UserProfile } from 'services/user/user.interface';
import { MessageQueryService, SmartModalService } from '@certemy/ui-components';
import { Store } from '@ngrx/store';
import { LoggerService } from '@certemy/common';
import { Actions as BrandingActions } from 'features/organization-settings/platform-branding/+state/platform-branding.actions';
import { CasbinService } from 'services/casbin/casbin.service';
import _get from 'lodash-es/get';
import { Actions as OrganizationLabelsActions } from 'features/global-organizations-setting-state/global-organizations-setting.actions';

@Injectable()
export class ProfilesEffects {
  getProfiles = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.GetProfiles.type),
    switchMap(() =>
      this.userService.getUserProfiles().pipe(
        switchMap((userProfiles: GetUserProfilesResponse) => this.getPermissions(userProfiles)),
        tap((userProfiles: GetUserProfilesResponse) =>
          this.store.dispatch(new OrganizationLabelsActions.SetLabelsSettings(userProfiles.active_profile)),
        ),
        map((profiles: GetUserProfilesResponse) => new ProfileActions.GetProfilesSuccess(profiles)),
        catchError(error => {
          this.loggerService.info(error);
          return of(new ProfileActions.GetProfilesError());
        }),
      ),
    ),
  ));

  login = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.Login.type),
    switchMap(() =>
      this.userService.getUserProfiles().pipe(
        switchMap((userProfiles: GetUserProfilesResponse) => this.getPermissions(userProfiles)),
        tap((profiles: GetUserProfilesResponse) => this.onLoginSuccess(profiles)),
        tap((userProfiles: GetUserProfilesResponse) =>
          this.store.dispatch(new OrganizationLabelsActions.SetLabelsSettings(userProfiles.active_profile)),
        ),
        map((profiles: GetUserProfilesResponse) => new ProfileActions.GetProfilesSuccess(profiles)),
        catchError(() => of(new ProfileActions.GetProfilesError())),
      ),
    ),
  ));

  switchProfile = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.SwitchProfile.type),
    switchMap((action: ProfileActions.SwitchProfile) =>
      this.profileService.switchProfile(action.payload.profileId).pipe(
        tap(token => this.tokenStorage.replaceUserToken(token)),
        switchMap(() => this.userService.getUserProfiles()),
        switchMap((userProfiles: GetUserProfilesResponse) => this.getPermissions(userProfiles)),
        tap(userProfiles => this.handleRedirect(userProfiles.active_profile)),
        tap((userProfiles: GetUserProfilesResponse) =>
          this.store.dispatch(new OrganizationLabelsActions.SetLabelsSettings(userProfiles.active_profile)),
        ),
        map((profiles: GetUserProfilesResponse) => new ProfileActions.GetProfilesSuccess(profiles)),
        tap(() => this.store.dispatch(new BrandingActions.GetBrandingSettings())),
        catchError(() => of(new ProfileActions.GetProfilesError())),
      ),
    ),
  ));

  loginAs = createEffect(() => this.actions$.pipe(
    ofType(ProfileActions.LoginAsProfile.type),
    switchMap((action: ProfileActions.LoginAsProfile) => {
      const { profileId } = action.payload;
      return this.profileService.loginAsProfile(profileId).pipe(
        tap(({ token }) => this.tokenStorage.replaceUserToken(token)),
        switchMap(() => this.userService.getUserProfiles()),
        switchMap((userProfiles: GetUserProfilesResponse) => this.getPermissions(userProfiles)),
        tap(profiles => this.onLoginAsSuccess(profiles)),
        tap((userProfiles: GetUserProfilesResponse) =>
          this.store.dispatch(new OrganizationLabelsActions.SetLabelsSettings(userProfiles.active_profile)),
        ),
        map(profiles => new ProfileActions.GetProfilesSuccess(profiles)),
        catchError(() => of(new ProfileActions.GetProfilesError())),
      );
    }),
  ));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private profileService: ProfileService,
    private mixpanel: MixpanelService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private smartModalService: SmartModalService,
    private store: Store<any>,
    private messageQueryService: MessageQueryService,
    private loggerService: LoggerService,
    private casbinService: CasbinService,
  ) {}

  private onLoginSuccess({ active_profile }: GetUserProfilesResponse) {
    this.mixpanel.login(active_profile);
    this.loggerService.setUserContext({ profile_id: active_profile.profile_id });
    this.handleRedirect(active_profile);
    this.store.dispatch(new BrandingActions.GetBrandingSettings());
  }

  private onLoginAsSuccess(profiles: GetUserProfilesResponse) {
    const { first_name, last_name, role } = profiles.active_profile;
    const redirectLink = this.userService.generateRedirectLink('', profiles.active_profile);
    this.router
      .navigate(redirectLink.link, { queryParams: redirectLink.queryParams })
      .then(() => this.messageQueryService.addMessage(`You are logged in as ${role} ${first_name} ${last_name}`));
  }

  private handleRedirect(profile: UserProfile) {
    const section = getSectionByRoleId(profile.role_id);
    const storedRedirect = this.tokenStorage.redirectUrl;
    // TODO: Should be removed as soon as Verifier role will be added to the system
    // Currently Verifier is part of Organization Admin role
    // Admin should be redirected to 'verifier' section if it was stored at tokenStorage
    const isVerifierRedirectWithAdminRole =
      storedRedirect && storedRedirect.includes('verifier') && section === '/organization';
    let redirect: string;

    const regexp = new RegExp(`^[^\\w]*${section}[^\\w]`);

    if (
      storedRedirect &&
      (regexp.test(storedRedirect) || storedRedirect === section || isVerifierRedirectWithAdminRole)
    ) {
      redirect = storedRedirect;
    }
    const redirectLink = this.userService.generateRedirectLink(redirect, profile);

    this.tokenStorage.dropRedirectUrl();

    this.router.navigate(redirectLink.link, { queryParams: redirectLink.queryParams });
  }

  private getPermissions(userProfiles: GetUserProfilesResponse): Observable<GetUserProfilesResponse> {
    const role_id = _get(userProfiles, 'active_profile.role_id');
    const organization_id = _get(userProfiles, 'active_profile.organization_id');
    return this.casbinService.initialize(role_id, organization_id).pipe(
      switchMap(() => this.profileService.setStartUrlPermissions(role_id)),
      map(() => userProfiles),
    );
  }
}
