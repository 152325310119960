import { Injectable } from '@angular/core';
import { AuthnService } from 'services/authn/authn.service';
import { Observable } from 'rxjs';
import { OtpCreateRequest, OtpCreateResponse, OtpVerifyRequest, OtpVerifyResponse } from './otp.interfaces';

@Injectable()
export class OtpService {
  constructor(private authnService: AuthnService) {}

  sendOtp(sendReq: OtpCreateRequest): Observable<OtpCreateResponse> {
    return this.authnService.post('/otp/send', sendReq);
  }

  verifyOtp(verifyRequest: OtpVerifyRequest): Observable<OtpVerifyResponse> {
    return this.authnService.post('/otp/verify', verifyRequest);
  }
}
