import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { buildQueryParams, createFileUploadHeaders, createQueryParam, mapToQueryParams } from 'utils/helpers';
import { ListRequestParams } from 'utils/interfaces';

import { PROFILE_STATUSES } from 'constants/status.constants';
import { HttpService } from 'services/http/http.service';
import { EMPTY, Observable, of } from 'rxjs';
import {
  BatchUploadOperation,
  BatchUploadSampleaAditionalParams,
  GetUserProfilesResponse,
  ProfessionalBatchUploadOperation,
  SetBatchMappingData,
  UserData,
  UserProfile,
} from 'app/services/user/user.interface';
import { ShowIfHttpError } from '@certemy/common';
import { IGNORE_SESSION_URLS, IGNORE_SESSION_URLS_REGEXP } from 'constants/auth.constants';
import { Params } from '@angular/router';
import _size from 'lodash-es/size';
import { ProfileService } from 'services/profile/profile.service';
import { GENERATE_REPORT_RESPONSE_TYPE } from 'features/report-generate/report-generate.constants';
import { GlobalOrganizationsLabel } from 'features/global-organizations-setting-state/global-organizations-setting.constant';
import { SmartForm } from '@certemy/ui-components';
import {
  DEFAULT_PROFILE_TYPE_ID_NAMES,
  PROFILE_TYPE_ID,
  PROFILE_TYPE_ID_ORGANIZATIONS_LABEL_MAPPER,
} from 'components/organization-users/users/users.constants';
import _isNumber from 'lodash-es/isNumber';

const ignoredUrls = ['/access-restricted', '/external-login'];

@Injectable()
export class UserService {
  private static invitedProfilesForAssignment = [];

  constructor(private http: HttpService, private profileService: ProfileService) {}

  @ShowIfHttpError('Cannot receive user details.')
  getUser(userId: string): Observable<UserData> {
    return this.http.get(`/user/${userId}`);
  }

  getUserProfileInformation(profileId: number = 0) {
    // TODO: Do we still need this check for profileId?
    const url = profileId === 0 ? `/user/information` : `/user/information/${profileId}`;

    return this.http.get(url);
  }

  addInvitedProfilesForAssignment(profiles) {
    UserService.invitedProfilesForAssignment = profiles;
  }

  hasInvitedProfilesForAssignment(): boolean {
    return UserService.invitedProfilesForAssignment.length > 0;
  }

  getInvitedProfilesForAssignment() {
    return of(UserService.invitedProfilesForAssignment);
  }

  @ShowIfHttpError('Cannot receive user profiles.')
  getUserProfiles(): Observable<GetUserProfilesResponse> {
    return this.http.get(`/user/profiles`);
  }

  @ShowIfHttpError('Cannot update last session url.')
  setUserLatestSessionUrl(latestSessionUrl: string = ''): Observable<null> {
    if (ignoredUrls.includes(latestSessionUrl)) {
      return EMPTY;
    }
    return this.http.put('/user/latest-session', { latestSessionUrl });
  }

  @ShowIfHttpError('Cannot get roles data.')
  getUserAdditionalParameters() {
    return this.http.get(`/imposer/additional_parameters`);
  }

  @ShowIfHttpError('Cannot receive users list.')
  getUsers({
    search = null,
    order_id = null,
    order_type = 'asc',
    filters = null,
    page = 1,
    page_size = 1000,
  }: ListRequestParams) {
    const params = [
      createQueryParam('search', search),
      createQueryParam('filters', filters),
      createQueryParam('order_id', order_id),
      createQueryParam('order_type', order_type),
      createQueryParam('page', page),
      createQueryParam('page_size', page_size),
    ];
    const queryParams = buildQueryParams(params);

    return this.http.get(`/user${queryParams}`);
  }

  search(key) {
    const params = [createQueryParam('search', key)];
    const queryParams = buildQueryParams(params);
    return this.http.get(`/user/list${queryParams}`);
  }

  deleteUsers(userIds) {
    return this.http.patch(`/user/`, { ids: userIds, status_id: PROFILE_STATUSES.DELETED });
  }

  activateUsers(payload) {
    return this.http.patch(`/user/`, payload);
  }

  deactivateUsers(userIds) {
    return this.http.patch(`/user/`, { ids: userIds, status_id: PROFILE_STATUSES.DEACTIVATED });
  }

  @ShowIfHttpError('An error occurred while inviting users.')
  inviteUsers(invitationFormData: any) {
    const body = {
      users: invitationFormData.usersForInvitation,
      role: invitationFormData.role,
      // group
      invitation_message: invitationFormData.messageForInvitation,
    };
    return this.http.post(`/user/invitations`, body);
  }

  @ShowIfHttpError('An error occurred while inviting employer.')
  inviteEmployer(body) {
    return this.http.post(`/super_admin/create-employer`, body);
  }

  updateUserInformation(body) {
    return this.http.post(`/user/update_information`, body);
  }

  batchUploadUsers({
    file,
    role,
  }: {
    file: File;
    role: number;
  }): Observable<{ operation_id: number } | { custom_fields: string[] }> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('role_id', role.toString());
    const options = createFileUploadHeaders();
    return this.http.post(`/user/batch-upload/check-headers`, formData, options).pipe(map(res => res.result));
  }

  professionalBatchUploadUsers({ file, role }: { file: File; role: number }): Observable<{ operationId: number }> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('role_id', role.toString());
    const options = createFileUploadHeaders();
    return this.http.post(`/user/batch-upload/file`, formData, options);
  }

  getProfessionalBatchUploadOperation(
    operationId: number | string,
    filters: ProfessionalBatchUploadOperation.RequestQuery = {
      page: 1,
      page_size: 10,
      filters: { showErrors: true, showCorrectRecords: true },
    },
  ): Observable<ProfessionalBatchUploadOperation.Response> {
    const queryParams = mapToQueryParams({ filters, createOffset: true });
    return this.http.get(`/user/batch-upload/${operationId}/rows${queryParams}`);
  }

  setBatchMapping({ mapped, file, role }: SetBatchMappingData): Observable<{ operation_id: number }> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('mapped', JSON.stringify(mapped));
    formData.append('role_id', role.toString());
    const options = createFileUploadHeaders();
    return this.http.post('/user/batch-upload/mapped-custom-fields', formData, options).pipe(map(res => res.result));
  }

  getBatchUploadOperation(
    operationId: number | string,
    { search, filters, page, page_size }: BatchUploadOperation.RequestQuery = {
      page: 1,
      page_size: 10,
    },
  ): Observable<BatchUploadOperation.Response> {
    const params = [
      createQueryParam('search', search),
      createQueryParam('filters', filters),
      createQueryParam('page', page),
      createQueryParam('page_size', page_size),
    ];
    const queryParams = buildQueryParams(params);

    return this.http.get(`/user/batch-upload/${operationId}${queryParams}`).pipe(map(res => res.result));
  }

  toggleBatchUploadInvite(operationId: number, row_id: number, invite: boolean): Observable<any> {
    return this.http.patch(`/user/batch-upload/${operationId}/row`, { row_id, invite });
  }

  updateBatchUploadRowFields(operationId: number, rowId: number, fields): Observable<any> {
    return this.http.put(`/user/batch-upload/${operationId}/row/${rowId}`, { fields });
  }

  updateProfessionalBatchUploadRowFields(
    operationId: number,
    payload: ProfessionalBatchUploadOperation.RowUpdatePayload,
  ): Observable<ProfessionalBatchUploadOperation.RowUpdateResponse> {
    return this.http.put(`/user/batch-upload/${operationId}`, payload).pipe(map(response => response.result));
  }

  updateGridCommonParams(
    operationId: number,
    payload: ProfessionalBatchUploadOperation.GridCommonParams,
  ): Observable<ProfessionalBatchUploadOperation.GridCommonParams> {
    return this.http.patch(`/user/batch-upload/${operationId}`, payload);
  }

  @ShowIfHttpError('An error occurred. Please, try again.')
  postBatchInvitations(operationId: number): Observable<any> {
    return this.http.post(`/user/batch-invitations/${operationId}`, {}).pipe(
      map(res => res.result),
      tap((res: { invited: number; not_invited: number }) =>
        this.http.showSuccessMessage(
          `${res.invited} users were successfully invited to the system. ${res.not_invited} users were not invited.`,
        ),
      ),
    );
  }

  @ShowIfHttpError('An error occurred. Please, try again.')
  postProfessionalBatchInvitations(
    operationId: number,
  ): Observable<ProfessionalBatchUploadOperation.InvitationResponse> {
    return this.http
      .post(`/user/batch-upload/invite/${operationId}`, {})
      .pipe(
        tap((res: ProfessionalBatchUploadOperation.InvitationResponse) =>
          this.http.showSuccessMessage(
            `${res.invited} users were successfully invited to the system. ${res.notInvited} users were not invited.`,
          ),
        ),
      );
  }

  getBatchUploadSampleLink(): Observable<BatchUploadSampleaAditionalParams> {
    return this.http.get(`/user/batch-upload/additional-params`).pipe(map(res => res.result));
  }

  inviteConfirmation(inviteId) {
    return this.http.get(`/user/confirmations/${inviteId}`);
  }

  @ShowIfHttpError('An error occurred while confirming invitation.')
  confirmInvitedUser(body) {
    return this.http.post(`/user/confirmations`, body);
  }

  @ShowIfHttpError('An error occurred while getting batch upload sample file.')
  getBatchUploadFile(responseType = GENERATE_REPORT_RESPONSE_TYPE.ARRAY_BUFFER) {
    return this.http.get('/user/batch-upload/file', { responseType });
  }

  getIndustriesSpecifications() {
    return this.http.get(`/user/industry_specialty`);
  }

  isIgnoreSessionUrls(url) {
    return IGNORE_SESSION_URLS.includes(url) || IGNORE_SESSION_URLS_REGEXP.find(item => item.test(url));
  }

  generateRedirectLink(link, profile?): { link: any[]; queryParams: Params } {
    if (!profile) {
      profile = {} as UserProfile;
    }
    const { latestSessionUrl, role_id, profile_id } = profile;
    link =
      link ||
      latestSessionUrl ||
      (role_id && this.profileService.getStartUrlByRolePermission(profile_id ? profile : null)) ||
      '';
    const urlQueryParams = link.match(/\?.*$/);
    let queryParams = {};
    if (_size(urlQueryParams)) {
      queryParams = urlQueryParams[0]
        .substr(1)
        .split('&')
        .reduce((acc, queryParam) => {
          const [key, value] = queryParam.split('=');
          acc[key] = decodeURIComponent(value);
          return acc;
        }, {});
      link = link.replace(/\?.*$/, '');
    }
    const regExp = /\((.*):(.*)\)/;
    const outletMatch = link.match(regExp);
    link = [link.replace(regExp, '')];
    let outlet;
    if (outletMatch && outletMatch.length) {
      const action = outletMatch[1];
      const matchLink = outletMatch[2];
      outlet = {
        [action]: matchLink,
      };
      link.push({ outlets: outlet });
    }
    return {
      link,
      queryParams,
    };
  }

  @ShowIfHttpError('An error occurred. Please, try again.')
  resendInvitationByProfileId(invitationId: number, payload: any): Observable<null> {
    return this.http.post(`/invitation/${invitationId}/resend`, payload);
  }

  getProfessionalTypeOptions(labels: GlobalOrganizationsLabel): SmartForm.FieldOption[] {
    return Object.values(PROFILE_TYPE_ID)
      .filter(value => _isNumber(value))
      .map(id => {
        const labelTypeId = PROFILE_TYPE_ID_ORGANIZATIONS_LABEL_MAPPER[id];
        return { id, name: labelTypeId ? labels[labelTypeId] : DEFAULT_PROFILE_TYPE_ID_NAMES[id] };
      });
  }
}
