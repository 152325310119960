export const MIXPANEL_EVENTS = Object.freeze({
  CREATE_ACCOUNT_FORM_VIEW: 'CreateAccountFormView',
  FORGOT_PASSWORD_FORM_VIEW: 'ForgotPasswordFormView',

  USER_REGISTERED: 'UserRegistered',
  USER_REGISTERED_BY_INVITATION: 'UserRegisteredByInvitation',
  USER_LOGGED_IN: 'UserLoggedIn',
  USER_INVITED: 'UserInvited',
  USER_ASSIGNED: 'UserAssigned',

  CERTIFICATION_CREATED: 'CertificationCreated',
  CERTIFICATION_ASSIGNED: 'CertificationAssigned',
  CERTIFICATION_COMPLETED: 'CertificationCompleted',

  STEP_COMPLETED: 'StepCompleted',

  ENTITY_TYPE_CREATED: 'EntityTypeCreated',
  ENTITY_CREATED: 'EntityCreated',

  SUBSCRIPTION_TYPE_CREATED: 'SubscriptionTypeCreated',
  SUBSCRIPTION_CREATED: 'SubscriptionCreated',

  ORGANIZATION_CREATED: 'OrganizationCreated',
  ASSIGNMENT_CREATED: 'AssignmentCreated',
});
