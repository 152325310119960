import { Actions, ActionType } from './profiles-state.actions';
import { ProfilesState } from './profiles-state.state';
import { ResponseStatus } from '@certemy/common';
import { ROLE_CODES } from 'constants/role.constants';
import { UserProfile } from 'services/user/user.interface';
import { PROFILE_TYPE_ID } from 'components/organization-users/users/users.constants';

function getCurrencySymbol(profile) {
  switch (profile.currency) {
    case 'eur':
      return '€';
    case 'usd':
    default:
      return '$';
  }
}
function setAvatarPlaceholderUrl(profile: UserProfile) {
  const { role_id, profile_type_id } = profile;
  const avatarPlaceholder =
    role_id === ROLE_CODES.PROFESSIONAL && profile_type_id === PROFILE_TYPE_ID.FACILITY
      ? '/assets/images/facility-avatar.svg'
      : '/assets/images/user-icon-colored.svg';
  return {
    ...profile,
    avatarPlaceholder,
  };
}
export function profilesReducer(state = new ProfilesState(), action: ActionType) {
  switch (action.type) {
    case Actions.GetProfiles.type:
    case Actions.SwitchProfile.type:
      return { ...state, status: ResponseStatus.PENDING };
    case Actions.GetProfilesSuccess.type:
      return {
        currentProfile: setAvatarPlaceholderUrl({
          ...action.payload.active_profile,
          symbol: getCurrencySymbol(action.payload.active_profile),
        }),
        availableProfiles: action.payload.available_profiles.map(setAvatarPlaceholderUrl),
        status: ResponseStatus.COMPLETE,
      };
    case Actions.GetProfilesError.type:
      return { ...state, status: ResponseStatus.ERROR };
    default:
      return state;
  }
}
