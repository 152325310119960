import { NgModule } from '@angular/core';
import { PaymentFormService } from './payment-form.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { PaymentFormErrorComponent } from './error/payment-form-error.component';
import { PaymentFormComponent } from './payment-form.component';
import { PaymentService } from 'services/payment/payment.service';
import { PreloaderModule, TextInputModule } from '@certemy/ui-components';
import { StripeFormComponent } from './stripe-form/stripe-form.component';
import { PayflowFormComponent } from './payflow-form/payflow-form.component';
import { SquareFormComponent } from './square-form/square-form.component';
import { ExactFormComponent } from './exact-form/exact-form.component';
import { EPayFormComponent } from 'features/payment-form/epay-form/epay-form.component';
import { AuthorizeNetFormComponent } from 'features/payment-form/authorize-net/authorize-net.component';
import { NgxCardModule } from '@certemy/common'

@NgModule({
  imports: [CertemyCommonModule, NgbTooltipModule, TextInputModule, PreloaderModule, NgxCardModule],
  declarations: [
    PaymentFormComponent,
    PaymentFormErrorComponent,
    StripeFormComponent,
    PayflowFormComponent,
    SquareFormComponent,
    ExactFormComponent,
    EPayFormComponent,
    AuthorizeNetFormComponent,
  ],
  providers: [PaymentFormService, PaymentService],
  exports: [PaymentFormComponent],
})
export class PaymentFormModule {}
