import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { FeatureToggleService } from './feature-toggle.service';
import { AuditService } from 'features/audit/audit.service';

@Injectable()
export class FeatureToggleGuardService  {
  constructor(private featureToggleService: FeatureToggleService, private auditService: AuditService) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const features = activatedRouteSnapshot.data.features as string[];
    const workflowTypeId = Number(activatedRouteSnapshot.queryParams.workflowTypeId);

    this.auditService.workflowTypeId = workflowTypeId;

    return this.featureToggleService.hasFeatures(features);
  }
}

export const featureToggleGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
  return inject(FeatureToggleGuardService).canActivate(route);
};
