import { NgModule } from '@angular/core';
import { TermsAndConditionsModalComponent } from './terms-and-conditions-modal.component';
import { CheckboxModule } from '@certemy/ui-components';
import { CertemyCommonModule } from 'common/certemy-common.module';
import { TermsAndConditionsModule } from 'pages/static-pages/term-and-conditions/terms-and-conditions.module';

@NgModule({
  imports: [CertemyCommonModule, CheckboxModule, TermsAndConditionsModule],
  declarations: [TermsAndConditionsModalComponent],
})
export class TermsAndConditionsModalModule {}
